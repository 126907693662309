import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Platform } from 'react-native';

import DocsMenu from './Components/DocsMenu';

import Icon from 'react-native-vector-icons/Octicons';

export default function AnalyticsInstallDocs({ appInfo, InAppNavigation }) {
    const { setScreenName } = InAppNavigation;

    return (
        <View style={styles.body}>
            <DocsMenu InAppNavigation={InAppNavigation} />
            <Text style={styles.HeadingText}>Follow these steps to add analytics to your app:</Text>
            <Text style={styles.plainText}>These instructions show how to add Native Notify analytics to your app. You will be able to see how many times your users view your app each day and which screens your users are viewing most. This can help you know which app screens are the most popular. You'll also know how many daily, monthly, and yearly active users you have.</Text>
            
            <Text style={styles.mb}></Text>
            <Text style={styles.mb}></Text>

            {Platform.OS === "web"
                ? <View style={styles.youTubeVideo}>
                    <Text style={styles.h2}>This video walks you through the setup guide below:</Text>
                    <iframe width="400" height="220" src="https://www.youtube.com/embed/zHJlu86A7GA?rel=0" title="Native Notify Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen="allowfullscreen"></iframe>
                    
                    </View>
                : null 
            }


            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>
            <Text style={styles.mb}></Text>
            <Text style={styles.HeadingText}>How to add Native Notify analytics to your app:</Text>    

            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.blackBacktext}>npm install axios</Text>   

            <Text style={styles.boldText}>Step 2:</Text>
            <Text style={styles.plainText}>In your App.js file, make sure these imports are included at the top of the screen:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>axios</Text> <Text style={styles.purpleText}>from</Text> 'axios';</Text>
            </Text>     

            <Text style={styles.boldText}>Step 3:</Text>
            <Text style={styles.plainText}>Make sure you are using a Hook function as your App.js component. Here is an example:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.purpleText}>export default</Text> <Text style={styles.medBlueText}>function</Text> <Text style={styles.yellowText}>App</Text>() {"{"}</Text>
                <Text>...</Text>
                <Text>{"}"}</Text>
            </Text>
            <Text style={styles.plainText}>
                This link explains Hooks in detail: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://reactjs.org/docs/hooks-intro.html')}>https://reactjs.org/docs/hooks-intro.html</TouchableOpacity>
            </Text>

            <Text style={styles.boldText}>Step 4:</Text>
            <Text style={styles.plainText}>Inside of your App function, create a 'useEffect' function if you have not already. Here is an example:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.yellowText}>useEffect</Text>(() => {"{"}</Text>
                <Text>     ...</Text>
                <Text>{"});"}</Text>
            </Text>
            <Text style={styles.plainText}>
                This link explains how 'useEffect' works in detail: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://reactjs.org/docs/hooks-effect.html')}>https://reactjs.org/docs/hooks-effect.html</TouchableOpacity>
            </Text>

            <Text style={styles.boldText}>Step 5:</Text>
            <Text style={styles.plainText}>Inside of your 'useEffect' function, paste this code:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.yellowText}>useEffect</Text>(() => {"{"}</Text>
                    <Text>     axios.<Text style={styles.yellowText}>post</Text>(`https://app.nativenotify.com/api/analytics`, {`{`}</Text>
                    <Text>          app_id: {appInfo.app_id},</Text>
                    <Text>          app_token: '{appInfo.app_token}',</Text>
                    <Text>          screenName: 'Home'</Text>
                    <Text style={styles.mb}>     {" });"}</Text>
                <Text>{"});"}</Text>
            </Text>
            <Text style={styles.boldText}>The screenName in the App.js component MUST be 'Home', or Analytics will not work properly.</Text>
            <Text style={styles.plainText}>Do NOT change the 'app_id' or the 'app_token' value in this code. We put your app_id and app_token in the code for you. </Text>

            <Text style={styles.boldText}>Step 6:</Text>
            <Text style={styles.plainText}>Each time a user navigates to a screen, post the name of that screen to our API.</Text>
            <Text style={styles.mb}>Here is the API:</Text>
            <Text style={styles.blackBacktext}>
                <Text>axios.<Text style={styles.yellowText}>post</Text>(`https://app.nativenotify.com/api/analytics`, {`{`}</Text>
                <Text>      app_id: {appInfo.app_id},</Text>
                <Text>      app_token: '{appInfo.app_token}',</Text>
                <Text>      screenName: '<Text style={styles.lightBlueText}>put your screen name here as a string</Text>'</Text>
                <Text>{" });"}</Text>
            </Text>
            <Text style={styles.plainText}>
                Do NOT change the 'app_id' or the 'app_token' value in this code. We put your app_id and app_token in the code for you. 
            </Text>
            <Text style={styles.mb}></Text>
            <Text style={styles.plainText}>If you are using @react-navigation, go to this link to learn how to track each screen your users visit: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://reactnavigation.org/docs/screen-tracking/')}>https://reactnavigation.org/docs/screen-tracking/</TouchableOpacity> Replace the 'await Analytics.setCurrentScreen(currentRouteName);' part of the code from the reactnavigation.org link with the API code above.</Text>
            <Text style={styles.mb}></Text>
            <Text style={styles.plainText}>
                And that's it! You can now view your analytics by clicking this icon at the left of your screen: {"    "}
                <Icon
                    name="graph"
                    size={20}
                    color={'#000'}
                    onPress={() => setScreenName('analytics')}
                />
            </Text>
            
        </View>
    )
}

const styles = StyleSheet.create({
    body: {
        width: '85%',
        paddingTop: 30,
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingBottom: 200
    },

    buttonText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 16,
        color: '#fff',
    },
    button: {
        width: 220,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
    },
    HeadingText: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        // marginTop: 70,
        marginBottom: 10
    },
    h2: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 20,
        marginBottom: 10
    },
    boldText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginTop: 20,
        marginBottom: 5
    },
    bT: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 16,
        marginBottom: 5
    },
    blackBacktext: {
        backgroundColor: "#1b1f23",
        color: "#fff",
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        padding: 20,
        borderRadius: 6,
        marginBottom: 5,
        display: "flex",
        flexDirection: "column"
    },
    blueText: {
        color: "rgb(54, 117, 212)"
    },
    medBlueText: {
        color: "rgb(81, 130, 204)"
    },
    dottedLine: {
        height: 1,
        width: "100%",
        borderTopWidth: 1,
        borderColor: "#000",
        borderStyle: "dotted",
        marginTop: 30,
        marginBottom: 10,
    },
    lightBlueText: {
        color: "rgb(144, 180, 218)"
    },
    plainText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 5
    },
    purpleText: {
        color: "rgb(187, 86, 187)"
    },
    mb: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 10
    },
    tab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50
    },
    yellowText: {
        color: "rgb(222, 222, 160)"
    }
});