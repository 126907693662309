import React from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Platform } from 'react-native';

import DocsMenu from './Components/DocsMenu';

export default function IndieNotificationInboxDocs({ appInfo, InAppNavigation }) {
    const { setScreenName } = InAppNavigation;

    return (
        <View style={styles.body}>
            <DocsMenu InAppNavigation={InAppNavigation} />
            <Text style={styles.HeadingText}>Follow these steps to create Indie In-App Notification Inbox:</Text>
            <Text style={styles.plainText}>These instructions show how to create an Indie Notification Inbox within your app. Your users will be able to see a history of all the previous notifications sent.</Text>

            <Text style={styles.boldText}>Make sure you follow the <TouchableOpacity onPress={() => setScreenName('indieInstallDocs')}>'Indie Push'</TouchableOpacity> guide instructions BEFORE you set up Indie Notification Inboxes. Indie Notification Inboxes will not work without first following the <TouchableOpacity onPress={() => setScreenName('indieInstallDocs')}>'Indie Push'</TouchableOpacity> Guide.</Text>

            <Text style={styles.mb}></Text>
            <Text style={styles.mb}></Text>

            {Platform.OS === "web"
                ? <View style={styles.youTubeVideo}>
                    <Text style={styles.h2}>This video walks you through the setup guide below:</Text>
                    <iframe width="400" height="220" src="https://www.youtube.com/embed/FPcqnl3Qbfc?rel=0" title="Native Notify Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen="allowfullscreen"></iframe>

                </View>
                : null
            }

            <View style={styles.dottedLine}></View>

            <Text style={styles.boldText}>Notification Inbox preview:</Text>
            <Text style={styles.plainText}>Here's how the Indie Notification Inbox API works:</Text>
            <Text style={styles.mb}></Text>
            <Text style={styles.plainText}><Text style={styles.bT}>Get Indie Notifications Sent:</Text> Use the native-notify 'getIndieNotificationInbox' function to get a history of all the push notifications sent to an individual user in the past.</Text>
            <Text style={styles.plainText}><Text style={styles.bT}>Use:</Text> Use the Indie Notification Inbox data from the 'getIndieNotificationInbox' function to create an in-app Indie Notification Inbox.</Text>
            <Text style={styles.plainText}><Text style={styles.bT}>Allow Users to Delete Notifications:</Text> Use the native-notify 'deleteIndieNotificationInbox' function to delete a notification within the Indie Notification Inbox.</Text>

            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>
            <Text style={styles.HeadingText}>Setup:</Text>

            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.blackBacktext}>
                <Text style={styles.mbSmall}>npm install native-notify@latest</Text>
                <Text>npx expo install expo-device expo-notifications expo-constants</Text>
            </Text>

            <Text style={styles.boldText}>Step 2:</Text>
            <Text style={styles.plainText}>In your Indie Notification Inbox screen, make sure these imports are included at the top of the screen:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>React, {"{ useState, useEffect }"} </Text><Text style={styles.purpleText}>from</Text> 'react-native';</Text>
                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>{"{ getIndieNotificationInbox, deleteIndieNotificationInbox }"} </Text><Text style={styles.purpleText}>from</Text> 'native-notify';</Text>
            </Text>

            <Text style={styles.boldText}>Step 3:</Text>
            <Text style={styles.plainText}>Make sure you are using a Hook function as your Indie Notification Inbox function. Here is an example:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.purpleText}>export default</Text> <Text style={styles.medBlueText}>function</Text> <Text style={styles.yellowText}>IndieNotificationInbox</Text>() {"{"}</Text>
                <Text>     ...</Text>
                <Text>{"}"}</Text>
            </Text>
            <Text style={styles.plainText}>
                This link explains Hooks in detail: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://reactjs.org/docs/hooks-intro.html')}>https://reactjs.org/docs/hooks-intro.html</TouchableOpacity>
            </Text>

            <Text style={styles.boldText}>Step 4:</Text>
            <Text style={styles.plainText}>Inside of your Indie Notification Inbox screen, create a useState to store your Indie Notification Inbox data like this:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.blueText}>const</Text> [data, setData] = <Text style={styles.yellowText}>useState</Text>([]);</Text>
            </Text>

            <Text style={styles.boldText}>Step 5:</Text>
            <Text style={styles.plainText}>Paste this 'useEffect' function into your Indie Notification Inbox function:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.yellowText}>useEffect</Text>(<Text style={styles.blueText}>async</Text> () => {"{"}</Text>
                <Text><Text style={styles.blueText}>    const</Text> <Text style={styles.lightBlueText}>notifications</Text> = <Text style={styles.purpleText}>await</Text> <Text style={styles.yellowText}>getIndieNotificationInbox</Text>('Indie Push Sub ID as a string', {appInfo.app_id}, '{appInfo.app_token}', take-number, skip-number);</Text>
                <Text>    console.log(<Text style={styles.lightBlueText}>"notifications: ", notifications</Text>);</Text>
                <Text>    <Text style={styles.yellowText}>setData</Text>(notifications);</Text>
                <Text>{"}, []);"}</Text>
            </Text>
            <Text style={styles.plainText}>Notes:</Text>
            <Text style={styles.plainText}>
                - This link explains how 'useEffect' works in detail: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://reactjs.org/docs/hooks-effect.html')}>https://reactjs.org/docs/hooks-effect.html</TouchableOpacity>
            </Text>
            <Text style={styles.plainText}>
                - Do NOT change the 'appId' or the 'appToken' value in this code. We put your appId and appToken in the code for you. 
            </Text>
            <Text style={styles.plainText}>- Take count defaults to 10.</Text>
            <Text style={styles.plainText}>- Skip count defaults to 0.</Text>

            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>

            <Text style={styles.plainText}>Here is an example of a Header.js screen with Unread Indie Notification Count code included:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>React, {"{ useState, useEffect }"} </Text><Text style={styles.purpleText}>from</Text> 'react';</Text>
                <Text style={styles.mb}><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>{"{ getIndieNotificationInbox, deleteIndieNotificationInbox }"}</Text> <Text style={styles.purpleText}>from</Text> 'native-notify';</Text>

                <Text><Text style={styles.purpleText}>export default</Text> <Text style={styles.medBlueText}>function</Text> <Text style={styles.yellowText}>IndieNotificationInbox</Text>() {"{"}</Text>
                <Text style={styles.mb}><Text style={styles.blueText}>    const</Text> [data, setData] = <Text style={styles.yellowText}>useState</Text>([]);</Text>

                <Text>     <Text style={styles.yellowText}>useEffect</Text>(<Text style={styles.blueText}>async</Text> () => {"{"}</Text>
                <Text>          <Text style={styles.blueText}>const</Text> <Text style={styles.lightBlueText}>notifications</Text> = <Text style={styles.purpleText}>await</Text> <Text style={styles.yellowText}>getIndieNotificationInbox</Text>('Indie Push Sub ID as a string', {appInfo.app_id}, '{appInfo.app_token}', take-number, skip-number);</Text>
                <Text>          console.log(<Text style={styles.lightBlueText}>"notifications: ", notifications</Text>);</Text>
                <Text>          <Text style={styles.yellowText}>setData</Text>(notifications);</Text>
                <Text style={styles.mb}>     {"}, []);"}</Text>

                <Text>     <Text style={styles.purpleText}>return</Text> (</Text>
                <Text style={styles.tab2}>...</Text>
                <Text>     )</Text>
                <Text>{"}"}</Text>
            </Text>

            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>

            <Text style={styles.HeadingText}>How to delete a notification:</Text>
            <Text style={styles.plainText}>Something you need to know first:</Text>
            <Text style={styles.plainText}>Each notification in the Indie Notification Inbox has a notification_id. You need that notification_id to be able to delete the notification. If you're using something like a Flatlist or a map function to display your notifications in the Indie Notification Inbox, each notification object within your data array will include a notification_id. Use this notification_id as the parameter in the handleDeleteNotification function below. The delete function in your onPress event should look something like this:</Text>
            <Text style={styles.mb}></Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.yellowText}>handleDeleteNotification</Text>(<Text style={styles.lightBlueText}>item</Text>.notification_id);</Text>
            </Text>

            <Text style={styles.boldText}>Here's how to delete a notification in an Indie Notification Inbox:</Text>

            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.plainText}>Use this function:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.blueText}>const</Text> <Text style={styles.yellowText}>handleDeleteNotification</Text> = <Text style={styles.blueText}>async</Text> (notificationId) => {"{"}</Text>
                <Text><Text style={styles.blueText}>    const</Text> <Text style={styles.lightBlueText}>notifications</Text> = <Text style={styles.purpleText}>await</Text> <Text style={styles.yellowText}>deleteIndieNotificationInbox</Text>('Indie Push Sub ID as a string', notificationId, {appInfo.app_id}, '{appInfo.app_token}');</Text>
                <Text>    console.log(<Text style={styles.lightBlueText}>"notifications: ", notifications</Text>);</Text>
                <Text>    <Text style={styles.yellowText}>setData</Text>(notifications);</Text>
                <Text>{"}"}</Text>
            </Text>

            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>

            <Text style={styles.HeadingText}>How to create an Unread Indie Notifications Icon that shows the number of unread notifications in an inbox:</Text>
            <Text style={styles.plainText}>Use the functions below to get the number of unread notifications in the Indie Notification Inbox.</Text>

            <Text style={styles.mb}></Text>
            <Text style={styles.mb}></Text>

            {Platform.OS === "web"
                ? <View style={styles.youTubeVideo}>
                    <Text style={styles.h2}>This video walks you through the setup guide below:</Text>
                    <iframe width="400" height="220" src="https://www.youtube.com/embed/8_4lmVhNHRU?rel=0" title="Native Notify Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen="allowfullscreen"></iframe>

                </View>
                : null
            }

            <View style={styles.dottedLine}></View>

            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.blackBacktext}>
                <Text style={styles.mbSmall}>npm install native-notify@latest</Text>
                <Text>npx expo install expo-device expo-notifications expo-constants</Text>
            </Text>

            <Text style={styles.boldText}>Step 2:</Text>
            <Text style={styles.plainText}>In your Footer or Header component (wherever your Indie Unread Indie Notifications Icon is located), make sure these imports are included at the top of the screen:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>React, {"{ useState, useEffect }"} </Text><Text style={styles.purpleText}>from</Text> 'react-native';</Text>
                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>{"{ getUnreadIndieNotificationInboxCount }"} </Text><Text style={styles.purpleText}>from</Text> 'native-notify';</Text>
            </Text>

            <Text style={styles.boldText}>Step 3:</Text>
            <Text style={styles.plainText}>Make sure you are using a Hook function as your Indie Notification Inbox function. Here is an example:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.purpleText}>export default</Text> <Text style={styles.medBlueText}>function</Text> <Text style={styles.yellowText}>Footer</Text>() {"{"}</Text>
                <Text>     ...</Text>
                <Text>{"}"}</Text>
            </Text>
            <Text style={styles.plainText}>
                This link explains Hooks in detail: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://reactjs.org/docs/hooks-intro.html')}>https://reactjs.org/docs/hooks-intro.html</TouchableOpacity>
            </Text>

            <Text style={styles.boldText}>Step 4:</Text>
            <Text style={styles.plainText}>Inside of your Footer or Header component (wherever your Indie Unread Indie Notifications Icon is located), create a useState to store your Unread Notifications Count like this:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.blueText}>const</Text> [unreadNotificationCount, setUnreadNotificationCount] = <Text style={styles.yellowText}>useState</Text>(0);</Text>
            </Text>

            <Text style={styles.boldText}>Step 5:</Text>
            <Text style={styles.plainText}>Paste this 'useEffect' function into your Footer or Header component (wherever your Indie Unread Indie Notifications Icon is located):</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.yellowText}>useEffect</Text>(<Text style={styles.blueText}>async</Text> () => {"{"}</Text>
                <Text><Text style={styles.blueText}>    const</Text> <Text style={styles.lightBlueText}>unreadCount</Text> = <Text style={styles.purpleText}>await</Text> <Text style={styles.yellowText}>getUnreadIndieNotificationInboxCount</Text>('your-indie-push-id-as-string', {appInfo.app_id}, '{appInfo.app_token}');</Text>
                <Text>    console.log(<Text style={styles.lightBlueText}>"unreadCount: ", unreadCount</Text>);</Text>
                <Text>    <Text style={styles.yellowText}>setUnreadNotificationCount</Text>(unreadCount);</Text>
                <Text>{"}, []);"}</Text>
            </Text>
            <Text style={styles.mb}>
                This link explains how 'useEffect' works in detail: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://reactjs.org/docs/hooks-effect.html')}>https://reactjs.org/docs/hooks-effect.html</TouchableOpacity>
            </Text>
            <Text style={styles.plainText}>Do NOT change the 'appId' or the 'appToken' value in this code. We put your appId and appToken in the code for you. </Text>

            <Text style={styles.boldText}>Step 6:</Text>
            <Text style={styles.plainText}>Use this unreadNotificationCount to create an unread notification bubble over your Unread Indie Notifications Icon whenever the number of unreadNotificationCount is above zero (0).</Text>

            <View style={styles.dottedLine}></View>

            <Text style={styles.plainText}>Here is an example of a Header.js screen with Unread Indie Notification Count code included:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>React, {"{ useState, useEffect }"} </Text><Text style={styles.purpleText}>from</Text> 'react';</Text>
                <Text style={styles.mb}><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>{"{ getUnreadIndieNotificationInboxCount }"}</Text> <Text style={styles.purpleText}>from</Text> 'native-notify';</Text>

                <Text><Text style={styles.purpleText}>export default</Text> <Text style={styles.medBlueText}>function</Text> <Text style={styles.yellowText}>Header</Text>() {"{"}</Text>
                <Text style={styles.mb}><Text style={styles.blueText}>    const</Text> [unreadNotificationCount, setUnreadNotificationCount] = <Text style={styles.yellowText}>useState</Text>(0);</Text>

                <Text>     <Text style={styles.yellowText}>useEffect</Text>(<Text style={styles.blueText}>async</Text> () => {"{"}</Text>
                <Text>          <Text style={styles.blueText}>const</Text> <Text style={styles.lightBlueText}>unreadCount</Text> = <Text style={styles.purpleText}>await</Text> <Text style={styles.yellowText}>getUnreadIndieNotificationInboxCount</Text>('your-indie-push-id-as-string', {appInfo.app_id}, '{appInfo.app_token}');</Text>
                <Text>          console.log(<Text style={styles.lightBlueText}>"unreadCount: ", unreadCount</Text>);</Text>
                <Text>          <Text style={styles.yellowText}>setUnreadNotificationCount</Text>(unreadCount);</Text>
                <Text style={styles.mb}>     {"}, []);"}</Text>

                <Text>     <Text style={styles.purpleText}>return</Text> (</Text>
                <Text style={styles.tab2}>...</Text>
                <Text>     )</Text>
                <Text>{"}"}</Text>
            </Text>
        </View>
    )
}

const styles = StyleSheet.create({
    body: {
        width: '85%',
        paddingTop: 30,
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingBottom: 200
    },

    buttonText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 14,
        color: '#fff',
    },
    button: {
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
    },
    HeadingText: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        // marginTop: 70,
        marginBottom: 10
    },
    h2: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 20,
        marginBottom: 10
    },
    boldText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginTop: 20,
        marginBottom: 5
    },
    bT: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 16,
        marginBottom: 5
    },
    blackBacktext: {
        backgroundColor: "#1b1f23",
        color: "#fff",
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        padding: 20,
        borderRadius: 6,
        marginBottom: 5,
        display: "flex",
        flexDirection: "column"
    },
    blueText: {
        color: "rgb(54, 117, 212)"
    },
    medBlueText: {
        color: "rgb(81, 130, 204)"
    },
    dottedLine: {
        height: 1,
        width: "100%",
        borderTopWidth: 1,
        borderColor: "#000",
        borderStyle: "dotted",
        marginTop: 30,
        marginBottom: 10,
    },
    lightBlueText: {
        color: "rgb(144, 180, 218)"
    },
    plainText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 5
    },
    purpleText: {
        color: "rgb(187, 86, 187)"
    },
    mb: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 10
    },
    tab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50
    },
    yellowText: {
        color: "rgb(222, 222, 160)"
    },

    recCont: {
        width: '100%',
        backgroundColor: '#fff',
        marginTop: 10,
        marginBottom: 10,
        padding: 20,
        paddingBottom: 15,
        borderRadius: 6,
        flexWrap: 'wrap',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    recText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 14,
        marginBottom: 5,
    },
    recTextBold: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 5,
    },
    leftPart: {
        width: '50%'
    }
});