import React, { useState, useEffect } from 'react';
import { View, TextInput, StyleSheet, Text, TouchableOpacity } from 'react-native';
import axios from '../../../../axios';

import Icon from 'react-native-vector-icons/FontAwesome';
import Icon2 from 'react-native-vector-icons/Octicons';
import Icon3 from 'react-native-vector-icons/Ionicons';

export default function NotificationSidebar({ host, setAppInfo, InAppNavigation }) {
    const { goToSend, screenName, setScreenName } = InAppNavigation;

    function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

    const updateAppInfo = () => {
        axios
            .get(`${host}/api/app/${getCookie('app_id')}`)
            .then(res => {
                setAppInfo(res.data);
            })
            .catch(err => console.log(err));
    }

    return (
        <View style={styles.sidebarCont}>
            <View style={styles.cont}>
                <View style={styles.items}>
                    <TouchableOpacity style={styles.icon} onPress={() => goToSend()}>
                        <Icon
                            name="send"
                            size={20}
                            color={screenName === "sendNot" ? "#3675d4" : '#fff'}
                            onPress={() => goToSend()}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.icon} onPress={() => { setScreenName('scheduleNotifications'); updateAppInfo(); }}>
                        <Icon
                            name="calendar"
                            size={20}
                            color={screenName === "scheduleNotifications" ? "#3675d4" : '#fff'}
                            onPress={() => { setScreenName('scheduleNotifications'); updateAppInfo(); }}
                        />
                        {/* <Text style={styles.text}>NEW</Text> */}
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.icon} onPress={() => { setScreenName('notInbox'); updateAppInfo(); }}>
                        <Icon3
                            name="mail"
                            size={20}
                            color={screenName === "notInbox" ? "#3675d4" : '#fff'}
                            onPress={() => { setScreenName('notInbox'); updateAppInfo(); }}
                        />
                        {/* <Text style={styles.text}>NEW</Text> */}
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.icon} onPress={() => { setScreenName('topicGroups'); updateAppInfo(); }}>
                        <Icon
                            name="group"
                            size={20}
                            color={screenName === "topicGroups" ? "#3675d4" : '#fff'}
                            onPress={() => { setScreenName('topicGroups'); updateAppInfo(); }}
                        />
                        {/* <Text style={styles.text}>NEW</Text> */}
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.icon} onPress={() => { setScreenName('subs'); updateAppInfo(); }}>
                        <Text 
                            style={{
                                color: screenName === "subs" ? "#3675d4" : '#fff',
                                fontFamily: 'Arial',
                                fontWeight: 700,
                                fontSize: 10,
                                marginTop: 5
                            }}
                            size={20}
                            onPress={() => { setScreenName('subs'); updateAppInfo(); }}
                        >SUBS</Text>
                        {/* <Text style={styles.text}>NEW</Text> */}
                    </TouchableOpacity>
                    {/* <TouchableOpacity style={styles.icon} onPress={() => { setScreenName('analytics'); updateAppInfo(); }}>
                        <Icon2
                            name="graph"
                            size={20}
                            color={screenName === "analytics" ? "#3675d4" : '#fff'}
                            onPress={() => { setScreenName('analytics'); updateAppInfo(); }}
                        />
                        <Text style={styles.text}>NEW</Text>
                    </TouchableOpacity> */}
                </View>
            </View> 

            <View style={styles.line}></View>

            <View style={styles.cont}>
                <TouchableOpacity style={styles.icon} onPress={() => { setScreenName('installDocs'); updateAppInfo(); }}>
                    <Icon
                        name="book"
                        size={20}
                        color={
                            screenName !== "sendNot" 
                                && screenName !== "scheduleNotifications"
                                && screenName !== "notInbox" 
                                && screenName !== "analytics" 
                                && screenName !== "settings" 
                                && screenName !== "topicGroups"
                                && screenName !== "subs"
                            ? "#3675d4" : '#fff'}
                        onPress={() => { setScreenName('installDocs'); updateAppInfo(); }}
                    />
                    {/* <Text style={styles.text}>NEW</Text> */}
                </TouchableOpacity>
                <TouchableOpacity style={styles.icon} onPress={() => { setScreenName('settings'); updateAppInfo(); }}>
                    <Icon2
                        name="gear"
                        size={20}
                        color={screenName === "settings" ? "#3675d4" : '#fff'}
                        onPress={() => { setScreenName('settings'); updateAppInfo(); }}
                    />
                    {/* <Text style={styles.text}>NEW</Text> */}
                </TouchableOpacity>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    sidebarCont: {
        backgroundColor: 'rgb(27, 31, 35)',
        minHeight: '100vh', 
    },
    cont: {
        paddingHorizontal: 20,
        paddingTop: 30,
    },
    text: {
        color: '#e44258',
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: 10,
        marginTop: 5
    },
    textWhite: {
        color: '#fff',
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: 10,
        marginTop: 5
    },
    icon: {
        marginBottom: 30,
        textAlign: 'center'
    },
    items:  {
        position: 'sticky',
        top: 30
    },
    line: {
        width: '70%',
        borderWidth: 1,
        borderBottomColor: "white",
        marginHorizontal: "15%"
    }
})