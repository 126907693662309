import React, { useState } from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, ActivityIndicator, Alert, Dimensions } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import axios from 'axios';

const screenWidth = Dimensions.get('window').width;

export default function UpdatePlan({ host, hideFreePlan = false, subNameString, setAppUpdated }) {
  const [showLoading, setShowLoading] = useState(false);
  const [updatedSubName, setUpdatedSubName] = useState("");

  const plans = [
    {
      name: 'Free Trial',
      description: 'Perfect for small projects & just getting started.',
      price: 'Free Trial',
      included: '7 Day Free Trial',
      overage: null,
      monthlyActiveUsers: null,
      teamMembers: '10 Team Members',
      prioritySupport: true,
      analytics: 'Advanced Analytics',
      sendUnlimited: true,
      massPush: true,
      massInbox: true,
      indiePush: true,
      indieInbox: true,
      groupPush: true,
      topicPush: true,
      followPush: true,
      subName: 0,
    },
    {
      name: 'Basic',
      description: 'Perfect for smaller projects with basic needs.',
      price: '$39/month',
      included: '1,000 monthly active users',
      overage: 'then $0.05 per MAU',
      monthlyActiveUsers: 1000,
      teamMembers: '1 Team Member',
      prioritySupport: true,
      analytics: 'Basic Analytics',
      sendUnlimited: true,
      massPush: true,
      massInbox: false,
      indiePush: true,
      indieInbox: false,
      groupPush: false,
      topicPush: false,
      followPush: false,
      subName: 1,
    },
    {
      name: 'Premium',
      description: 'Great for growing apps needing advanced features.',
      price: '$99/month',
      included: '10,000 monthly active users',
      overage: 'then $0.005 per MAU',
      monthlyActiveUsers: 10000,
      teamMembers: '3 Team Members',
      prioritySupport: true,
      analytics: 'Advanced Analytics',
      sendUnlimited: true,
      massPush: true,
      massInbox: true,
      indiePush: true,
      indieInbox: true,
      groupPush: true,
      topicPush: true,
      followPush: true,
      subName: 2,
    },
    {
      name: 'Pro',
      description: 'Designed for large-scale, production-ready apps.',
      price: '$399/month',
      included: '50,000 monthly active users',
      overage: 'then $0.00325 per MAU',
      monthlyActiveUsers: 50000,
      teamMembers: '10 Team Members',
      prioritySupport: true,
      analytics: 'Advanced Analytics',
      sendUnlimited: true,
      massPush: true,
      massInbox: true,
      indiePush: true,
      indieInbox: true,
      groupPush: true,
      topicPush: true,
      followPush: true,
      subName: 3,
    },
  ];

  const handleSubscribe = async (subName) => {
    if (!host) {
      Alert.alert('Error', 'Missing host information.');
      return;
    }

    setShowLoading(true);
    await axios.post(`${host}/api/stripe/subscribe`, { subName })
      .then((res) => {
        if (res.data.url) {
          // For Expo, consider using Linking if window.location is not available.
          // If running in web environment, window.location is fine.
          window.location = res.data.url;
        } else {
          const subNameString = 
                subName === 1 ? "Basic" :
                subName === 2 ? "Premium" :
                subName === 3 ? "Pro" 
                : "Free";

          setUpdatedSubName(subNameString);
          setAppUpdated(1000000000)
          alert(`You successfully updated your plan to Native Notify ${subNameString}`)
          setShowLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        alert("Our server is experiencing high traffic or is in maintenance. Please try again later.");
        setShowLoading(false);
      });
  };

  const FeatureRow = ({ text, included }) => (
    <View style={styles.featureRow}>
      <Icon
        name={included ? "check-circle-outline" : "highlight-off"}
        size={20}
        color={included ? "green" : "red"}
        style={styles.icon}
      />
      <Text style={styles.featureText}>{text}</Text>
    </View>
  );

  if (showLoading) {
    return (
      <View style={[styles.container, {justifyContent: 'center', alignItems: 'center'}]}>
        <ActivityIndicator size="large" color="rgb(54, 117, 212)" />
      </View>
    );
  }

  // Filter out free plan if hideFreePlan is true
  const displayedPlans = hideFreePlan ? plans.filter(p => p.subName !== 0) : plans;

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Select Your Plan</Text>
      <Text style={styles.planDescription}>Your current plan: {updatedSubName || subNameString}</Text>
      <ScrollView contentContainerStyle={styles.plansWrapper}>
        {displayedPlans.map((plan, index) => (
          <View key={index} style={styles.planCard}>
            <Text style={styles.planName}>{plan.name}</Text>
            <Text style={styles.planDescription}>{plan.description}</Text>
            <Text style={styles.planPrice}>
              <Text style={styles.planPriceLight}>From </Text>
              {plan.price}
            </Text>

            <TouchableOpacity 
              style={styles.selectButton}
              onPress={() => handleSubscribe(plan.subName)}
            >
              <Text style={styles.selectButtonText}>Select {plan.name}</Text>
            </TouchableOpacity>

            <Text style={styles.smallText}>No commitments. Cancel anytime.</Text>

            <View style={styles.divider} />

            {/* Plan Details */}
            {plan.included && (
              <FeatureRow text={plan.included} included={true} />
            )}
            {plan.overage && (
              <Text style={styles.featureOverage}>{plan.overage}</Text>
            )}

            <FeatureRow text="Send Unlimited Notifications" included={plan.sendUnlimited} />
            <FeatureRow text="Mass Push Notifications" included={plan.massPush} />
            <FeatureRow text="Mass Notification Inbox" included={plan.massInbox} />
            <FeatureRow text="Indie Push Notifications" included={plan.indiePush} />
            <FeatureRow text="Indie Notification Inbox" included={plan.indieInbox} />
            <FeatureRow text="Group Push Notifications" included={plan.groupPush} />
            <FeatureRow text="Topic Push Notifications" included={plan.topicPush} />
            <FeatureRow text="Follow Push Notifications" included={plan.followPush} />
            <FeatureRow text="Priority Support" included={plan.prioritySupport} />
            <FeatureRow text={plan.analytics} included={plan.analytics === 'Advanced Analytics' || plan.analytics === 'Basic Analytics'} />
            <FeatureRow text={plan.teamMembers} included={true} />
          </View>
        ))}
      </ScrollView>
    </View>
  );
}

const cardWidth = Math.min(screenWidth * 0.9, 300);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    backgroundColor: "#f1f3f7",
    paddingVertical: 30,
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: '700',
    marginBottom: 20,
    fontFamily: 'Arial',
    color: '#000',
    textAlign: 'center',
  },
  plansWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingBottom: 50,
    paddingHorizontal: 10,
  },
  planCard: {
    width: cardWidth,
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#d6d6d6',
    borderRadius: 6,
    paddingVertical: 20,
    paddingHorizontal: 15,
    margin: 10,
    shadowColor: '#000',
    shadowOpacity: 0.05,
    shadowRadius: 2,
    elevation: 2,
    justifyContent: 'flex-start',
  },
  planName: {
    fontSize: 20,
    fontWeight: '700',
    textAlign: 'center',
    marginBottom: 5,
    fontFamily: 'Arial',
    color: '#000',
  },
  planDescription: {
    fontSize: 14,
    fontFamily: 'Arial',
    color: '#444',
    textAlign: 'center',
    marginBottom: 10,
    lineHeight: 20,
  },
  planPrice: {
    fontSize: 20,
    fontWeight: '700',
    textAlign: 'center',
    marginBottom: 15,
    fontFamily: 'Arial',
    color: '#000',
  },
  planPriceLight: {
    fontSize: 12,
    fontWeight: '500',
    textAlign: 'right',
    marginBottom: 15,
    fontFamily: 'Arial',
    color: '#000',
  },
  selectButton: {
    width: '100%',
    backgroundColor: '#2690cb',
    borderRadius: 6,
    paddingVertical: 10,
    paddingHorizontal: 20,
    alignSelf: 'center',
    textAlign: 'center',
    marginBottom: 15,
  },
  selectButtonText: {
    color: '#fff',
    fontWeight: '700',
    fontSize: 16,
    fontFamily: 'Arial',
    textAlign: 'center',
  },
  divider: {
    height: 1,
    backgroundColor: '#e0e0e0',
    marginBottom: 15,
  },
  featureRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  icon: {
    marginRight: 8,
  },
  featureText: {
    flex: 1,
    fontSize: 14,
    fontFamily: 'Arial',
    color: '#3f3f3f',
    lineHeight: 20,
  },
  featureOverage: {
    fontSize: 12,
    fontFamily: 'Arial',
    color: '#333',
    lineHeight: 16,
    marginTop: -8,
    marginBottom: 10,
    marginLeft: 29,
  },
  smallText: {
    fontSize: 12,
    fontFamily: 'Arial',
    color: '#333',
    marginBottom: 10,
    textAlign: 'center'
  }
});
