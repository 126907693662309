import React, { useState, useEffect } from 'react';
import { View, TextInput, StyleSheet, Text, TouchableOpacity } from 'react-native';

import Icon from 'react-native-vector-icons/FontAwesome';
import Icon2 from 'react-native-vector-icons/Octicons';

export default function NotificationSidebar({ goToSend, goToAll, goToSettings }) {
    return (
        <View style={styles.sidebarCont}>
            <View style={styles.items}>
                <TouchableOpacity style={styles.icon} onPress={() => goToSend()}>
                    <Icon
                        name="send"
                        size={20}
                        color={'#fff'}
                        onPress={() => goToSend()}
                    />
                </TouchableOpacity>
                <TouchableOpacity style={styles.icon} onPress={() => goToAll()}>
                    <Icon
                        name="book"
                        size={20}
                        color={'#fff'}
                        onPress={() => goToAll()}
                    />
                    {/* <Text style={styles.text}>NEW</Text> */}
                </TouchableOpacity>
                <TouchableOpacity style={styles.icon} onPress={() => goToAnalytics()}>
                    <Icon2
                        name="gear"
                        size={20}
                        color={'#fff'}
                        onPress={() => goToSettings()}
                    />
                    {/* <Text style={styles.text}>NEW</Text> */}
                </TouchableOpacity>
            </View>
        </View> 
    )
}

const styles = StyleSheet.create({
    sidebarCont: {
        padding: 20,
        paddingTop: 30,
        backgroundColor: 'rgb(27, 31, 35)',
        minHeight: '100vh',
        
    },
    text: {
        color: '#e44258',
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: 10
    },
    icon: {
        marginBottom: 60,
        textAlign: 'center'
    },
    items:  {
        position: 'sticky',
        top: 30
    }
})