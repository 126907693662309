import React, { useState, useEffect, useRef } from 'react';
import { ActivityIndicator, View, TextInput, StyleSheet, Text, TouchableOpacity, ScrollView } from 'react-native';
import { useScrollToTop } from '@react-navigation/native';

export default function SendNotification({ appInfo, title, setTitle, body, setBody, bigPictureURL, setBigPictureURL, dataObject, setDataObject, sendNotification }) {
    const [showLoading, setShowLoading] = useState(false);
    const [notReceipts, setNotReceipts] = useState([]);
    const [showDataInput, setShowDataInput] = useState(false);
    const [showSee, setShowSee] = useState(false);
    const [showAPI, setShowAPI] = useState(false);

    const scrollRef = useRef();
    // useScrollToTop(ref);

    useEffect(() => {
        console.log(appInfo.notifications_sent);
        if(appInfo.notifications_sent) {
            setNotReceipts(appInfo.notifications_sent.slice(0, 100));
        }
        window.scroll({ top: 0, left: 0, behavior: 'instant' });
    }, [appInfo]);

    return (
        <ScrollView style={styles.body} ref={scrollRef}>
            {showLoading ? <ActivityIndicator size="large" color="rgb(54, 117, 212)" style={styles.activityInd} /> : null}
            {/* <Text style={styles.HeadingText}>Send a push notification now:</Text> */}

            <View style={styles.inputCont}>
                <Text style={styles.text}>Notification Title:</Text>
                <TextInput 
                    style={styles.inputLarge}
                    onChangeText={(text) => setTitle(text)}
                    placeholder={"Type Notification Title here..."}
                    placeholderTextColor={"#6e6d6c"}
                    value={title}
                />
            </View>
            <View style={styles.inputCont}>
                <Text style={styles.text}>Notification Message:</Text>
                <TextInput 
                    style={styles.inputLarge}
                    onChangeText={(text) => setBody(text)}
                    placeholder={"Type Notification Message here..."}
                    placeholderTextColor={"#6e6d6c"}
                    value={body}
                    multiline={true}
                />
            </View>

            <TouchableOpacity style={styles.advancedText} onPress={() => setShowDataInput(!showDataInput)}>
                <Text>Advanced Settings (optional)</Text> {showDataInput ? <Text>{"   "}&#8593;</Text> : <Text>{"   "}&#8595;</Text>}
            </TouchableOpacity>
            {showDataInput
                ? <View style={styles.inputCont}>
                    <Text style={styles.dataObjText}>Big Picture URL (only works with Android):</Text>
                    <TextInput 
                        style={styles.inputLarge}
                        onChangeText={(text) => setBigPictureURL(text)}
                        placeholder={'Paste the URL to the big picture you would like to appear in your push notification here...'}
                        placeholderTextColor={"#6e6d6c"}
                        value={bigPictureURL}
                        multiline={true}
                    />

                    <Text style={styles.dataObjText}>Push Data Object (only works with Android):</Text>
                    <TextInput 
                        style={styles.inputLarge}
                        onChangeText={(text) => setDataObject(text)}
                        placeholder={'Type data object here in this format: { "propertyName":"propertyValue" }'}
                        placeholderTextColor={"#6e6d6c"}
                        value={dataObject}
                        multiline={true}
                    />
                    
                    <TouchableOpacity style={styles.seeText} onPress={() => setShowSee(!showSee)}>
                        <Text>See how Push Data Objects work</Text>
                        {showSee ? <Text>{"   "}&#8593;</Text> : <Text>{"   "}&#8595;</Text>}
                    </TouchableOpacity>
                    {showSee 
                        ? <View style={styles.blueBackCont}>
                            <Text style={styles.boldTextTop}>Push Data Objects can be accessed once the user taps on your push notification.</Text> 

                            <Text style={styles.boldText}>Follow these steps to see push data objects in your app:</Text> 

                            <Text style={styles.boldText}>Step 1:</Text>
                            <Text style={styles.blackBacktext}>
                                <Text style={styles.mbSmall}>flutter pub upgrade native_notify</Text>
                            </Text>

                            <Text style={styles.boldText}>Step 2:</Text>
                            <Text style={styles.plainText}>Make sure these imports are included at the top of your main.dart file:</Text>
                            <Text style={styles.blackBacktext}>
                                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.orangeText}>'package:native_notify/native_notify.dart'</Text>;</Text>
                                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.orangeText}>'package:awesome_notifications/awesome_notifications.dart'</Text>;</Text>
                            </Text>

                            <Text style={styles.boldText}>Step 3:</Text>
                            <Text style={styles.plainText}>Inside of the State portion of your main.dart file, paste this code:</Text>
                            <Text style={styles.blackBacktext}>
                                <Text style={styles.blueText}>@override</Text>
                                <Text><Text style={styles.blueText}>void</Text> <Text style={styles.yellowText}>initState</Text>() {"{"}</Text>
                                <Text>    <Text style={styles.blueText}>super</Text>.<Text style={styles.yellowText}>initState</Text>();</Text>
                                <Text>    <Text style={styles.greenText}>AwesomeNotifications</Text>().actionStream.<Text style={styles.yellowText}>listen</Text>((receivedNotification) {"{"}</Text>
                                <Text>         <Text style={styles.yellowText}>print</Text>(pushDataObject);</Text>
                                <Text>    {"}"});</Text>
                                <Text>{"}"}</Text>
                            </Text>
                            <Text style={styles.plainText}>This 'pushDataObject' contains the value of your pushDataObject.</Text>

                            <Text style={styles.boldText}>Step 4:</Text>
                            <Text style={styles.plainText}>You can access the value of one of the keys in your pushDataObject like this:</Text>
                            <Text style={styles.blackBacktext}>
                                <Text style={styles.plainText}>pushDataObject['yourKeyName']</Text>
                            </Text>
                          </View>
                        : null
                    }
                  </View>
                : null
            }
            
            <TouchableOpacity style={styles.sendButton} onPress={() => sendNotification()}>
                <Text style={styles.buttonText}>Send Push Notification</Text>
            </TouchableOpacity>

            {appInfo.app_sub_count > 0
                ? <Text style={styles.mb}><Text style={styles.HeadingTextBold}>Push Notification Subscriber Count:</Text> {appInfo.app_sub_count}</Text>
                : null
            }
            
            <Text style={styles.plainText}>Did you know you can send emojis in your push notifications? A collection of emojis can be found here: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://emojiterra.com')}><Text>https://emojiterra.com</Text></TouchableOpacity>.</Text>

            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>
            <TouchableOpacity style={styles.showAPIButton} onPress={() => setShowAPI(!showAPI)}>
                <Text style={styles.buttonText}>Send API (optional): {showAPI ? <Text>{" "}&#8593;</Text> : <Text>{" "}&#8595;</Text>}</Text>
            </TouchableOpacity>

            {showAPI 
                ? <View>
                    <Text style={styles.mb}></Text>
                    <Text style={styles.mb}>You can also send push notifications using our API if you would like. Here are the steps:</Text>
                    <Text style={styles.boldText}>Step 1:</Text>
                        <Text style={styles.plainText}>Post to this URL:</Text>
                        <Text style={styles.blackBacktext}>
                            <Text>https://app.nativenotify.com/api/flutter/notification</Text>
                        </Text>
                        <Text style={styles.boldText}>Step 2:</Text>
                        <Text style={styles.mb}>Use this post body:</Text>
                        <Text style={styles.blackBacktext}>
                            <Text>{` {`}</Text>
                            <Text>      'flutterAppId': '{appInfo.flutter_app_id}',</Text>
                            <Text>      'flutterAppToken': '{appInfo.flutter_app_token}',</Text>
                            <Text>      'title': 'put your push notification title here as a string',</Text>
                            <Text>      'body': 'put your push notification message here as a string',</Text>
                            <Text>      'bigPictureURL': <Text style={styles.blueText}>null</Text>,</Text>
                            <Text>      'data': '{'{ "yourProperty":"yourPropertyValue" }'}',</Text>
                            <Text>{" }"}</Text>
                        </Text>
                        <Text style={styles.mb}></Text>
                    <Text style={styles.mb}></Text>
                    <Text style={styles.plainText}>'bigPictureURL' and 'data' API values are optional. If you do NOT want to use these post values, put null as it's value or remove the values from the post request. 'bigPictureURL' and 'data' only works with Android.</Text>
                    <Text style={styles.boldText}>Make sure to specify that this is an 'application/json' post type in your post header.</Text>
                  </View>
                : null
            }
            

            {/* <Text style={styles.text}>Notification Receipts:</Text>
            {notReceipts.map((e, i) => {
                let obj = JSON.parse(e);

                return (
                    <View style={styles.recCont} key={i}>
                        <Text style={styles.recText}><Text style={styles.recTextBold}>Date:</Text>     {obj.dateSent}</Text>
                        <Text style={styles.recText}><Text style={styles.recTextBold}>Users sent notification:</Text>     {obj.numberOfSubsSentNotification}</Text>
                        <Text style={styles.recText}><Text style={styles.recTextBold}>Users who successfully received notification:</Text>     {obj.numberOfSubsReceivedNotification}</Text>
                        <Text style={styles.recText}><Text style={styles.recTextBold}>Title of notification:</Text>     {obj.title}</Text>
                        <Text style={styles.recText}><Text style={styles.recTextBold}>Message of notification:</Text>     {obj.body}</Text>
                    </View>
                )
            })} */}
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    activityInd: {
        height: '100vh',
        width: '116%',
        position: 'absolute', 
        top: '-8%', 
        right: '-8%', 
        bottom: '-5%', 
        left: '-8%',
        zIndex: 100,
        backgroundColor: '#00000040'
    },
    body: {
        width: '85%',
        paddingTop: 30,
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingBottom: 100
    },
    HeadingText: {
        fontFamily: "Arial",
        fontSize: 20,
        marginBottom: 30,
        fontWeight: 700
    },
    HeadingTextBold: {
        fontFamily: "Arial",
        fontSize: 16,
        fontWeight: 700,
        marginBottom: 30
    },
    buttonText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 16,
        color: '#fff',
    },
    guideButton: {
        width: 150,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
        marginBottom: 70,
    },
    sendButton: {
        width: 220,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
        marginTop: 30,
        marginBottom: 70,
    },
    showAPIButton: {
        width: 220,
        backgroundColor: 'black',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
        marginTop: 0,
        marginBottom: 30,
    },
    inputCont: {
        marginBottom: 20,
        width: '100%',
    },
    inputLarge: {
        width: '100%',
        padding: 12.5,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 15
    },

    recCont: {
        width: '100%',
        backgroundColor: '#fff',
        marginTop: 10,
        marginBottom: 10,
        padding: 20,
        paddingBottom: 15,
        borderRadius: 6,
        flexWrap: 'wrap',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
    },
    recText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 14,
        marginBottom: 5,
    },
    recTextBold: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 5,
    },

    text: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginBottom: 5
    },
    blueText: {
        color: "rgb(54, 117, 212)"
    },
    advancedText: {
        flexDirection: 'row',
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 14,
        marginTop: -10,
        marginBottom: 10
    },
    dataObjText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginTop: 15,
        marginBottom: 5
    },
    seeText: {
        flexDirection: 'row',
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 14,
        marginBottom: 10,
    },
    blackBacktext: {
        backgroundColor: "#1b1f23",
        color: "#fff",
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        padding: 20,
        borderRadius: 6,
        marginBottom: 5,
        display: "flex",
        flexDirection: "column"
    },
    tab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50
    },
    greentab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50,
        color: 'rgb(81, 167, 81)'
    },
    yellowText: {
        color: "rgb(222, 222, 160)"
    },
    mb: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 20
    },
    lightBlueText: {
        color: "rgb(144, 180, 218)"
    },
    boldText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginTop: 20,
        marginBottom: 5
    },
    boldTextTop: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        // marginTop: 20,
        marginBottom: 5
    },
    purpleText: {
        color: "rgb(187, 86, 187)"
    },
    blueBackCont: {
        backgroundColor: '#3675d420',
        padding: 15,
        borderRadius: 6
    },
    dottedLine: {
        height: 1,
        width: "100%",
        borderTopWidth: 1,
        borderColor: "#000",
        borderStyle: "dotted",
        marginTop: 30,
        marginBottom: 30,
    },
    greenText: {
        color: '#76d7aa'
    },
    orangeText: {
        color: "#c78c74"
    },
    plainText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 5
    }
});