import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Platform, TextInput } from 'react-native';
import axios from '../../../../axios';

import Icon from 'react-native-vector-icons/MaterialIcons';

import DocsMenu from './Components/DocsMenu';

export default function IOSDocs({ host, appInfo, InAppNavigation, updateAppInfo }) {
    const { goToSend, setScreenName } = InAppNavigation;

    const { fcm_server_key, apn_p8_file_name, apn_key_id, apn_team_id, apn_bundle_id } = appInfo;
    // console.log(fcm_server_key, apn_p8_file_name, apn_key_id, apn_team_id, apn_bundle_id);

    const [FcmServerKey, setFcmServerKey] = useState('');

    const [apnP8FileName, setApnP8FileName] = useState('');
    const [apnKeyId, setApnKeyId] = useState('');
    const [apnTeamId, setApnTeamId] = useState('');
    const [apnBundleId, setApnBundleId] = useState('');

    useEffect(() => {
        fcm_server_key ? setFcmServerKey(fcm_server_key) : null;
        apn_p8_file_name ? setApnP8FileName(apn_p8_file_name) : null;
        apn_key_id ? setApnKeyId(apn_key_id) : null;
        apn_team_id ? setApnTeamId(apn_team_id) : null;
        apn_bundle_id ? setApnBundleId(apn_bundle_id) : null;
        window.scrollTo(0, 0)
    }, []);

    const updateSettings = () => {
        axios
            .put(`${host}/api/settings`, { 
                flutterAppID: appInfo.app_id, 
                FcmServerKey,
                apnP8FileName, 
                apnKeyId, 
                apnTeamId, 
                apnBundleId })
            .then(() => {
                updateAppInfo();

                alert('Update successful!');
            })
            .catch(err => console.log(err))
    }

    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');

    function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }
    
    return (
        <View style={styles.body}>
            <DocsMenu InAppNavigation={InAppNavigation} />

            <Text style={styles.HeadingText}>Follow these steps to get Native Notify push notifications working in iOS:</Text>
            <Text style={styles.plainText}>Follow the <TouchableOpacity onPress={() => setScreenName('installDocs')}>'Start Here'</TouchableOpacity> instructions BEFORE setting up iOS push notifications.</Text>

            <Text style={styles.mb}></Text>

            {Platform.OS === "web"
                ? <View style={styles.youTubeVideo}>
                    <Text style={styles.h2}>This video walks you through the setup guide below:</Text>
                    <iframe width="400" height="220" style={{ maxWidth: '100%' }} src="https://www.youtube.com/embed/7jXRDQ89rFk?rel=0" title="Native Notify Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen="allowfullscreen"></iframe>
                    
                    </View>
                : null 
            }   

            <View style={styles.dottedLine}></View>

            <View style={styles.container}>
                <Text style={styles.HeadingText}>Update iOS Push Notifications Settings:</Text>
                <Text style={styles.mb}>
                    You can find all of this information at this link: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://developer.apple.com/account')}>https://developer.apple.com/account</TouchableOpacity>
                </Text>                
                {apn_p8_file_name
                    ? <View>
                        <Text style={styles.text}>An Apple .p8 File has already been uploaded to your account ({apnP8FileName}).</Text>
                        <View style={styles.row}>
                            <Text style={styles.rowText}>Upload a new Apple .p8 File:</Text>
                            <iframe style={{ height: 100, width: '100%', border: 0 }} src="https://p8storage.nativenotify.com"></iframe>
                        </View>
                      </View>
                    : <View style={styles.row}>
                        <Text style={styles.rowText}>Upload an Apple .p8 File:</Text>
                        <iframe style={{ height: 100, width: '100%', border: 0 }} src="https://p8storage.nativenotify.com"></iframe>
                      </View>
                }
                
                <View style={styles.row}>
                    <Text style={styles.rowText}>Apple .p8 File Name:</Text>
                    <TextInput
                        style={styles.inputLarge}
                        onChangeText={(text) => setApnP8FileName(text)}
                        placeholder={"Paste Apple .p8 File Name here..."}
                        placeholderTextColor={"#6e6d6c"}
                        value={apnP8FileName}
                    />
                </View>
                <View style={styles.row}>
                    <Text style={styles.rowText}>Apple Auth Key ID:</Text>
                    <TextInput
                        style={styles.inputLarge}
                        onChangeText={(text) => setApnKeyId(text)}
                        placeholder={"Paste Apple Auth Key ID here..."}
                        placeholderTextColor={"#6e6d6c"}
                        value={apnKeyId}
                    />
                </View>
                <View style={styles.row}>
                    <Text style={styles.rowText}>Apple Team ID:</Text>
                    <TextInput
                        style={styles.inputLarge}
                        onChangeText={(text) => setApnTeamId(text)}
                        placeholder={"Paste Apple Team ID here..."}
                        placeholderTextColor={"#6e6d6c"}
                        value={apnTeamId}
                    />
                </View>
                <View style={styles.row}>
                    <Text style={styles.rowText}>Apple Bundle Identifier:</Text>
                    <TextInput
                        style={styles.inputLarge}
                        onChangeText={(text) => setApnBundleId(text)}
                        placeholder={"Paste Apple Bundle Identifier here..."}
                        placeholderTextColor={"#6e6d6c"}
                        value={apnBundleId}
                    />
                </View>

                <TouchableOpacity style={styles.button} onPress={() => updateSettings()}>
                    <Text style={styles.buttonText}>Update Settings</Text>
                </TouchableOpacity>
            </View>

            <View style={styles.mb}></View>

            <Text style={styles.boldText}>How to test iOS Production Push Notifications:</Text>
            <Text style={styles.boldText}>After you update iOS settings, Expo Go push notifications will NOT work anymore. You will need to use TestFlight or an actual iOS app on the App Store to test push notifications.</Text>
            <Text style={styles.plainText}>If you would like to continue to test push notifications in Expo Go, remove the values from the inputs above or from your settings page and click save. If the iOS settings inputs are empty, push notifications will work in Expo Go again.</Text>

            <View style={styles.dottedLine}></View>

            <Text style={styles.HeadingText}>Congratulations! </Text>
            <Text style={styles.plainText}>You are now ready to send push notifications in iOS production mode! You can test this out by installing an apk of your app directly onto an Android phone, opening the app, and then clicking the   <TouchableOpacity onPress={() => goToSend()}>
                <Icon
                    name="send"
                    size={20}
                    color={'#000'}
                    onPress={() => goToSend()}
                />
            </TouchableOpacity>   button at the top left of this page to send a push notification to your phone.</Text>
            <Text style={styles.boldText}>Note: Push notifications will NOT work on an emulator/simulator. Push notifications will ONLY work on an actual device.</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    body: {
        width: 'calc(100% - 60px)',
        paddingTop: 30,
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingBottom: 200,
    },
    flexRow: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    leftCont: {
        width: screen.width > 900 ? '48%' : '100%',
    },
    rightCont: {
        width: screen.width > 900 ? '48%' : '100%',
        backgroundColor: screen.width > 900 ? '#3675d420' : '#fff',
        padding: screen.width > 900 ? 15 : 0,
        borderRadius: screen.width > 900 ? 6 : 0
    },
    stickyRight: {
        position: screen.width > 900 ? 'sticky' : 'relative',
        top: screen.width > 900 ? 10 : null
    },

    buttonText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 16,
        color: '#fff',
    },
    button: {
        width: 220,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
    },
    HeadingTextTop: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 20,
        justifyContent: 'center',
        marginBottom: -5
    },
    HeadingTextBottom: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        marginBottom: 30,
        justifyContent: 'center'
    },
    HeadingText: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        // marginTop: 70,
        marginBottom: 10
    },
    HeadingText2: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        marginTop: 70,
        marginBottom: 10
    },
    h2: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 20,
        marginBottom: 10
    },
    boldText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginTop: 20,
        marginBottom: 5
    },
    boldTextTop: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        // marginTop: 20,
        marginBottom: 5
    },
    blackBacktext: {
        backgroundColor: "#1b1f23",
        color: "#fff",
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        padding: 20,
        borderRadius: 6,
        marginBottom: 5,
        display: "flex",
        flexDirection: "column"
    },
    blueText: {
        color: "rgb(54, 117, 212)"
    },
    medBlueText: {
        color: "rgb(81, 130, 204)"
    },
    dottedLine: {
        height: 1,
        width: "100%",
        borderTopWidth: 1,
        borderColor: "#000",
        borderStyle: "dotted",
        marginTop: 30,
        marginBottom: 30,
    },
    lightBlueText: {
        color: "rgb(144, 180, 218)"
    },
    plainText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 5
    },
    purpleText: {
        color: "rgb(187, 86, 187)"
    },
    mb: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 20
    },
    mbSmall: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 5
    },
    tab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50
    },
    greentab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50,
        color: 'rgb(81, 167, 81)'
    },
    yellowText: {
        color: "rgb(222, 222, 160)"
    },
    greenText: {
        color: 'rgb(81, 167, 81)'
    },
    sendCont: {
        padding: 20,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 15
    },
    sendButton: {
        width: 220,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
        marginTop: 5,
        // marginBottom: 70,
    },
    inputCont: {
        marginBottom: 20,
        width: '100%',
    },
    inputLarge: {
        width: '100%',
        padding: 12.5,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 15
    },
    orangeText: {
        color: "#c78c74"
    },
    container: {
        width: '100%',
        backgroundColor: '#fff',
        padding: 20,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        borderRadius: 6,
        marginBottom: 20
    },
    row: {
        flexDirection: "row",
        alignItems: 'center',
        marginTop: 10
    },
    rowText: {
        width: 270,
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 10,
        marginRight: 20
    },
    text: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 10,
    }
});