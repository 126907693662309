import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, TextInput, Platform } from 'react-native';
import axios from '../../../../axios';

import Icon from 'react-native-vector-icons/FontAwesome';

import DocsMenu from './Components/DocsMenu';
import Dropzone from '../Components/Dropzone';

export default function AndroidDocs({ host, appInfo, InAppNavigation, updateAppInfo }) {
    const { goToSend, setScreenName } = InAppNavigation;

    const { firebase_project_id, google_cloud_access_key_json_file, fcm_server_key, apn_p8_file_name, apn_key_id, apn_team_id, apn_bundle_id } = appInfo;
    // console.log(fcm_server_key, apn_p8_file_name, apn_key_id, apn_team_id, apn_bundle_id);

    // FCM legacy
    const [FcmServerKey, setFcmServerKey] = useState('');

    // FCM v1
    const [FcmV1ProjectId, setFcmV1ProjectId] = useState('');
    const [uploadedFileName, setUploadedFileName] = useState(null);
    const [googleCATJson, setGoogleCATJson] = useState({});

    const [apnP8FileName, setApnP8FileName] = useState('');
    const [apnKeyId, setApnKeyId] = useState('');
    const [apnTeamId, setApnTeamId] = useState('');
    const [apnBundleId, setApnBundleId] = useState('');

    useEffect(() => {
        firebase_project_id ? setFcmV1ProjectId(firebase_project_id) : null;
        google_cloud_access_key_json_file
            ? (
                setGoogleCATJson(google_cloud_access_key_json_file), 
                setUploadedFileName(google_cloud_access_key_json_file.project_id)
              )
            : null;
        fcm_server_key ? setFcmServerKey(fcm_server_key) : null;
        apn_p8_file_name ? setApnP8FileName(apn_p8_file_name) : null;
        apn_key_id ? setApnKeyId(apn_key_id) : null;
        apn_team_id ? setApnTeamId(apn_team_id) : null;
        apn_bundle_id ? setApnBundleId(apn_bundle_id) : null;
    }, []);

    const updateSettings = () => {
        console.log("FcmV1ProjectId, googleCATJson: ", FcmV1ProjectId, googleCATJson)
        axios
            .put(`${host}/api/settings`, { 
                appID: appInfo.app_id, 
                FcmV1ProjectId,
                googleCATJson,
                FcmServerKey,
                apnP8FileName, 
                apnKeyId, 
                apnTeamId, 
                apnBundleId 
            })
            .then(() => {
                updateAppInfo();

                alert('Update successful!');
            })
            .catch(err => console.log(err))
    }

    const handleFileUpload = (jsonData) => {
        // Handle the uploaded JSON file data
        console.log(jsonData);
        setGoogleCATJson(jsonData);
    };
    

    return (
        <View style={styles.body}>
             <DocsMenu InAppNavigation={InAppNavigation} />
            {/* <TouchableOpacity style={styles.button} onPress={() => { setShowInstallDocs(false); setShowSendNot(true); setShowInstantInstallDocs(false); }}>
                <Text style={styles.buttonText}>Send a Push Notification</Text>
            </TouchableOpacity> */}

            <Text style={styles.HeadingText}>Follow these steps to get Native Notify push notifications working in Android production mode:</Text>
            <Text style={styles.plainText}>These instructions show how to get Native Notify push notifications working in Android production mode. After you follow these instructions, you will be able to send push notifications to all of your Android app users.</Text>
            <Text style={styles.boldText}>Make sure you follow the <TouchableOpacity onPress={() => setScreenName('installDocs')}><Text>'Start Here'</Text></TouchableOpacity> instructions BEFORE you set up Android production push notifications. Android production push notifications will not work without first following the <TouchableOpacity onPress={() => setScreenName('installDocs')}><Text>'Start Here'</Text></TouchableOpacity> instructions.</Text>

            <Text style={styles.mb}></Text>

            {Platform.OS === "web"
                ? <View style={styles.youTubeVideo}>
                    <Text style={styles.h2}>This video walks you through the setup guide below:</Text>
                    <iframe width="400" height="220" style={{ maxWidth: '100%' }} src="https://www.youtube.com/embed/pQvl-NX9mW4?rel=0" title="Native Notify Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen="allowfullscreen"></iframe>
                    
                  </View>
                : null 
            }

            <View style={styles.dottedLine}></View>

            <Text style={styles.mb}>
                Firebase is required for production Android push notifications to work. Follow the instructions below to create a Firebase project and to get the project information you need to set up production Android push notifications.
            </Text>

            <Text style={styles.HeadingText}>Set up Firebase Project and find your Firebase Project settings:</Text>

            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.plainText}>
                Go to this link: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://console.firebase.google.com')}>
                   <Text>https://console.firebase.google.com</Text> 
                </TouchableOpacity>
            </Text>

            <Text style={styles.boldText}>Step 2:</Text>
            <Text style={styles.plainText}>Click "Add project" and follow the instructions. This will take you to the "Project Overview" page.</Text>

            <Text style={styles.boldText}>Step 3:</Text>
            <Text style={styles.plainText}>In the "Project Overview" page, click the Android icon in the middle of the screen to create an Android app and follow the instructions.</Text>
            <Text style={styles.plainTextSmall}> - You can find your "Android package name" in your app.json file in the "android" object in the "package" property</Text>
            <Text style={styles.plainTextSmall}> - If you do not see a "package" property in your "android" object, create one that looks like this: "package": "com.orgname.appname"</Text>

            <Text style={styles.boldText}>Step 4:</Text>
            <Text style={styles.plainText}>As you are creating your Firebase Android app, download the "google-service.json" file and place the file in the root of your Expo app.</Text>

            <Text style={styles.boldText}>Step 5:</Text>
            <Text style={styles.plainText}>In your app.json file, add this key-value pair to the "android" object: "googleServicesFile": "./google-services.json"</Text>

            {/* TO DO: add a black background coding example of what the "android" object should look like here */}

            <Text style={styles.boldText}>Step 6:</Text>
            <Text style={styles.plainText}>As you are creating your Firebase Android app, skip the "Add Firebase SDK" step.</Text>

            <Text style={styles.boldText}>Step 7:</Text>
            <Text style={styles.plainText}>Click the gear icon to the right of the "Project Overview" text in the top left of the screen and then click "Project settings".</Text>

            {/* <View style={styles.dottedLine}></View> */}
            
            {/* 

            <Text style={styles.HeadingText}>URGENT UPCOMING UPDATE:</Text>
            <Text style={styles.mb}>June 10, 2024, Google will stop allowing Firebase Legacy Server Keys to work. You will HAVE to use the new (v1) method at that point. Until then, you can use either Firebase (v1) or Firebase Legacy.</Text>
            <Text style={styles.mb}>Below, you can choose to use the Firebase (v1) Messaging method OR the Firebase Legacy Server Key method to set up Android production push notifications until June 10, 2024.</Text>
            <Text style={styles.plainText}>After June 10, 2024, you MUST use Firebase (v1) Messaging or Google has said your push notifications will stop working.</Text>

            <View style={styles.dottedLine}></View> */}

            {/* <Text style={styles.boldTextTop}>How to get your Firebase (v1) Messaging Information:</Text> */}
            <Text style={styles.boldText}>Step 8, get your Firebase Project ID:</Text>
            <Text style={styles.plainText}>In your Firebase project settings, in the General tab, copy and paste the "Project ID" below:</Text>
            <View style={styles.row}>
                <TextInput
                    style={styles.inputLarge}
                    onChangeText={(text) => setFcmV1ProjectId(text)}
                    placeholder={"Paste FCM Project ID here..."}
                    placeholderTextColor={"#6e6d6c"}
                    value={FcmV1ProjectId}
                />
            </View>
            <Text style={styles.boldTextTop}>Step 9, get your Google Cloud Access Token JSON file:</Text>
            {/* you're here */}
            <Text style={styles.plainText}>- Go to this Google Cloud Console link: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://console.cloud.google.com/projectselector2/iam-admin/serviceaccounts')}>
               <Text>https://console.cloud.google.com/projectselector2/iam-admin/serviceaccounts</Text>
            </TouchableOpacity></Text>
            <Text style={styles.plainText}>- Select your project. (If you do not see your project, click the "SELECT PROJECT" button in the top right, then click ALL to find your project.)</Text>
            <Text style={styles.plainText}>- Locate the desired service account and click on the three-dot Actions menu on the right.</Text>
            <Text style={styles.plainText}>- Select "Manage keys" from the drop-down menu</Text>
            <Text style={styles.plainText}>- Click "Add Key" then "Create new key"</Text>
            <Text style={styles.plainText}>- Choose the key type as "JSON" and click "Create" to download the JSON key file</Text>
            <Text style={styles.plainText}>- Upload that JSON file below:</Text>

            <Dropzone onFileUpload={handleFileUpload} uploadedFileName={uploadedFileName} setUploadedFileName={setUploadedFileName} />

            <TouchableOpacity style={styles.button} onPress={() => updateSettings()}>
                <Text style={styles.buttonText}>Save Firebase (v1) Information</Text>
            </TouchableOpacity>

            <View style={styles.dottedLine}></View>

            {/* 

            <Text style={styles.boldTextTop}>How to get your Firebase Legacy Server key:</Text>
            <Text style={styles.mb}>(If you have followed the "How to get your Firebase (v1) Messaging Information" instructions, you do NOT need a Firebase Legacy Server key. You can skip this section.)</Text>
            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.plainText}>In your Firebase project settings, click "Cloud Messaging" in the upper left part of the screen.</Text>

            <Text style={styles.boldText}>Step 2:</Text>
            <Text style={styles.plainText}>In the Cloud Messaging tab, click the three little dots in the top right corner of the "Cloud Messaging API (Legacy)" container to enable the legacy Server key.</Text>

            <Text style={styles.boldText}>Step 3:</Text>
            <Text style={styles.plainText}>Copy and paste the Cloud Messaging API (Legacy) Server key below and click "Save FCM Server Key":</Text>

            <View style={styles.row}>
                <TextInput
                    style={styles.inputLarge}
                    onChangeText={(text) => setFcmServerKey(text)}
                    placeholder={"Paste FCM Server Key here..."}
                    placeholderTextColor={"#6e6d6c"}
                    value={FcmServerKey}
                />
            </View>

            <TouchableOpacity style={styles.button} onPress={() => updateSettings()}>
                <Text style={styles.buttonText}>Save FCM Server Key</Text>
            </TouchableOpacity>

            <View style={styles.dottedLine}></View> */}

            <Text style={styles.HeadingText}>How to test Android Production Push Notifications:</Text>
            <Text style={styles.boldText}>After you save Firebase information, Expo Go push notifications will NOT work anymore. You will need to create an apk file and install it onto an actual phone or publish your Android app to the Google Play Store to test push notifications.</Text>
            <Text style={styles.mb}>If you would like to continue to test push notifications in Expo Go, remove the Firebase Project ID text or the Firebase Server Key text (whichever you used) from the input above and click save, and push notifications will work in Expo Go again.</Text>
            <Text style={styles.plainText}>This link explains how to create an APK file to test notifications: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://docs.expo.dev/build-reference/apk')}>
               <Text>https://docs.expo.dev/build-reference/apk</Text>
            </TouchableOpacity></Text>

            
            <View style={styles.dottedLine}></View>
            <Text style={styles.HeadingText}>Congratulations! </Text>
            <Text style={styles.plainText}>You are now ready to send push notifications in Android production mode! You can test this out by installing an apk of your app directly onto an Android phone, opening the app, and then clicking the   <TouchableOpacity onPress={() => goToSend()}>
                <Icon
                    name="send"
                    size={20}
                    color={'#000'}
                    onPress={() => goToSend()}
                />
            </TouchableOpacity>   button at the top left of this page to send a push notification to your phone.</Text>
            <Text style={styles.boldText}>Note: Push notifications will NOT work on an emulator/simulator. Push notifications will ONLY work on an actual device.</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    body: {
        width: 'calc(100% - 60px)',
        paddingTop: 30,
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingBottom: 200,
    },
    flexRow: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    leftCont: {
        width: screen.width > 900 ? '48%' : '100%',
    },
    rightCont: {
        width: screen.width > 900 ? '48%' : '100%',
        backgroundColor: screen.width > 900 ? '#3675d420' : '#fff',
        padding: screen.width > 900 ? 15 : 0,
        borderRadius: screen.width > 900 ? 6 : 0
    },
    stickyRight: {
        position: screen.width > 900 ? 'sticky' : 'relative',
        top: screen.width > 900 ? 10 : null
    },

    buttonText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 16,
        color: '#fff',
    },
    button: {
        width: 300,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
    },
    HeadingText: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        // marginTop: 70,
        marginBottom: 10
    },
    HeadingText2: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        marginTop: 70,
        marginBottom: 10
    },
    h2: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 20,
        marginBottom: 10
    },
    boldText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginTop: 20,
        marginBottom: 5
    },
    boldTextTop: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        // marginTop: 20,
        marginBottom: 5
    },
    blackBacktext: {
        backgroundColor: "#1b1f23",
        color: "#fff",
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        padding: 20,
        borderRadius: 6,
        marginBottom: 5,
        display: "flex",
        flexDirection: "column"
    },
    blueText: {
        color: "rgb(54, 117, 212)"
    },
    medBlueText: {
        color: "rgb(81, 130, 204)"
    },
    dottedLine: {
        height: 1,
        width: "100%",
        borderTopWidth: 1,
        borderColor: "#000",
        borderStyle: "dotted",
        marginTop: 30,
        marginBottom: 30,
    },
    lightBlueText: {
        color: "rgb(144, 180, 218)"
    },
    plainText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 5
    },
    plainTextSmall: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 12,
        marginBottom: 5
    },
    purpleText: {
        color: "rgb(187, 86, 187)"
    },
    mb: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 20
    },
    mbSmall: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 5
    },
    tab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50
    },
    greentab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50,
        color: 'rgb(81, 167, 81)'
    },
    yellowText: {
        color: "rgb(222, 222, 160)"
    },
    greenText: {
        color: 'rgb(81, 167, 81)'
    },
    inputLarge: {
        width: '100%',
        padding: 12.5,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 15
    }
});