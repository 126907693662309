import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Platform } from 'react-native';

import DocsMenu from './Components/DocsMenu';

export default function IndieInstallDocs({ appInfo, InAppNavigation }) {
    const { setScreenName } = InAppNavigation;
    
    return (
        <View style={styles.body}>
            <DocsMenu InAppNavigation={InAppNavigation} />
            <Text style={styles.HeadingText}>Follow these steps to send customized push notifications to individual users:</Text>
            <Text style={styles.plainText}>These instructions show how to send customized push notifications to individual users. We call these notifications "Indie" push notifications. </Text>
            <Text style={styles.boldText}>Make sure you follow the <TouchableOpacity onPress={() => setScreenName('installDocs')}>'Start Here'</TouchableOpacity> instructions BEFORE you set up Indie push notifications. Indie push notifications will not work without first following the <TouchableOpacity onPress={() => setScreenName('installDocs')}>'Start Here'</TouchableOpacity> instructions.</Text>
            
            <Text style={styles.mb}></Text>
            <Text style={styles.mb}></Text>

            {Platform.OS === "web"
                ? <View style={styles.youTubeVideo}>
                    <Text style={styles.h2}>This video walks you through the setup guide below:</Text>
                    <iframe width="400" height="220" src="https://www.youtube.com/embed/4-DCqfAnnFU?si=nFaTAkePPbYFmCml?rel=0" title="Native Notify Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen="allowfullscreen"></iframe>
                    
                    </View>
                : null 
            }

            <View style={styles.dottedLine}></View>

            <Text style={styles.boldText}>API preview:</Text>
            <Text style={styles.plainText}>Here's how our Indie push notification API works:</Text>
            <Text style={styles.mb}></Text>
            <Text style={styles.plainText}><Text style={styles.bT}>Registration:</Text> You have to send us a unique identifier for each app user you want to send an Indie push notification to.</Text>
            <Text style={styles.plainText}><Text style={styles.bT}>Use:</Text> You can then use this unique identifier whenever you want to send an Indie push notification.</Text>
            <Text style={styles.mb}></Text>
            <Text style={styles.plainText}>Usually, how people do this is by placing our Indie registration API in a login function. So, once one of your users logs into your app, you can send us that user's unique identifier in the login function. Usually a unique identifier is the user's email, phone number, or a unique user ID you have assigned to a user in your database.</Text><Text style={styles.bT}>It's very important that your user's ID is UNIQUE. Make sure no other users share the same user ID.</Text>

            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>
            <Text style={styles.HeadingText}>Registration setup:</Text>

            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.blackBacktext}>npm install axios</Text> 

            <Text style={styles.boldText}>Step 2:</Text>
            <Text style={styles.plainText}>In your login component, make sure these imports are included at the top of the screen:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>{"{ registerIndieID, unregisterIndieDevice }"} </Text><Text style={styles.purpleText}>from</Text> 'native-notify';</Text>
                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>axios</Text> <Text style={styles.purpleText}>from</Text> 'axios';</Text>
            </Text>

            <Text style={styles.boldText}>Step 3:</Text>
            <Text style={styles.plainText}>Make sure you are using a Hook function as your login component. Here is an example:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.purpleText}>export default</Text> <Text style={styles.medBlueText}>function</Text> <Text style={styles.yellowText}>LoginScreen</Text>() {"{"}</Text>
                <Text>...</Text>
                <Text>{"}"}</Text>
            </Text>
            <Text style={styles.plainText}>
                This link explains Hooks in detail: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://reactjs.org/docs/hooks-intro.html')}>https://reactjs.org/docs/hooks-intro.html</TouchableOpacity>
            </Text>

            <Text style={styles.boldText}>Step 4 (Login / Register):</Text>
            <Text style={styles.plainText}>In your login function, use this code to register the device your individual user logs in from:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.medBlueText}>const</Text> <Text style={styles.yellowText}>yourLoginFunction</Text> = () <Text style={styles.lightBlueText}>=></Text> {"{"}</Text>
                <Text style={styles.mb}>    ...</Text>
                <Text style={styles.mb}>    // Native Notify Indie Push Registration Code</Text>
                <Text style={styles.mb}>    <Text style={styles.yellowText}>registerIndieID</Text>('put your unique user ID here as a string', {appInfo.app_id}, '{appInfo.app_token}');</Text>
                <Text>    // End of Native Notify Code</Text>
                <Text style={styles.mb}>{"}"}</Text>
            </Text>
            <Text style={styles.plainText}>Do NOT change the 'appId' or the 'appToken' value in this code. We put your appId and appToken in the code for you. </Text>

            <Text style={styles.boldText}>How to Logout / Unregister an Indie Sub device:</Text>
            <Text style={styles.plainText}>In your logout function, use this code to unregister the device your individual user logs out from:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.medBlueText}>const</Text> <Text style={styles.yellowText}>yourLogoutFunction</Text> = () <Text style={styles.lightBlueText}>=></Text> {"{"}</Text>
                <Text style={styles.mb}>    ...</Text>
                <Text style={styles.mb}>    // Native Notify Indie Push Registration Code</Text>
                <Text style={styles.mb}>    <Text style={styles.yellowText}>unregisterIndieDevice</Text>('put your unique user ID here as a string', {appInfo.app_id}, '{appInfo.app_token}');</Text>
                <Text>    // End of Native Notify Code</Text>
                <Text style={styles.mb}>{"}"}</Text>
            </Text>
            <Text style={styles.plainText}>Do NOT change the 'appId' or the 'appToken' value in this code. We put your appId and appToken in the code for you. </Text>

            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>
            <Text style={styles.HeadingText}>How to send an Indie push notification:</Text>
            <Text style={styles.plainText}>At this point, you are ready to send Indie push notifications. Whenever you want to send an Indie push notification, simply send us your unique user ID (subID), your app ID, your app Token, a push notification title, and a push notification message.</Text>
            <Text style={styles.mb}>Here is the API:</Text>
            <Text style={styles.blackBacktext}>
                <Text>axios.<Text style={styles.yellowText}>post</Text>(`https://app.nativenotify.com/api/indie/notification`, {`{`}</Text>
                <Text>      subID: '<Text style={styles.lightBlueText}>put your unique app user ID here as a string</Text>',</Text>
                <Text>      appId: {appInfo.app_id},</Text>
                <Text>      appToken: '{appInfo.app_token}',</Text>
                <Text>      title: '<Text style={styles.lightBlueText}>put your push notification title here as a string</Text>',</Text>
                <Text>      message: '<Text style={styles.lightBlueText}>put your push notification message here as a string</Text>'</Text>
                <Text>{" });"}</Text>
            </Text>
            <Text style={styles.plainText}>
            Do NOT change the 'appId' or the 'appToken' value in this code. We put your appId and appToken in the code for you. 
            </Text>
            <Text style={styles.mb}></Text>
            <Text style={styles.mb}>You can also send an optional pushData object with your post. Here's an example:</Text>
            <Text style={styles.blackBacktext}>
                <Text>axios.<Text style={styles.yellowText}>post</Text>(`https://app.nativenotify.com/api/indie/notification`, {`{`}</Text>
                <Text>      ...,</Text>
                <Text>      pushData: '{'{ "yourProperty": "yourPropertyValue" }'}'</Text>
                <Text>{" });"}</Text>
            </Text>
            <Text style={styles.mb}></Text>
            <Text style={styles.mb}></Text>
            <Text style={styles.plainText}>Indie push notifications will be sent immediately to the individual app user associated with your subID.</Text>

            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>
            <Text style={styles.HeadingText}>How to get ONE successfully registered Indie Push Sub ID:</Text>
            <Text style={styles.mb}>To get a single successfully registered Indie Push Sub ID, use the GET API below:</Text>
            <Text style={styles.blackBacktext}>
                <Text>axios.<Text style={styles.yellowText}>get</Text>(`https://app.nativenotify.com/api/expo/indie/sub/{appInfo.app_id}/{appInfo.app_token}/your-indie-sub-id-here`)</Text>
            </Text>
            <Text style={styles.plainText}>
                Do NOT change the 'appId' or the 'appToken' value in this code. We put your appId and appToken in the code for you. 
            </Text>

            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>
            <Text style={styles.HeadingText}>How to get ALL successfully registered Indie Push Sub IDs:</Text>
            <Text style={styles.mb}>To see a list of all successfully registered Indie Push Sub IDs, use the GET API below:</Text>
            <Text style={styles.blackBacktext}>
                <Text>axios.<Text style={styles.yellowText}>get</Text>(`https://app.nativenotify.com/api/expo/indie/subs/{appInfo.app_id}/{appInfo.app_token}`)</Text>
            </Text>
            <Text style={styles.plainText}>
                Do NOT change the 'appId' or the 'appToken' value in this code. We put your appId and appToken in the code for you. 
            </Text>
            
            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>
            <Text style={styles.HeadingText}>How to DELETE an Indie Push Sub ID:</Text>
            <Text style={styles.mb}>To unregister / delete an Indie Push Sub ID, for example when a user logs out, use the DELETE API below:</Text>
            <Text style={styles.blackBacktext}>
                <Text>axios.<Text style={styles.yellowText}>delete</Text>(`https://app.nativenotify.com/api/app/indie/sub/{appInfo.app_id}/{appInfo.app_token}/your-indie-sub-id-here`)</Text>
            </Text>
            <Text style={styles.plainText}>
                Do NOT change the 'appId' or the 'appToken' value in this code. We put your appId and appToken in the code for you. 
            </Text>
        </View>
    )
}

const styles = StyleSheet.create({
    body: {
        width: '85%',
        paddingTop: 30,
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingBottom: 200
    },

    buttonText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 16,
        color: '#fff',
    },
    button: {
        width: 220,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
    },
    HeadingText: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        // marginTop: 70,
        marginBottom: 10
    },
    h2: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 20,
        marginBottom: 10
    },
    boldText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginTop: 20,
        marginBottom: 5
    },
    bT: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 16,
        marginBottom: 5
    },
    blackBacktext: {
        backgroundColor: "#1b1f23",
        color: "#fff",
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        padding: 20,
        borderRadius: 6,
        marginBottom: 5,
        display: "flex",
        flexDirection: "column"
    },
    blueText: {
        color: "rgb(54, 117, 212)"
    },
    medBlueText: {
        color: "rgb(81, 130, 204)"
    },
    dottedLine: {
        height: 1,
        width: "100%",
        borderTopWidth: 1,
        borderColor: "#000",
        borderStyle: "dotted",
        marginTop: 30,
        marginBottom: 10,
    },
    lightBlueText: {
        color: "rgb(144, 180, 218)"
    },
    plainText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 5
    },
    purpleText: {
        color: "rgb(187, 86, 187)"
    },
    mb: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 10
    },
    tab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50
    },
    yellowText: {
        color: "rgb(222, 222, 160)"
    }
});