import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Platform, TextInput } from 'react-native';
import axios from '../../../../axios';

import Icon from 'react-native-vector-icons/MaterialIcons';

import DocsMenu from './Components/DocsMenu';

export default function AndroidIconUpdateDocs({ host, appInfo, setAppInfo, showInstallDocs, showFirebaseDocs, showIndieInstallDocs, goToIOSDocs, showIOSDocs, goToSend, goToAll, goToFirebase, goToOne, showAndroidIconUpdateDocs, goToAndroidIconUpdateDocs }) {
    return (
        <View style={styles.body}>
            <DocsMenu
                showInstallDocs={showInstallDocs}
                showFirebaseDocs={showFirebaseDocs}
                showIndieInstallDocs={showIndieInstallDocs}
                showAndroidIconUpdateDocs={showAndroidIconUpdateDocs}
                goToAll={goToAll}
                goToFirebase={goToFirebase}
                goToOne={goToOne}
                goToAndroidIconUpdateDocs={goToAndroidIconUpdateDocs}
                goToIOSDocs={goToIOSDocs}
                showIOSDocs={showIOSDocs} />


            <Text style={styles.HeadingText}>Follow these steps to update the Android push notification icon:</Text>
            <Text style={styles.plainText}>(Your iOS push notification icon should be your default app icon.)</Text>

            {Platform.OS === "web"
                ? <View style={styles.youTubeVideo}>
                    <Text style={styles.h2}>This video walks you through the setup guide below:</Text>
                    <iframe width="400" height="220" style={{ maxWidth: '100%' }} src="https://www.youtube.com/embed/Kj49Z07Zq4w?rel=0" title="Native Notify Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen="allowfullscreen"></iframe>
                    
                    </View>
                : null 
            }   

            <View style={styles.dottedLine}></View>

            <View style={styles.flexRow}>
                <View style={styles.leftCont}>
                    <Text style={styles.boldText}>Step 1:</Text>
                    <Text style={styles.blackBacktext}>
                        <Text>flutter pub upgrade native_notify</Text>
                    </Text>

                    <Text style={styles.boldText}>Step 2:</Text>
                    <Text style={styles.mb}>In your 'android -> app -> src -> main -> res -> drawable' folder, put your notification icon png in that folder with this name 'notification_icon.png'</Text>
                    <Text style={styles.plainText}>(Your push notification icon must be a 96x96 png with a transparent background and an all white image.)</Text>

                    <Text style={styles.boldText}>Step 3:</Text>
                    <Text style={styles.plainText}>In your "lib > main.dart" file, in your NativeNotify.initialize function, in the 4th parameter, put the path to your notification icon like this: 'resource://drawable/notification_icon'.</Text>
                    <Text style={styles.plainText}>Here is an example:</Text>
                    <Text style={styles.blackBacktext}>
                        <Text><Text style={styles.greenText}>WidgetsFlutterBinding</Text>.<Text style={styles.yellowText}>ensureInitialized</Text>();</Text>
                        <Text><Text style={styles.greenText}>NativeNotify</Text>.<Text style={styles.yellowText}>initialize</Text>({appInfo.flutter_app_id}, '{appInfo.flutter_app_token}', 'your-firebase-key', 'resource://drawable/notification_icon');</Text>
                    </Text>
                </View>

                <View style={styles.rightCont}>
                    {screen.width < 900 ? <View style={styles.dottedLine}></View> : null}

                    <View style={styles.stickyRight}>
                        <Text style={styles.boldTextTop}>Here is an example of a "lib > main.dart" file with Native Notify code included:</Text>
                        <Text style={styles.blackBacktext}>
                            <Text><Text style={styles.medBlueText}>import</Text> 'package:flutter/material.dart';</Text>
                            <Text style={styles.mb}><Text style={styles.medBlueText}>import</Text> 'package:native_notify/native_notify.dart';</Text>

                            <Text><Text style={styles.medBlueText}>void</Text> <Text style={styles.yellowText}>main</Text>() {'{'}</Text>
                                <Text>    <Text style={styles.greenText}>WidgetsFlutterBinding</Text>.<Text style={styles.yellowText}>ensureInitialized</Text>();</Text>
                                <Text>    <Text style={styles.greenText}>NativeNotify</Text>.<Text style={styles.yellowText}>initialize</Text>({appInfo.flutter_app_id}, '{appInfo.flutter_app_token}', 'your-firebase-key', 'resource://drawable/notification_icon');</Text>
                                <Text>    <Text style={styles.yellowText}>runApp</Text>(<Text style={styles.greenText}>myApp</Text>());</Text>
                            <Text style={styles.mb}>{"}"}</Text>

                            <Text>...</Text>
                        </Text>
                    </View>
                </View>
            </View>    
        </View>
    )
}

const styles = StyleSheet.create({
    body: {
        width: 'calc(100% - 60px)',
        paddingTop: 30,
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingBottom: 200,
    },
    flexRow: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    leftCont: {
        width: screen.width > 900 ? '48%' : '100%',
    },
    rightCont: {
        width: screen.width > 900 ? '48%' : '100%',
        backgroundColor: screen.width > 900 ? '#3675d420' : '#fff',
        padding: screen.width > 900 ? 15 : 0,
        borderRadius: screen.width > 900 ? 6 : 0
    },
    stickyRight: {
        position: screen.width > 900 ? 'sticky' : 'relative',
        top: screen.width > 900 ? 10 : null
    },

    buttonText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 16,
        color: '#fff',
    },
    button: {
        width: 220,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
    },
    HeadingTextTop: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 20,
        justifyContent: 'center',
        marginBottom: -5
    },
    HeadingTextBottom: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        marginBottom: 30,
        justifyContent: 'center'
    },
    HeadingText: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        marginBottom: 10,
        justifyContent: 'center'
    },
    HeadingText2: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        marginTop: 70,
        marginBottom: 10
    },
    h2: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 20,
        marginTop: 15,
        marginBottom: 10
    },
    boldText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginTop: 20,
        marginBottom: 5
    },
    boldTextTop: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        // marginTop: 20,
        marginBottom: 5
    },
    blackBacktext: {
        backgroundColor: "#1b1f23",
        color: "#fff",
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        padding: 20,
        borderRadius: 6,
        marginBottom: 5,
        display: "flex",
        flexDirection: "column"
    },
    blueText: {
        color: "rgb(54, 117, 212)"
    },
    medBlueText: {
        color: "rgb(81, 130, 204)"
    },
    dottedLine: {
        height: 1,
        width: "100%",
        borderTopWidth: 1,
        borderColor: "#000",
        borderStyle: "dotted",
        marginTop: 30,
        marginBottom: 30,
    },
    lightBlueText: {
        color: "rgb(144, 180, 218)"
    },
    plainText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 5
    },
    orangeText: {
        color: "#c78c74"
    },
    mb: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 20
    },
    mbSmall: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 5
    },
    tab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50
    },
    greentab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50,
        color: 'rgb(81, 167, 81)'
    },
    yellowText: {
        color: "rgb(222, 222, 160)"
    },
    greenText: {
        color: '#76d7aa'
    },
    sendCont: {
        padding: 20,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 15
    },
    sendButton: {
        width: 220,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
        marginTop: 5,
        // marginBottom: 70,
    },
    inputCont: {
        marginBottom: 20,
        width: '100%',
    },
    inputLarge: {
        width: '100%',
        padding: 12.5,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 15
    }
});