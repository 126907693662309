import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, ScrollView, TextInput } from 'react-native';
import axios from '../../../axios';

export default function Subs({ host, appInfo, InAppNavigation }) {
    const { setScreenName } = InAppNavigation;

    const [indieSubs, setIndieSubs] = useState([]);

    const [searchQuery, setSearchQuery] = useState('');
    const [filteredIndieSubs, setFilteredIndieSubs] = useState([]);

    const scrollRef = useRef();

    useEffect(() => {
        axios
          .get(`${host}/api/expo/indie/subs/${appInfo.app_id}/${appInfo.app_token}`)
          .then(res => {
            console.log("res.data: ", res.data)
            setIndieSubs(res.data);
            setFilteredIndieSubs(res.data); // Initialize filteredIndieSubs with all Indie Subs
          })
          .catch(err => console.log(err))
    }, [appInfo]);

    const handleSearch = (query) => {
        setSearchQuery(query);
        
        const filteredSubs = indieSubs.filter(sub => 
            sub.sub_id.toLowerCase().includes(query.toLowerCase())
        );
        
        setFilteredIndieSubs(filteredSubs);
    };

    const toggleShowMore = (indieSub) => {
        setFilteredIndieSubs(prevIndieSubs => {
          return prevIndieSubs.map(sub => {
            if (sub === indieSub) {
              return { ...sub, showMore: !sub.showMore };
            }
            return sub;
          });
        });
      };

    return (
        <ScrollView style={styles.body} ref={scrollRef}>
            <Text style={styles.HeadingText}>Go to this page to set up Indie Push Notifications: <TouchableOpacity style={styles.blueText} onPress={() => setScreenName('indieInstallDocs')}><Text>Indie Push Notifications Setup Guide</Text></TouchableOpacity></Text>
            <View style={styles.inputCont}>
                <TextInput
                    style={styles.inputLarge}
                    placeholder="Search by Indie Sub ID"
                    value={searchQuery}
                    onChangeText={handleSearch}
                />
            </View>
            <View style={styles.overallCont}>
                <Text style={styles.HeadingText}>List of registered Indie Subscribers:</Text>
                {filteredIndieSubs.map(e => {
                    return (
                        <View style={{ 
                        width: '100%', 
                        borderBottomWidth: 1, 
                        borderBottomColor: "black",
                        padding: 15 }}
                        >
                        <Text style={styles.recTextBold}>Indie Sub Information:</Text>
                        <Text style={styles.recText}><strong>sub_id:</strong> {e.sub_id}</Text>

                        {/* Set up "See More" button to toggle the visibility */}
                        {!e.showMore &&
                            <TouchableOpacity onPress={() => toggleShowMore(e)}>
                                <Text style={styles.seeMoreButton}>See More</Text>
                            </TouchableOpacity>
                        }

                        {/* Display additional information if "showMore" is true */}
                        {e.showMore &&
                            <>
                            <Text style={styles.recText}>
                                <strong>expo_ios_tokens: </strong> 
                                {e.expo_ios_token && ( e.expo_ios_token.join(', ') )}
                            </Text>
                            <Text style={styles.recText}>
                                <strong>ios_apn_tokens: </strong> 
                                {e.ios_apn_token && ( e.ios_apn_token.join(', ') )}
                            </Text>
                            <Text style={styles.recText}>
                                <strong>expo_android_tokens: </strong> 
                                {e.expo_android_token && ( e.expo_android_token.join(', ') )}
                            </Text>
                            <Text style={styles.recText}>
                                <strong>android_fcm_tokens: </strong> 
                                {e.android_fcm_token && ( e.android_fcm_token.join(', ') )}
                            </Text>
                            {/* Set up "See Less" button to toggle the visibility back */}
                            <TouchableOpacity onPress={() => toggleShowMore(e)}>
                                <Text style={styles.seeMoreButton}>See Less</Text>
                            </TouchableOpacity>
                            </>
                        }
                        </View>
                    )
                })}
            </View>
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    body: {
        width: '85%',
        paddingTop: 30,
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingBottom: 100
    },
    HeadingText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginBottom: 10
    },
    HeadingTextThereArent: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginLeft: 15,
        marginTop: 15
    },
    buttonText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 16,
        color: '#fff',
    },
    guideButton: {
        width: 150,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
        marginBottom: 70,
    },
    sendButton: {
        width: 240,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
        marginBottom: 70,
    },
    inputCont: {
        marginTop: 20,
        width: '100%',
    },
    inputLarge: {
        width: '100%',
        padding: 12.5,
        paddingLeft: 20,
        paddingRight: 20,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 20,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 15
    },

    overallCont: {
        flexDirection: 'row',
        width: '100%',
        backgroundColor: '#fff',
        // marginTop: 20,
        marginBottom: 30,
        padding: 15,
        borderRadius: 6,
        flexWrap: 'wrap',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
    },
    categoriesCont: {
        flexDirection: 'row',
        marginBottom: 20
    },
    category: {
        borderWidth: 1,
        borderColor: '#000',
        padding: 10,
        paddingLeft: 15,
        paddingRight: 15,
        borderRadius: 16,
        marginRight: 10,
        fontSize: 12
    },
    mainNumCont: {
        margin: 20,
        marginBottom: 0
    },

    recCont: {
        width: '100%',
        backgroundColor: '#fff',
        marginTop: 10,
        marginBottom: 10,
        padding: 20,
        paddingBottom: 15,
        borderRadius: 6,
        flexWrap: 'wrap',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
    },
    screenHeaderText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 14,
        marginTop: 20,
        marginBottom: 5,
    },
    mt: {
        marginTop: 10
    },
    mt30: {
        marginTop: 30
    },
    recText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 14,
        marginBottom: 5,
    },
    recTextBold: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 5,
    },

    text: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginTop: 10,
        marginBottom: 30,
    },
    blueText: {
        color: "rgb(54, 117, 212)"
    },
    seeMoreButton: {
        color: '#3675d4',
        fontWeight: 'bold',
        textDecorationLine: 'underline',
      }
});