import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, ScrollView } from 'react-native';

export default function DocsMenu({ InAppNavigation }) {
    const { screenName, setScreenName } = InAppNavigation;

    return (
        <ScrollView contentContainerStyle={styles.container} horizontal={true} showsHorizontalScrollIndicator={true} persistentScrollbar={true}>
            <TouchableOpacity onPress={() => setScreenName('installDocs')}>
                <Text style={screenName === 'installDocs' ? styles.chosenText : styles.text}>Start Here</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setScreenName('IOSDocs')}>
                <Text style={screenName === 'IOSDocs' ? styles.chosenText : styles.text}>iOS</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setScreenName('androidDocs')}>
                <Text style={screenName === 'androidDocs' ? styles.chosenText : styles.text}>Android</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setScreenName('inboxDocs')}>
                <Text style={screenName === 'inboxDocs' ? styles.chosenText : styles.text}>Notification Inbox</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setScreenName('indieInstallDocs')}>
                <Text style={screenName === 'indieInstallDocs' ? styles.chosenText : styles.text}>Indie Push</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setScreenName('indieInboxDocs')}>
                <Text style={screenName === 'indieInboxDocs' ? styles.chosenText : styles.text}>Indie Notification Inbox</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setScreenName('GroupPushDocs')}>
                <Text style={screenName === 'GroupPushDocs' ? styles.chosenText : styles.text}>Group Push</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setScreenName('followDocs')}>
                <Text style={screenName === 'followDocs' ? styles.chosenText : styles.text}>Follow Push</Text>
            </TouchableOpacity>
            {/* <TouchableOpacity onPress={() => setScreenName('analyticsDocs')}>
                <Text style={screenName === 'analyticsDocs' ? styles.chosenText : styles.text}>Analytics</Text>
            </TouchableOpacity> */}
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
    },
    chosenText: {
        color: 'rgb(54, 117, 212)',
        fontFamily: 'Arial',
        fontWeight: 600,
        borderBottomWidth: 2,
        borderBottomColor: 'rgb(54, 117, 212)',
        marginBottom: 30,
        marginRight: 30,
        fontSize: 14,
        paddingBottom: 10
    },
    text: {
        color: '#000',
        fontFamily: 'Arial',
        fontWeight: 600,
        marginBottom: 30,
        marginRight: 30,
        fontSize: 14,
        paddingBottom: 10
    }
})