import React, { useState, useEffect, useRef } from 'react';
import { ActivityIndicator, View, StyleSheet, Image, Text, TouchableOpacity, TextInput} from 'react-native';
import { useScrollToTop } from '@react-navigation/native';
// import MetaTags from 'react-meta-tags';
import axios from '../../axios';

import Header from '../../Headers/HomeHeader';

export default function Home({ navigation, host, setUserInfo, setValidationCode }) {
    const [showLoading, setShowLoading] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const ref = useRef(null);
    useScrollToTop(ref);

    function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

    useEffect(() => {
        if (getCookie('AUTH')) {
            navigation.navigate('Dashboard');
        }
    }, []);

    const handlePost = () => {
        setUserInfo({ name, email, password });

        if(name && email && password) {
            axios
                .get(`${host}/api/auth/sendCode/${email}`)
                .then(res => {
                    if(res.data === "Email in use.") {
                        alert("Sorry, that email is already associated with a Native Notify account. Please try again with another email or login.")
                    } else {
                        setValidationCode(res.data);
                        navigation.navigate('SignUpTwo');
                    }
                })
                .catch(err => {
                    console.log(err);
                    alert('Sorry. Our servers seem to be down. Please try again later.')
                })
        } else {
            alert('Please provide a Name, Email, and Password before clicking the "Sign Up Free" button.')
        }
    }

    const onToken = (token) => {
        setShowLoading(true);

        axios
            .post(`${host}/api/auth/register`, { token })
            .then(res => {
                // console.log(res.data, res.data.user_id, res.data.sessionUser);
                if(res.data === "Email in use.") {
                    setShowLoading(false);
                    return alert("Sorry, that email is already associated with a Native Notify account. Please try again with another email.")
                }

                setShowLoading(false);
                navigation.navigate('Dashboard');
            })
            .catch(err => {
                console.log(err);
                alert('There seems to be something wrong with our server. Please send us an email at tj@nativenotify.com to let us know about the issue. We will fix the issue right away.');
                setShowLoading(false);
            })  
    }

    if(showLoading) {
        return <ActivityIndicator size="large" color="rgb(54, 117, 212)" />
    } else {
        return (
            <View style={styles.page}>
                {/* <MetaTags>
                    <title>Flutter and React Native Expo Push Notifications Made Simple - Native Notify</title>
                    <meta name="description" content="Flutter and React Native Expo push notifications made simple. 5 minute setup. Start your free trial now." />
                    <meta property="og:title" content="Flutter and React Native Expo push notifications made simple - Native Notify" />
                    <meta property="og:image" content={require('../../assets/images/push-notifications.jpg')} />
                </MetaTags> */}
                <Header navigation={navigation} style={styles.header} />
                <View 
                    ref={ref} 
                    style={styles.topRow}
                >
                    <View style={styles.topTextArea}>
                        <Text style={styles.h1}>Flutter and Expo Push Notifications Made Simple</Text>
                        <Text style={styles.bigParText}>5 minute setup time. Built for Flutter and React Native Expo developers. Increase customer engagement with push notifications.</Text>
                        {/* <StripeButton onToken={onToken} /> 
                       
                        <Text style={styles.bottomBlackText}>Try Native Notify free for 7 days. Cancel within 7 days and you won't be charged. After 7 days, it's $29.99/month. Cancel anytime.</Text> */}
                        {/* <Text style={styles.bottomBlackText}></Text> */}
                    </View>
                    
                    <View style={styles.body}>
                        <Text style={styles.headerText}>Sign Up Free</Text>
                        <Text style={styles.subtitleText}>No credit card required.</Text>
                        <TextInput 
                            style={styles.inputLarge}
                            onChangeText={(text) => setName(text)}
                            placeholder={"Name"}
                            placeholderTextColor={"#6e6d6c"}
                            value={name}
                        />
                        <TextInput 
                            style={styles.inputLarge}
                            onChangeText={(text) => setEmail(text.toLowerCase())}
                            placeholder={"Email"}
                            placeholderTextColor={"#6e6d6c"}
                            value={email}
                        />
                        <TextInput 
                            style={styles.inputLarge}
                            onChangeText={(text) => setPassword(text)}
                            placeholder={"Password"}
                            placeholderTextColor={"#6e6d6c"}
                            value={password}
                            secureTextEntry={true}
                        />
                        
                        <View style={styles.signUpCont}>
                            <TouchableOpacity onPress={() => handlePost()}><Text style={styles.button}>Sign Up Free</Text></TouchableOpacity>
                        </View>        
                    </View>   
                    <Text style={styles.brPBottom}>&copy; 2021 Native Notify, INC  |  tj@nativenotify.com</Text>
                </View>
                {/* <View style={styles.middleRow}>
                    <View style={styles.reviewTopCont}>
                        <View style={styles.line}></View>
                        <Text style={styles.mH1}>See what people are saying</Text>
                        <View style={styles.line}></View>
                    </View>

                    <View style={styles.reviewCont}>
                        <View style={styles.testCont}>
                            <Text style={styles.mP}>"Such a great tool. Thanks!"</Text>
                            <Text style={styles.mP}>- E.T.</Text>
                        </View>

                        <TouchableOpacity style={styles.testCont} onPress={() => window.open('https://bilimoko.net')}>
                            <Image
                                style={styles.reviewImage}
                                source={require('../../assets/images/bilimoko-logo.png')}
                                resizeMode='cover'
                            /> 
                            <Text style={styles.mP}>"I am so thankful that you made this service. I am currently using this now for push notifications in my app. It's just sooooo easy to integrate." - R.Y., CTO of bilimoko</Text>
                        </TouchableOpacity>

                        <View style={styles.testCont}>
                            <Text style={styles.mP}>"Thank you for this service!"</Text>
                            <Text style={styles.mP}>- D.B.</Text>
                        </View>
                    </View>
                </View> */}
                {/* <View style={styles.bottomRow}>
                        <View style={styles.topRow}>
                            <View style={styles.topTextArea}>
                                <Text style={styles.brH1}>Our Story</Text>
                                <Text style={styles.brP}>We couldn't find a push notification service provider for React Native Expo apps, so we built one. </Text>
                                <Text style={styles.brP}>Setup takes less than 15 minutes. Try it for free. No credit card required.</Text>
                            </View>

                            <View style={styles.body}>
                                <Text style={styles.headerText}>Sign Up Free</Text>
                                <Text style={styles.subtitleText}>No credit card required.</Text>
                                <TextInput 
                                    style={styles.inputLarge}
                                    onChangeText={(text) => setName(text)}
                                    placeholder={"Name"}
                                    placeholderTextColor={"#6e6d6c"}
                                    value={name}
                                />
                                <TextInput 
                                    style={styles.inputLarge}
                                    onChangeText={(text) => setEmail(text.toLowerCase())}
                                    placeholder={"Email"}
                                    placeholderTextColor={"#6e6d6c"}
                                    value={email}
                                />
                                <TextInput 
                                    style={styles.inputLarge}
                                    onChangeText={(text) => setPassword(text)}
                                    placeholder={"Password"}
                                    placeholderTextColor={"#6e6d6c"}
                                    value={password}
                                    secureTextEntry={true}
                                />
                                
                                <View style={styles.signUpCont}>
                                    <TouchableOpacity onPress={() => handlePost()}><Text style={styles.button}>Sign Up Free</Text></TouchableOpacity>
                                </View>        
                            </View>
                        </View>
                        
                        <Text style={styles.brPBottom}>&copy; 2021 Native Notify, INC  |  tj@nativenotify.com</Text>
                </View> */}
            </View>
        )
    }

    
}

let bodyWidth, leftWidth, rightWidth, halfWidth, testWidth, mt1, mt2, mt3, mt4, mp, pt4, mr, mb1, sbC, rowCol;

if(screen.width > 900) {
    bodyWidth = '95%';
    leftWidth = '48%';
    rightWidth = '48%';
    halfWidth = '50%';
    testWidth = '30%';
    mt1 = 160;
    mt2 = 60;
    mt3 = 70;
    mt4 = 150;
    mb1 = 40;
    mp = 60;
    pt4 = 0;
    mr = 30;
    sbC = 'space-between';
    rowCol = 'row';
} else {
    bodyWidth = '100%';
    leftWidth = '100%';
    rightWidth = '100%';
    halfWidth = '100%';
    testWidth = '100%';
    mt1 = 0;
    mt2 = 20;
    mt3 = 40;
    mt4 = 40;
    mb1 = 0;
    mp = 40;
    pt4 = 60;
    mr = 0;
    sbC = 'center';
    rowCol = 'column';
}

const styles = StyleSheet.create({
    page: {
        flex: 1, 
        height: '100%',
        minHeight: '100vh',
        width: '100%',
        backgroundColor: "#fff",
        backgroundColor: "#3576d4",
        alignItems: 'center',
        justifyContent: 'center',
        overflowX: 'hidden'
    },
    body: {
        width: bodyWidth,
        maxWidth: 500,
        maxHeight: 700,
        padding: "3%",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        marginTop: mt2,
    },
    headerText: {
        width: "90%",
        textAlign: "left",
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 30,
    },
    subtitleText: {
        width: "100%",
        fontFamily: "Arial",
        fontWeight: 400,
        color: "#6e6d6c",
        marginBottom: 20,
    },
    inputLarge: {
        width: "100%",
        padding: 12.5,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 15
    },
    signUpCont: {
        width: "100%",
        alignItems: "center",
        marginTop: 25
    },
    button: {
        width: 220,
        padding: 7.5,
        backgroundColor: "#06bd43",
        borderRadius: 6,
        marginBottom: 20,
        fontFamily: "Arial",
        fontWeight: 700,
        color: "white",
        fontSize: 20,
        textAlign: "center"
    },

    header: {
        flex: 1,
    },
    topRow: {
        flex: 8, 
        height: '100%',
        width: '100%',
        maxWidth: 1400,
        padding: '5vw',
        paddingTop: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        // alignItems: 'center',
        flexWrap: 'wrap',
    },
    footer: {
        flex: 1,
    },
    topTextArea: {
        width: leftWidth,
        // minWidth: 300,
        flexWrap: 'wrap',
        marginTop: mt1
    },
    codeScreenshot: {
        height: 450,
        width: rightWidth,
        // minWidth: 300,
        maxWidth: '100%',
        borderRadius: 14,
        boxShadow: '1px 4px 10px #202020',
        marginTop: mt2,
    },
    youTubeVideo: {
        height: 'auto',
        minHeight: '40vh',
        width: rightWidth,
        maxWidth: '100%',
        marginTop: mt2,
    },
    
    middleRow:  {
        width: '100%',
        marginTop: mt4,
        // backgroundColor: '#fff',
        backgroundColor: "#3576d4",
        alignItems: 'center',
        paddingTop: pt4,
        paddingBottom: mp,
    },
    reviewTopCont: {
        width: '95%',
        flexDirection: rowCol,
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 30
    },
    line: {
        height: 2,
        borderWidth: 1,
        borderColor: '#fff',
        width: 100,
        marginBottom: 29,
        marginLeft: 20,
        marginRight: 20
    },
    reviewCont: {
        width: '95%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: sbC,
        alignItems: 'center',
        marginBottom: mt4,
        marginLeft: 15,
        marginRight: 15
    },
    testCont: {
        width: testWidth,
        maxWidth: '95%',
        // maxHeight: 700,
        padding: "3%",
        // flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        // marginTop: mt2,
        marginBottom: mt2,
    },
    mH1: {
        fontSize: 16,
        fontFamily: 'Arial',
        fontWeight: 700,
        color: '#fff',
        marginBottom: 30,
    },
    mP: {
        maxWidth: 400,
        fontSize: 20,
        fontFamily: 'Arial',
        fontWeight: 400,
        color: '#000',
        fontStyle: 'italic',
        textAlign: 'center',
        // marginBottom: 30,
    },
    reviewImage: {
        height: 140,
        width: 222,
        marginRight: mr,
        marginBottom: 30,
    },

    bottomRow:  {
        width: '100%',
        backgroundColor: '#000',
        alignItems: 'center',
        paddingTop: pt4
    },
    brH1: {
        fontSize: 30,
        fontFamily: 'Arial',
        fontWeight: 700,
        color: '#fff',
        width: '100%',
    },
    brP: {
        fontSize: 20,
        fontFamily: 'Arial',
        fontWeight: 400,
        marginTop: 15,
        color: '#fff',
        width: '100%',
    },
    brPBottom: {
        fontSize: 14,
        fontFamily: 'Arial',
        fontWeight: 400,
        maxWidth: 1400,
        textAlign: 'center',
        color: '#fff',
        width: '100%',
        paddingTop: 200,
    },
    maxWidth: {
        width: '100%',
        maxWidth: 1400,
        paddingTop: mt3,
        paddingRight: '5%',
        paddingBottom: '5%',
        paddingLeft: '5%',
    },

    h1: {
        fontSize: 45,
        fontFamily: 'Arial',
        fontWeight: 700,
        // marginTop: '5%',
        color: '#fff'
    },
    h2: {
        fontSize: 20,
        fontFamily: 'Arial',
        fontWeight: 700,
        color: 'rgb(229, 75, 77)',
        marginBottom: 15,
    },
    bigParText: {
        fontSize: 20,
        fontFamily: 'Arial',
        fontWeight: 400,
        marginTop: 15,
        color: '#fff'
    },
    signUpButton: {
        // backgroundColor: '#e54b4d',
        // padding: 10,
        width: 240,
        borderRadius: 4,
        marginTop: 40,
        marginBottom: 30
    },
    whiteText: {
        fontSize: 18,
        color:'#fff',
        fontFamily: 'Arial',
        fontWeight: 600,
        textAlign: 'center'
    },
    blackText: {
        fontSize: 16,
        color: '#000',
        fontFamily: 'Arial',
        fontWeight: 400,
        marginTop: 0,
    },
    bottomBlackText: {
        fontSize: 14,
        color: '#000',
        fontFamily: 'Arial',
        fontWeight: 400,
        marginTop: 0,
        marginBottom: 30
    }
});
