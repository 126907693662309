import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Text, Image, TouchableOpacity, ActivityIndicator } from 'react-native';
import { useScrollToTop } from '@react-navigation/native';
import axios from '../../axios';

export default function ChooseAFramework({ navigation, host, userInfo, setUserInfo, setAppInfo, appUpdated, setAppUpdated }) {
    const [disable, setDisabled] = useState(false);
    
    const ref = useRef(null);
    useScrollToTop(ref);

    function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

    const createExpoApp = async () => {
        setDisabled(true)
        await axios
            .post(`${host}/api/post/app`, { isWebsiteToApp: false })
            .then(res => {
                // console.log("res.data: ", res.data);
                setAppInfo(res.data);
                document.cookie = `app_id = ${res.data.app_id}`;
                setAppUpdated(res.data.app_id);
                if(getCookie('app_id') == res.data.app_id) {
                    setDisabled(false)
                    navigation.navigate('InApp');
                }
            })
            .catch(err => console.log(err))
    }

    const createFlutterApp = () => {
        axios
            .post(`${host}/api/flutter/app`)
            .then(res => {
                // console.log("res.data: ", res.data);
                setAppInfo(res.data);
                document.cookie = `flutter_app_id = ${res.data.flutter_app_id}`;
                setAppUpdated(res.data.flutter_app_id);
                if(getCookie('flutter_app_id') == res.data.flutter_app_id) {
                    navigation.navigate('InAppFlutter');
                }
            })
            .catch(err => console.log(err))
    }

    const createWebsiteToAppApp = () => {
        axios
            .post(`${host}/api/post/app`, { isWebsiteToApp: true })
            .then(res => {
                // console.log("res.data: ", res.data);
                setAppInfo(res.data);
                document.cookie = `app_id = ${res.data.app_id}`;
                setAppUpdated(res.data.app_id);
                if(getCookie('app_id') == res.data.app_id) {
                    navigation.navigate('InAppWebsiteToApp');
                }
            })
            .catch(err => console.log(err))
    }

    return (
        <View style={styles.body}>
            <View style={styles.container}>
                <Text style={styles.h1}>Create an app</Text>
                <View className style={styles.flexRow}>
                    <TouchableOpacity 
                        style={styles.frameworkCont} 
                        onPress={() => createExpoApp()}
                        disabled={disable}
                    >
                        {disable
                            ? <View style={styles.iconLoading}>
                                <ActivityIndicator size="large" color="rgb(54, 117, 212)" />
                              </View>
                            : <Image
                                style={styles.icon}
                                source={require('../../assets/images/react-native-expo-icon.png')}
                                resizeMode='cover'
                              />
                        }
                        
                        <Text style={styles.frameworkTitle}>React Native / Expo</Text>
                    </TouchableOpacity>

                    {/* new way that is a link */}
                    <a style={styles.frameworkCont} href='https://websitetoappconvert.com/'>
                        <Image
                            style={styles.icon}
                            source={require('../../assets/images/website-to-app-icon.png')}
                            resizeMode='cover'
                        />
                        <Text style={styles.frameworkTitle}>Website to App Convert</Text>
                    </a>
                </View>
            </View>
        </View>  
    )
}

let flexRowJustify, flexRowGap, appMR, appWH;

if(screen.width < 500) {
    flexRowJustify = 'center';
    appMR = 0;
} else {
    flexRowJustify = 'flex-start';
    appMR = 60;
}

if(screen.width > 400) {
    appWH = 175;
    flexRowGap = 30;
} else { 
    appWH = 160; 
    flexRowGap = 15
}

const styles = StyleSheet.create({
    body: {
        width: '100%',
        paddingHorizontal: '5%',
        paddingVertical: 15,
        backgroundColor: "#47689915",
        alignItems: 'center'
    },
    container: {
        width: '100%',
        maxWidth: 1200,
    },
    flexRow: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: flexRowGap,
    },
    h1: {
        fontFamily: 'Arial',
        fontWeight: 400,
        fontSize: 20,
        marginBottom: 15,
        paddingTop: 5,
        paddingBottom: 7,
    },
    h1Bold: {
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: 20,
        marginBottom: 30,
        paddingTop: 5,
        paddingBottom: 7,
    },
    p: {
        fontFamily: 'Arial',
        fontWeight: 400,
        fontSize: 14,
        marginBottom: 30,
        paddingTop: 5,
        paddingBottom: 7,
    },
    pBold: {
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: 14,
        marginBottom: 30,
        paddingTop: 5,
        paddingBottom: 7,
    },
    frameworkCont: {
        textDecorationLine: 'none'
    },
    frameworkTitle: {
        fontFamily: 'Arial',
        fontWeight: 500,
        fontSize: 14,
        marginBottom: 10
    },
    icon: {
        height: 200,
        width: 200,
        height: appWH,
        width: appWH,
        borderWidth: 1,
        borderColor: '#d6d6d6',
        borderRadius: 6,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 15,
        backgroundColor: '#fff',
        marginBottom: 10
    },
    iconLoading: {
        height: 200,
        width: 200,
        height: appWH,
        width: appWH,
        borderWidth: 1,
        borderColor: '#d6d6d6',
        borderRadius: 6,
        backgroundColor: '#00000040',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 15,
        marginBottom: 10
    }
})