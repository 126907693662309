import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, ScrollView } from 'react-native';

export default function Analytics({ appInfo, InAppNavigation }) {
    const { setScreenName } = InAppNavigation;

    const [analytics, setAnalytics] = useState([]);

    const scrollRef = useRef();

    useEffect(() => {
        console.log(appInfo.analytics);
        if(appInfo.analytics) {
            setAnalytics(appInfo.analytics);
        }
        window.scroll({ top: 0, left: 0, behavior: 'instant' });
    }, [appInfo])

    return (
        <ScrollView style={styles.body} ref={scrollRef}>
            <Text style={styles.HeadingText}>Go to this page to set up analytics: <TouchableOpacity style={styles.blueText} onPress={() => setScreenName('analyticsDocs')}><Text>Analytics Setup Guide</Text></TouchableOpacity></Text>
            <View style={styles.overallCont}>
                {analytics.length > 0 
                    ? <View style={styles.mainNumCont}>
                        <Text style={styles.HeadingText}>Most recent day's numbers:</Text>
                        <Text style={styles.recText}><Text style={styles.recTextBold}>Date:</Text>   {analytics[0].dateName}</Text>
                        <Text style={styles.recText}><Text style={styles.recTextBold}>Total Views:</Text>   {analytics[0].appTotalViews}</Text>
                        <Text style={styles.recText}><Text style={styles.recTextBold}>Total Unique Views:</Text>   {analytics[0].appUniqueViews}</Text>
                      </View>
                    : <Text style={styles.HeadingTextThereArent}>There aren't any analytics to report yet...</Text>
                }
                {analytics.length > 1 
                    ? <View style={styles.mainNumCont}>
                        <Text style={styles.HeadingText}>Last day's Numbers:</Text>
                        <Text style={styles.recText}><Text style={styles.recTextBold}>Date:</Text>   {analytics[1].dateName}</Text>
                        <Text style={styles.recText}><Text style={styles.recTextBold}>Total Views:</Text>   {analytics[1].appTotalViews}</Text>
                        <Text style={styles.recText}><Text style={styles.recTextBold}>Total Unique Views:</Text>   {analytics[1].appUniqueViews}</Text>
                      </View>
                    : null
                }
                {analytics.length > 6 
                    ? <View style={styles.mainNumCont}>
                        <Text style={styles.HeadingText}>Last 7 days' Numbers:</Text>
                        <Text style={styles.recText}>
                            <Text style={styles.recTextBold}>Date Range:</Text>   {analytics[6].dateName} to {analytics[0].dateName}</Text>
                        <Text style={styles.recText}>
                            <Text style={styles.recTextBold}>Total Views:</Text>   {analytics.slice(0, 7).map(e => e.appTotalViews).reduce((a, b) => a + b)}</Text>
                        <Text style={styles.recText}>
                            <Text style={styles.recTextBold}>Average Daily Unique Views:</Text>   {(analytics.slice(0, 7).map(e => e.appUniqueViews).reduce((a, b) => a + b) / 7).toFixed(0)}</Text>
                      </View>
                    : null
                }
                {analytics.length > 29 
                    ? <View style={styles.mainNumCont}>
                        <Text style={styles.HeadingText}>Last 30 days' Numbers:</Text>
                        <Text style={styles.recText}>
                            <Text style={styles.recTextBold}>Date Range:</Text>   {analytics[29].dateName} to {analytics[0].dateName}</Text>
                        <Text style={styles.recText}>
                            <Text style={styles.recTextBold}>Total Views:</Text>   {analytics.slice(0, 30).map(e => e.appTotalViews).reduce((a, b) => a + b)}</Text>
                        <Text style={styles.recText}>
                            <Text style={styles.recTextBold}>Average Daily Unique Views:</Text>   {(analytics.slice(0, 30).map(e => e.appUniqueViews).reduce((a, b) => a + b) / 30).toFixed(0)}</Text>
                      </View>
                    : null
                }
                {analytics.length > 89 
                    ? <View style={styles.mainNumCont}>
                        <Text style={styles.HeadingText}>Last 90 days' Numbers:</Text>
                        <Text style={styles.recText}>
                            <Text style={styles.recTextBold}>Date Range:</Text>   {analytics[89].dateName} to {analytics[0].dateName}</Text>
                        <Text style={styles.recText}>
                            <Text style={styles.recTextBold}>Total Views:</Text>   {analytics.slice(0, 90).map(e => e.appTotalViews).reduce((a, b) => a + b)}</Text>
                        <Text style={styles.recText}>
                            <Text style={styles.recTextBold}>Average Daily Unique Views:</Text>   {(analytics.slice(0, 90).map(e => e.appUniqueViews).reduce((a, b) => a + b) / 90).toFixed(0)}</Text>
                      </View>
                    : null
                }
                {analytics.length > 364 
                    ? <View style={styles.mainNumCont}>
                        <Text style={styles.HeadingText}>Last 365 days' Numbers:</Text>
                        <Text style={styles.recText}>
                            <Text style={styles.recTextBold}>Date Range:</Text>   {analytics[364].dateName} to {analytics[0].dateName}</Text>
                        <Text style={styles.recText}>
                            <Text style={styles.recTextBold}>Total Views:</Text>   {analytics.slice(0, 365).map(e => e.appTotalViews).reduce((a, b) => a + b)}</Text>
                        <Text style={styles.recText}>
                            <Text style={styles.recTextBold}>Average Daily Unique Views:</Text>   {(analytics.slice(0, 365).map(e => e.appUniqueViews).reduce((a, b) => a + b) / 365).toFixed(0)}</Text>
                      </View>
                    : null
                }
            </View>
            <Text style={styles.HeadingText}>Detailed Daily Reports:</Text>
            {analytics.length > 0 
                ? analytics.map((e, i) => {
                    return (
                        <View style={styles.recCont} key={i}>
                            <Text style={styles.recText}><Text style={styles.recTextBold}>Date:</Text>     {e.dateName}</Text>
                            <Text style={styles.recText}><Text style={styles.recTextBold}>Total Views:</Text>     {e.appTotalViews}</Text>
                            <Text style={styles.recText}><Text style={styles.recTextBold}>Total Unique Views:</Text>     {e.appUniqueViews}</Text>
                            <Text style={styles.screenHeaderText}>Individual Screen Views:</Text>
                            {e.indScreenViews.map((el, ind) => {
                                return (
                                    <View style={styles.mt} key={ind}>
                                        <Text style={styles.recText}>{el.screenName} Total:     {el.totalViews}</Text>
                                        <Text style={styles.recText}>{el.screenName} Unique:     {el.uniqueViews}</Text>
                                    </View>
                                )
                            })}
                        </View>
                    )
                  })
                : null
            }
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    body: {
        width: '85%',
        paddingTop: 30,
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingBottom: 100
    },
    HeadingText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginBottom: 10
    },
    HeadingTextThereArent: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginLeft: 15,
        marginTop: 15
    },
    buttonText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 16,
        color: '#fff',
    },
    guideButton: {
        width: 150,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
        marginBottom: 70,
    },
    sendButton: {
        width: 240,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
        marginBottom: 70,
    },
    inputCont: {
        marginBottom: 20,
        width: '100%',
    },
    inputLarge: {
        width: '100%',
        padding: 12.5,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 15
    },

    overallCont: {
        flexDirection: 'row',
        width: '100%',
        backgroundColor: '#fff',
        marginTop: 20,
        marginBottom: 30,
        paddingBottom: 15,
        borderRadius: 6,
        flexWrap: 'wrap',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
    },
    categoriesCont: {
        flexDirection: 'row',
        marginBottom: 20
    },
    category: {
        borderWidth: 1,
        borderColor: '#000',
        padding: 10,
        paddingLeft: 15,
        paddingRight: 15,
        borderRadius: 16,
        marginRight: 10,
        fontSize: 12
    },
    mainNumCont: {
        margin: 20,
        marginBottom: 0
    },

    recCont: {
        width: '100%',
        backgroundColor: '#fff',
        marginTop: 10,
        marginBottom: 10,
        padding: 20,
        paddingBottom: 15,
        borderRadius: 6,
        flexWrap: 'wrap',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
    },
    screenHeaderText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 14,
        marginTop: 20,
        marginBottom: 5,
    },
    mt: {
        marginTop: 10
    },
    mt30: {
        marginTop: 30
    },
    recText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 14,
        marginBottom: 5,
    },
    recTextBold: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 5,
    },

    text: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginTop: 10,
        marginBottom: 30,
    },
    blueText: {
        color: "rgb(54, 117, 212)"
    }
});