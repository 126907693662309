import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, ScrollView } from 'react-native';

export default function DocsMenu({ goToAll, goToFirebase, goToOne, goToIOSDocs, goToAndroidIconUpdateDocs, showIOSDocs, showInstallDocs, showFirebaseDocs, showIndieInstallDocs, showAndroidIconUpdateDocs }) {
    return (
        <ScrollView contentContainerStyle={styles.container} horizontal={true}>
            {/* <TouchableOpacity onPress={() => goToAll()}>
                <Text style={showInstallDocs ? styles.chosenText : styles.text}>Start Here</Text>
            </TouchableOpacity> */}
            <TouchableOpacity onPress={() => goToFirebase()}>
                <Text style={showFirebaseDocs ? styles.chosenText : styles.text}>Android Production Setup</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => goToIOSDocs()}>
                <Text style={showIOSDocs ? styles.chosenText : styles.text}>iOS Production Setup</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => goToOne()}>
                <Text style={showIndieInstallDocs ? styles.chosenText : styles.text}>Indie Push Setup</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => goToAndroidIconUpdateDocs()}>
                <Text style={showAndroidIconUpdateDocs ? styles.chosenText : styles.text}>Update Android Push Icon</Text>
            </TouchableOpacity>
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        // paddingBottom: 30,
    },
    chosenText: {
        color: 'rgb(54, 117, 212)',
        fontFamily: 'Arial',
        fontWeight: 600,
        borderBottomWidth: 2,
        borderBottomColor: 'rgb(54, 117, 212)',
        marginBottom: 30,
        marginRight: 30,
        fontSize: 16,
        paddingBottom: 10
    },
    text: {
        color: '#000',
        fontFamily: 'Arial',
        fontWeight: 600,
        marginBottom: 30,
        marginRight: 30,
        fontSize: 16,
        paddingBottom: 10
    }
})