import React, { useState, useEffect, useRef } from 'react';
import { ActivityIndicator, View, StyleSheet, Text } from 'react-native';
import { useScrollToTop } from '@react-navigation/native';
import axios from '../../axios';

import Header from '../../Headers/FlutterAppHeader';
import NotificationSidebar from './Screens/Components/NotificationSidebar';

import SendNotification from './Screens/SendNotification';
import StartHereDocs from './Screens/DocumentationScreens/StartHereDocs';
import FirebaseDocs from './Screens/DocumentationScreens/FirebaseDocs';
import IOSDocs from './Screens/DocumentationScreens/IOSDocs';
import IndieInstallDocs from './Screens/DocumentationScreens/IndieInstallDocs';
import AnalyticsInstallDocs from './Screens/DocumentationScreens/AndroidIconUpdateDocs';
import Analytics from './Screens/Analytics';
import Settings from './Screens/Settings';

export default function Notification({ navigation, host, appInfo, setAppInfo, appUpdated, setAppUpdated }) {
    const [showLoading, setShowLoading] = useState(true);
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [bigPictureURL, setBigPictureURL] = useState('');
    const [dataObject, setDataObject] = useState('');

    const [showSendNot, setShowSendNot] = useState(false);
    const [showInstallDocs, setShowInstallDocs] = useState(false);
    const [showFirebaseDocs, setFirebaseDocs] = useState(true);
    const [showIndieInstallDocs, setShowIndieInstallDocs] = useState(false);
    const [showAndroidIconUpdateDocs, setShowAndroidIconUpdateDocs] = useState(false);
    const [showAnalytics, setShowAnalytics] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [showIOSDocs, setShowIOSDocs] = useState(false);

    const updateAppInfo = () => {
        axios
            .get(`${host}/api/flutter/app/${getCookie('flutter_app_id')}`)
            .then(res => {
                // console.log("res.data: ", res.data)
                setAppInfo(res.data);
            })
            .catch(err => console.log(err))
    }

    const goToSend = () => {
        setShowInstallDocs(false);
        setFirebaseDocs(false);
        setShowSendNot(true);
        setShowIndieInstallDocs(false);
        setShowAndroidIconUpdateDocs(false);
        setShowAnalytics(false);
        setShowSettings(false);
        setShowIOSDocs(false);

        updateAppInfo();
    }
    const goToAll = () => {
        // setShowInstallDocs(true);
        setFirebaseDocs(true);
        setShowSendNot(false);
        setShowIndieInstallDocs(false);
        setShowAndroidIconUpdateDocs(false);
        setShowAnalytics(false);
        setShowSettings(false);
        setShowIOSDocs(false);

        updateAppInfo();
    }
    const goToFirebase = () => {
        setShowInstallDocs(false);
        setFirebaseDocs(true);
        setShowSendNot(false);
        setShowIndieInstallDocs(false);
        setShowAndroidIconUpdateDocs(false);
        setShowAnalytics(false);
        setShowSettings(false);
        setShowIOSDocs(false);

        updateAppInfo();
    }
    const goToIOSDocs = () => {
        setShowInstallDocs(false);
        setFirebaseDocs(false);
        setShowSendNot(false);
        setShowIndieInstallDocs(false);
        setShowAndroidIconUpdateDocs(false);
        setShowAnalytics(false);
        setShowSettings(false);
        setShowIOSDocs(true);

        updateAppInfo();
    }
    const goToOne = () => {
        setShowInstallDocs(false);
        setFirebaseDocs(false);
        setShowSendNot(false);
        setShowIndieInstallDocs(true);
        setShowAndroidIconUpdateDocs(false);
        setShowAnalytics(false);
        setShowSettings(false);
        setShowIOSDocs(false);

        updateAppInfo();
    }
    const goToAndroidIconUpdateDocs = () => {
        setShowInstallDocs(false);
        setFirebaseDocs(false);
        setShowSendNot(false);
        setShowIndieInstallDocs(false);
        setShowAndroidIconUpdateDocs(true);
        setShowAnalytics(false);
        setShowSettings(false);
        setShowIOSDocs(false);

        updateAppInfo();
    }
    const goToAnalytics = () => {
        setShowInstallDocs(false);
        setFirebaseDocs(false);
        setShowSendNot(false);
        setShowIndieInstallDocs(false);
        setShowAndroidIconUpdateDocs(false);
        setShowAnalytics(true);
        setShowSettings(false);
        setShowIOSDocs(false);

        updateAppInfo();
    }
    const goToSettings = () => {
        setShowInstallDocs(false);
        setFirebaseDocs(false);
        setShowSendNot(false);
        setShowIndieInstallDocs(false);
        setShowAndroidIconUpdateDocs(false);
        setShowAnalytics(false);
        setShowSettings(true);
        setShowIOSDocs(false);

        updateAppInfo();
    }


    const ref = useRef(null);
    useScrollToTop(ref);

    function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

    useEffect(() => {
      // console.log(appInfo.app_sub_count);

      if (!appInfo.app_sub_count || appInfo.app_sub_count < 1) {
        setShowSendNot(false);
        setFirebaseDocs(true);
      } else {
        setShowSendNot(true);
        setFirebaseDocs(false);
      }

      // this is triggered so the push_sub_count will be updated
      axios.get(`${host}/api/all/apps`);

      // this is triggered to get the current check premium status and to set app info
      axios.get(`${host}/api/user`).then((response) => {
        const data = response.data;
        // console.log(data);

        if (!data.paid_sub) {
          let today = new Date();
          let priorDate = new Date().setDate(today.getDate() - 7);

          if (
            +new Date(priorDate) > +new Date(data.date_signed_up) &&
            !data.custom_pricing
          ) {
            navigation.navigate("Dashboard");
          } else {
            axios
              .put(`${host}/api/stripe/subscription/quantity`, {})
              .then((responseToPost) => {
                // console.log(responseToPost);

                axios
                  .get(`${host}/api/flutter/app/${getCookie("flutter_app_id")}`)
                  .then((res) => {
                    // console.log("res.data: ", res.data)
                    setAppInfo(res.data);

                    if (!res.data.app_sub_count || res.data.app_sub_count < 1) {
                      setShowSendNot(false);
                      setFirebaseDocs(true);
                    } else {
                      setShowSendNot(true);
                      setFirebaseDocs(false);
                    }

                    setShowLoading(false);
                  })
                  .catch((err) => console.log(err));
              })
              .catch((errorOfPost) => console.log(errorOfPost));
          }
        } else if (data.paid_sub && !data.custom_pricing) {
          axios
            .put(`${host}/api/stripe/subscription/quantity`, {})
            .then((responseToPost) => {
              // console.log(responseToPost);

              axios
                .get(`${host}/api/flutter/app/${getCookie("flutter_app_id")}`)
                .then((res) => {
                  // console.log("res.data: ", res.data)
                  setAppInfo(res.data);

                  if (!res.data.app_sub_count || res.data.app_sub_count < 1) {
                    setShowSendNot(false);
                    setFirebaseDocs(true);
                  } else {
                    setShowSendNot(true);
                    setFirebaseDocs(false);
                  }

                  setShowLoading(false);
                })
                .catch((err) => console.log(err));
            })
            .catch((errorOfPost) => console.log(errorOfPost));
        } else if (data.paid_sub && data.custom_pricing) {
          axios
            .get(`${host}/api/flutter/app/${getCookie("flutter_app_id")}`)
            .then((res) => {
              // console.log("res.data: ", res.data)
              setAppInfo(res.data);

              if (!res.data.app_sub_count || res.data.app_sub_count < 1) {
                setShowSendNot(false);
                setFirebaseDocs(true);
              } else {
                setShowSendNot(true);
                setFirebaseDocs(false);
              }

              setShowLoading(false);
            })
            .catch((err) => console.log(err));
        }
      });
    }, []);

    const sendNotification = () => {
        if (!appInfo.app_sub_count || appInfo.app_sub_count < 1) {
            return alert("Sorry, your app does not have any push notification subscribers yet. Try opening your app, then refresh this page and try again.")
        }

        if (title && body) {
            let sendConfirm = confirm("Are you ready to send your push notification? This will be sent to all of your notification subscribers immediately.");

            if (sendConfirm) {
                const date = new Date();
                let hour = date.getHours();
                let amPM = "";

                if (hour === 12) {
                    amPM = "PM"
                } else if (hour > 12) {
                    amPM = "PM"
                    hour = hour - 12;
                } else {
                    amPM = "AM"
                }

                axios
                    .post(`${host}/api/flutter/notification`, { flutterAppId: getCookie('flutter_app_id'), flutterAppToken: appInfo.flutter_app_token, title, body, bigPictureURL, data: dataObject })
                    .then(res => {
                        // console.log(res.data);
                        alert(`Your push notification was successfully sent! \n\n(Sometimes it takes up to 30 minutes for a push notification to appear on a device.)`);
                        setTitle('');
                        setBody('');
                        setBigPictureURL('');
                        setDataObject('');

                        axios
                            .get(`${host}/api/flutter/app/${getCookie('flutter_app_id')}`)
                            .then(res => {
                                setAppInfo(res.data);
                            })
                            .catch(err => console.log(err))
                    })
                    .catch(err => {
                        console.log(err);
                        alert("There was an error. We're going to refresh the page for you. Please, try to send a push notification again after the page reload.");
                        location.reload();
                    })
            }
        } else {
            alert('Please, type a notification title and a notification message before sending a push notification.')
        }
    }

    if (showLoading) {
        return <ActivityIndicator size="large" color="rgb(54, 117, 212)" style={styles.page} />
    }

    else {
        return (
            <View style={styles.page}>
                <Header
                    navigation={navigation}
                    host={host}
                    appInfo={appInfo}
                    setAppInfo={setAppInfo}
                    setAppUpdated={setAppUpdated}
                    goToAll={goToAll}
                />
                <View style={styles.body}>
                    {/* <Text style={{ margin: '5%', fontSize: 20, fontFamily: 'Arial' }}>Native Notify Flutter Push Notifications is currently in development. It should be live soon. Check back in a few days...</Text> */}
                    <NotificationSidebar
                        goToSend={goToSend}
                        goToAll={goToAll}
                        goToOne={goToOne}
                        goToAnalytics={goToAnalytics}
                        goToSettings={goToSettings}
                    />
                    {showSendNot
                        ? <SendNotification
                            appInfo={appInfo}
                            title={title}
                            setTitle={setTitle}
                            body={body}
                            setBody={setBody}
                            bigPictureURL={bigPictureURL}
                            setBigPictureURL={setBigPictureURL}
                            dataObject={dataObject}
                            setDataObject={setDataObject}
                            sendNotification={sendNotification}
                        />
                        : null
                    }
                    {showSettings
                        ? <Settings
                            host={host}
                            appInfo={appInfo}
                            goToAndroidIconUpdateDocs={goToAndroidIconUpdateDocs}
                        />
                        : null
                    }
                    {showAnalytics
                        ? <Analytics
                            appInfo={appInfo}
                            goToAndroidIconUpdateDocs={goToAndroidIconUpdateDocs}
                        />
                        : null
                    }
                    {/* {showInstallDocs
                        ? <StartHereDocs 
                            host={host}
                            appInfo={appInfo} 
                            setAppInfo={setAppInfo}
                            showInstallDocs={showInstallDocs}
                            showFirebaseDocs={showFirebaseDocs}
                            showIndieInstallDocs={showIndieInstallDocs}
                            showAndroidIconUpdateDocs={showAndroidIconUpdateDocs}
                            goToSend={goToSend} 
                            goToAll={goToAll} 
                            goToOne={goToOne}
                            goToFirebase={goToFirebase}  
                            goToAndroidIconUpdateDocs={goToAndroidIconUpdateDocs}
                            goToAnalytics={goToAnalytics}
                            goToIOSDocs={goToIOSDocs}
                            showIOSDocs={showIOSDocs}
                          />
                        : null
                    } */}
                    {showFirebaseDocs
                        ? <FirebaseDocs
                            host={host}
                            appInfo={appInfo}
                            showInstallDocs={showInstallDocs}
                            showFirebaseDocs={showFirebaseDocs}
                            showIndieInstallDocs={showIndieInstallDocs}
                            showAndroidIconUpdateDocs={showAndroidIconUpdateDocs}
                            goToSend={goToSend}
                            goToAll={goToAll}
                            goToOne={goToOne}
                            goToFirebase={goToFirebase}
                            goToIOSDocs={goToIOSDocs}
                            showIOSDocs={showIOSDocs}
                            goToAndroidIconUpdateDocs={goToAndroidIconUpdateDocs}
                            goToAnalytics={goToAnalytics}
                        />
                        : null
                    }
                    {showIOSDocs
                        ? <IOSDocs
                            host={host}
                            appInfo={appInfo}
                            showInstallDocs={showInstallDocs}
                            showFirebaseDocs={showFirebaseDocs}
                            showIndieInstallDocs={showIndieInstallDocs}
                            showAndroidIconUpdateDocs={showAndroidIconUpdateDocs}
                            goToSend={goToSend}
                            goToAll={goToAll}
                            goToOne={goToOne}
                            goToFirebase={goToFirebase}
                            goToIOSDocs={goToIOSDocs}
                            showIOSDocs={showIOSDocs}
                            goToAndroidIconUpdateDocs={goToAndroidIconUpdateDocs}
                            goToAnalytics={goToAnalytics}
                            updateAppInfo={updateAppInfo}
                        />
                        : null
                    }
                    {showIndieInstallDocs
                        ? <IndieInstallDocs
                            appInfo={appInfo}
                            showInstallDocs={showInstallDocs}
                            showFirebaseDocs={showFirebaseDocs}
                            showIndieInstallDocs={showIndieInstallDocs}
                            showAndroidIconUpdateDocs={showAndroidIconUpdateDocs}
                            goToSend={goToSend}
                            goToAll={goToAll}
                            goToOne={goToOne}
                            goToFirebase={goToFirebase}
                            goToAndroidIconUpdateDocs={goToAndroidIconUpdateDocs}
                            goToAnalytics={goToAnalytics}
                            goToIOSDocs={goToIOSDocs}
                            showIOSDocs={showIOSDocs}
                        />
                        : null
                    }
                    {showAndroidIconUpdateDocs
                        ? <AnalyticsInstallDocs
                            appInfo={appInfo}
                            showInstallDocs={showInstallDocs}
                            showFirebaseDocs={showFirebaseDocs}
                            showIndieInstallDocs={showIndieInstallDocs}
                            showAndroidIconUpdateDocs={showAndroidIconUpdateDocs}
                            goToSend={goToSend}
                            goToAll={goToAll}
                            goToOne={goToOne}
                            goToFirebase={goToFirebase}
                            goToAndroidIconUpdateDocs={goToAndroidIconUpdateDocs}
                            goToAnalytics={goToAnalytics}
                            goToIOSDocs={goToIOSDocs}
                            showIOSDocs={showIOSDocs}
                        />
                        : null
                    }
                </View>

            </View>
        )
    }


}

const styles = StyleSheet.create({
    page: {
        flex: 1,
        height: '100%',
        minHeight: '100vh',
        width: '100%',
        minWidth: '100vw',
        maxWidth: '100vw',
        backgroundColor: "rgb(250, 250, 250)",
        // overflowX: 'hidden'
    },
    body: {
        width: '100%',
        flexDirection: 'row'
    }
});