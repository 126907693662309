import React, { useState } from 'react';
import {
  ActivityIndicator,
  View,
  Text,
  TouchableOpacity,
  TextInput,
  StyleSheet,
} from 'react-native';
import axios from 'axios';

import Header from '../../../Headers/HomeHeader';

export default function ResetPasswordOne({
  host,
  navigation,
  setCategory,
}) {
  const [email, setEmail] = useState('');
  const [resetCode, setResetCode] = useState('');
  const [step, setStep] = useState(1);
  const [showLoading, setShowLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const sendResetCode = () => {
    setShowLoading(true);
    axios
      .post(`${host}/api/resetCode`, { email })
      .then((res) => {
        console.log('res.data: ', res.data);
        if (
          res.data ===
          'Sorry, that email is not associated with a Native Notify account. Please try again.'
        ) {
          setShowLoading(false);
          return alert(
            'Sorry, that email is not associated with a Native Notify account. Please try again.'
          );
        }
        setStep(2)
        setShowLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoading(false);
        alert('An error occurred. Please try again later.');
      });
  };

  const checkPassword = () => {
    setShowLoading(true);
    if (password === confirmPassword) {
      axios
        .post(`${host}/api/resetPassword`, { email, password, code: resetCode })
        .then((res) => {
          console.log(res.data);
          setShowLoading(false);
          alert('Your password was successfully reset.');
          navigation.navigate('Login');
        })
        .catch((err) => {
          console.log(err);
          alert(
            'Sorry, the reset code you typed does not match our records. Please try again.'
          );
          setStep(2);
          setShowLoading(false);
        });
    } else {
      alert(
        'Sorry, the password and confirm password do not match. Please try again.'
      );
      setShowLoading(false);
    }
  };

  return (
    <div style={styles.page} className='radialGradientBackground'>
      <View style={styles.bodyContainer} >
        <Header navigation={navigation} />
        {step === 1 && (
          <View style={styles.body}>
            <Text style={styles.headerText}>Reset Password</Text>
            <Text style={styles.subtitleText}>
              What email is associated with your account?
            </Text>
            <TextInput
              style={styles.inputLarge}
              onChangeText={(text) => setEmail(text.toLowerCase())}
              placeholder="Email"
              placeholderTextColor="#6e6d6c"
              value={email}
            />
            <TouchableOpacity
              style={styles.button}
              onPress={sendResetCode}
              disabled={showLoading}
            >
                {showLoading 
                    ? <ActivityIndicator size="small" color="rgb(54, 117, 212)" />
                    : <Text style={styles.text}>Submit</Text>
                }
            </TouchableOpacity>
          </View>
        )}
        {step === 2 && (
          <View style={styles.body}>
            <Text style={styles.headerText}>Check Your Email</Text>
            <Text style={styles.subtitleText}>
              We sent a password reset code to your email. Please type that code
              below:
            </Text>
            <TextInput
              style={styles.inputLarge}
              onChangeText={(text) => setResetCode(text)}
              placeholder="Reset Code"
              placeholderTextColor="#6e6d6c"
              value={resetCode}
            />
            <TouchableOpacity
              style={styles.button}
              onPress={() => setStep(3)}
              disabled={showLoading}
            >
                {showLoading 
                    ? <ActivityIndicator size="small" color="rgb(54, 117, 212)" />
                    : <Text style={styles.text}>Submit</Text>
                }
            </TouchableOpacity>
          </View>
        )}
        {step === 3 && (
          <View style={styles.body}>
            <Text style={styles.headerText}>Reset Password</Text>
            <TextInput
              style={styles.inputLarge}
              onChangeText={(text) => setPassword(text)}
              placeholder="New Password"
              placeholderTextColor="#6e6d6c"
              value={password}
              secureTextEntry
            />
            <TextInput
              style={styles.inputLarge}
              onChangeText={(text) => setConfirmPassword(text)}
              placeholder="Confirm New Password"
              placeholderTextColor="#6e6d6c"
              value={confirmPassword}
              secureTextEntry
            />
            <TouchableOpacity
              style={styles.button}
              onPress={checkPassword}
              disabled={showLoading}
            >
                {showLoading 
                    ? <ActivityIndicator size="small" color="rgb(54, 117, 212)" />
                    : <Text style={styles.text}>Reset Password</Text>
                }
            </TouchableOpacity>
          </View>
        )}
      </View>
    </div>
  );
}

const styles = StyleSheet.create({
  page: {
    flex: 1,
    backgroundColor: 'rgb(20, 20, 60)',
  },
  bodyContainer: {
    flex: 1,
    position: 'relative',
    alignItems: 'center',
    height: '100%'
  },
  radialGradient: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  body: {
    width: '95%',
    maxWidth: 500,
    maxHeight: 700,
    padding: '4%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 6,
    marginTop: 60,
    alignSelf: 'center',
  },
  headerText: {
    width: '100%',
    textAlign: 'left',
    fontFamily: 'Arial',
    fontWeight: '700',
    fontSize: 30,
    marginBottom: 5,
  },
  subtitleText: {
    width: '100%',
    fontFamily: 'Arial',
    fontWeight: '400',
    fontSize: 16,
    color: '#6e6d6c',
    marginBottom: 20,
  },
  inputLarge: {
    width: '100%',
    padding: 12.5,
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderColor: '#99999920',
    backgroundColor: '#fff',
    borderRadius: 6,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    marginBottom: 15,
  },
  button: {
    width: '100%',
    height: 45,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000',
    borderRadius: 6,
    marginBottom: 20,
    marginTop: 15,
  },
  text: {
    fontFamily: 'Arial',
    fontWeight: '700',
    color: '#fff',
    fontSize: 20,
  },
});
