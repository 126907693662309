import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Platform } from 'react-native';

import DocsMenu from './Components/DocsMenu';

export default function IndieInstallDocs({ appInfo, InAppNavigation }) {
    const { setScreenName } = InAppNavigation;
    
    return (
        <View style={styles.body}>
            <DocsMenu InAppNavigation={InAppNavigation} />
            <Text style={styles.HeadingText}>Follow these steps to send push notifications to groups of users:</Text>
            <Text style={styles.plainText}>These instructions show how to send push notifications to groups of users programmatically.</Text>
            <Text style={styles.boldText}>Make sure you follow the <TouchableOpacity onPress={() => setScreenName('indieInstallDocs')}>'Indie Push'</TouchableOpacity> guide instructions BEFORE you set up Group Push Notifications. Group Push Notifications will not work without first following the <TouchableOpacity onPress={() => setScreenName('indieInstallDocs')}>'Indie Push'</TouchableOpacity> Guide.</Text>
            
            <Text style={styles.mb}></Text>
            <Text style={styles.mb}></Text>

            {Platform.OS === "web"
                ? <View style={styles.youTubeVideo}>
                    <Text style={styles.h2}>This video walks you through the setup guide below:</Text>
                    <iframe width="400" height="220" src="https://www.youtube.com/embed/pJLHnv0Tozo?rel=0" title="Native Notify Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen="allowfullscreen"></iframe>
                    
                    </View>
                : null 
            }
            
            <View style={styles.dottedLine}></View>

            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.blackBacktext}>npm install axios</Text> 

            <View style={styles.dottedLine}></View>

            <Text style={styles.mb}></Text>
            <Text style={styles.HeadingText}>How to send a group push notification:</Text>
            <Text style={styles.mb}>If you followed the Indie Push setup guide, you are already setup to send Group Push Notifications. Use your registered Indie Push Sub IDs in an array to programmatically send a push notification ONLY to the users with a matching indie sub ID in the subIDs array.</Text>
            <Text style={styles.mb}>Here is the API:</Text>
            <Text style={styles.blackBacktext}>
                <Text>axios.<Text style={styles.yellowText}>post</Text>(`https://app.nativenotify.com/api/indie/group/notification`, {`{`}</Text>
                <Text>      subIDs: ['<Text style={styles.lightBlueText}>indie-sub-id-1</Text>', '<Text style={styles.lightBlueText}>indie-sub-id-2</Text>', '<Text style={styles.lightBlueText}>indie-sub-id-3</Text>'],</Text>
                <Text>      appId: {appInfo.app_id},</Text>
                <Text>      appToken: '{appInfo.app_token}',</Text>
                <Text>      title: '<Text style={styles.lightBlueText}>put your push notification title here as a string</Text>',</Text>
                <Text>      message: '<Text style={styles.lightBlueText}>put your push notification message here as a string</Text>'</Text>
                <Text>{" });"}</Text>
            </Text>
            <Text style={styles.plainText}>
            Do NOT change the 'appId' or the 'appToken' value in this code. We put your appId and appToken in the code for you. 
            </Text>
            <Text style={styles.mb}></Text>
            <Text style={styles.mb}>You can also send an optional pushData object with your post. Here's an example:</Text>
            <Text style={styles.blackBacktext}>
                <Text>axios.<Text style={styles.yellowText}>post</Text>(`https://app.nativenotify.com/api/indie/group/notification`, {`{`}</Text>
                <Text>      ...,</Text>
                <Text>      pushData: '{'{ "yourProperty": "yourPropertyValue" }'}'</Text>
                <Text>{" });"}</Text>
            </Text>
            <Text style={styles.mb}></Text>
            <Text style={styles.mb}></Text>
            <Text style={styles.plainText}>Group push notifications will be sent immediately to the individual app users associated with your subIDs.</Text>
            
        </View>
    )
}

const styles = StyleSheet.create({
    body: {
        width: '85%',
        paddingTop: 30,
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingBottom: 200
    },

    buttonText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 16,
        color: '#fff',
    },
    button: {
        width: 220,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
    },
    HeadingText: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        // marginTop: 70,
        marginBottom: 10
    },
    h2: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 20,
        marginBottom: 10
    },
    boldText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginTop: 20,
        marginBottom: 5
    },
    bT: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 16,
        marginBottom: 5
    },
    blackBacktext: {
        backgroundColor: "#1b1f23",
        color: "#fff",
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        padding: 20,
        borderRadius: 6,
        marginBottom: 5,
        display: "flex",
        flexDirection: "column"
    },
    blueText: {
        color: "rgb(54, 117, 212)"
    },
    medBlueText: {
        color: "rgb(81, 130, 204)"
    },
    dottedLine: {
        height: 1,
        width: "100%",
        borderTopWidth: 1,
        borderColor: "#000",
        borderStyle: "dotted",
        marginTop: 30,
        marginBottom: 10,
    },
    lightBlueText: {
        color: "rgb(144, 180, 218)"
    },
    plainText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 5
    },
    purpleText: {
        color: "rgb(187, 86, 187)"
    },
    mb: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 10
    },
    tab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50
    },
    yellowText: {
        color: "rgb(222, 222, 160)"
    }
});