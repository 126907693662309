import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image } from 'react-native';

import Icon from 'react-native-vector-icons/MaterialIcons';

export default function HomeHeader({ navigation }) {
    return (
        <View style={styles.headerCont}>
            <TouchableOpacity style={styles.smallCont} onPress={() => navigation.navigate('Home')}>
                <Image
                    style={styles.logo}
                    source={require('../assets/images/logo-white.png')}
                    resizeMode='cover'
                />
                {/* <Icon
                    name="notifications-on"
                    size={20}
                    onPress={() => console.log('click')}
                /> */}
                <Text style={styles.logoText}>Native Notify</Text>
            </TouchableOpacity>
           
            <View style={styles.smallCont}>
                <TouchableOpacity onPress={() => navigation.navigate('Login')}>
                    <Text style={styles.whiteText}>Login</Text>
                </TouchableOpacity>
                {/* <TouchableOpacity style={styles.signUpButton} onPress={() => navigation.navigate('SignUpOne')}>
                    <Text style={styles.whiteText}>Sign Up</Text>
                </TouchableOpacity> */}
            </View> 
        </View>
    )
}

let mB;

if(screen.width > 1024) {
    mB = 0
} else {
    mB = 20;
}

const styles = StyleSheet.create({
    headerCont: {
        width: '100%',
        maxWidth: 1400,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 20,
        paddingLeft: '5%',
        paddingRight: '5%',
        flexWrap: 'wrap',
        // borderColor: "#99999920",
        // backgroundColor: "#14143c",
        backgroundColor: "transparent",
        // // borderRadius: 6,
        // shadowColor: "#000",
        // shadowOffset: {
        //     width: 0,
        //     height: 2,
        // },
        // shadowOpacity: 0.25,
        // shadowRadius: 3.84,
        // elevation: 5,
        marginBottom: mB
    },
    smallCont: {
        height: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    logo: {
        width: 22,
        height: 30,
        color: '#fff'
    },
    logoText: {
        fontSize: 20,
        fontFamily: 'Arial',
        fontWeight: 600,
        marginLeft: 15,
        color: '#fff'
    },
    loginButton: {
        cursor: 'pointer',
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10,
        marginRight: 10,
        backgroundColor: '#fff',
        borderRadius: 3
    },
    signUpButton: {
        cursor: 'pointer',
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: '#000',
        borderRadius: 3
    },
    blackText: {
        color: '#000',
        fontFamily: 'Arial',
        fontWeight: 400,
    },
    whiteText: {
        color:'#fff',
        fontFamily: 'Arial',
        fontWeight: 600,
    }
});