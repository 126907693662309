import React, { useState, useEffect, useRef } from 'react';
import { ActivityIndicator, View, StyleSheet } from 'react-native';
import { useScrollToTop } from '@react-navigation/native';
import axios from '../../axios';

// components
import Header from '../../Headers/NotificationHeader';
import NotificationSidebar from './Screens/Components/NotificationSidebar';
// import DocsMenu from './Screens/DocumentationScreens/Components/DocsMenu';

// main screens / sidebar screens
import SendNotification from './Screens/SendNotification';
import ScheduleNotifications from './Screens/ScheduleNotifications';
import NotificationInbox from './Screens/NotificationInbox';
import TopicGroups from './Screens/TopicGroups/TopicGroups';
import Analytics from './Screens/Analytics';
import Settings from './Screens/Settings';
import Subs from './Screens/Subs';

// documentation
import StartHereDocs from './Screens/DocumentationScreens/StartHereDocs';
import AndroidDocs from './Screens/DocumentationScreens/AndroidDocs';
import IOSDocs from './Screens/DocumentationScreens/IOSDocs';
import NotificationInboxDocs from './Screens/DocumentationScreens/NotificationInboxDocs';
import IndieInstallDocs from './Screens/DocumentationScreens/IndieInstallDocs';
import IndieNotificationInboxDocs from './Screens/DocumentationScreens/IndieNotificationInboxDocs';
import GroupPushDocs from './Screens/DocumentationScreens/GroupPushDocs';
import FollowPushDocs from './Screens/DocumentationScreens/FollowPushDocs';
import AnalyticsInstallDocs from './Screens/DocumentationScreens/AnalyticsInstallDocs';


export default function Notification({ navigation, host, appInfo, setAppInfo, appUpdated, setAppUpdated }) {
    const [showLoading, setShowLoading] = useState(true);
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [pushData, setPushData] = useState('');
    const [bigPictureURL, setBigPictureURL] = useState('');

    const [screenName, setScreenName] = useState('');
    // screenName main screens list: sendNot, scheduleNotifications, notInbox, topicGroups, analytics, settings
    // screenName documentation pages list: installDocs, androidDocs, IOSDocs, inboxDocs, indieInstallDocs, indieInboxDocs, GroupPushDocs, followDocs, analyticsDocs

    const goToSend = async () => {
        setScreenName('sendNot');

        await axios
            .get(`${host}/api/app/${getCookie('app_id')}`)
            .then(res => {
                // console.log("goToSend appInfo: ", appInfo)
                setAppInfo(res.data);
            })
            .catch(err => console.log(err));
    }

    const updateAppInfo = async () => {
        await axios
            .get(`${host}/api/app/${getCookie('app_id')}`)
            .then(res => {
                setAppInfo(res.data);
            })
            .catch(err => console.log(err));
    }

    const InAppNavigation = {
        goToSend,
        screenName, setScreenName
    };

    const ref = useRef(null);
    useScrollToTop(ref);

    function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

    useEffect(() => {
        // console.log("appInfo: ", appInfo.app_sub_count, appInfo.expo_tokens);
        setShowLoading(true)

        if (!appInfo.app_sub_count || !appInfo.expo_tokens) {
            setScreenName('installDocs');
        } else {
            // console.log("InAppNavigation 1 appInfo: ", appInfo)
            setScreenName('sendNot');
        }

        if (appInfo.app_sub_count > 0 || appInfo.expo_tokens > 0) {
            // console.log("InAppNavigation 2 appInfo: ", appInfo)
            setScreenName('sendNot');
        } else {
            setScreenName('installDocs');
        }

        // this is triggered so the push_sub_count will be updated
        axios.get(`${host}/api/all/apps`)

        // this is triggered to get the current check premium status and to set app info
        axios
            .get(`${host}/api/user`)
            .then(response => {
                const data = response.data;
                // console.log(data);

                if (!data.paid_sub) {
                    let today = new Date();
                    let priorDate = new Date().setDate(today.getDate() - 7);

                    if (+new Date(priorDate) > +new Date(data.date_signed_up) && !data.custom_pricing) {
                        navigation.navigate('Dashboard');
                    } else {
                        axios
                            .get(`${host}/api/app/${getCookie('app_id')}`)
                            .then(res => {
                                // console.log("res.data: ", res.data);
                                // console.log("InAppNavigation 3 appInfo res.data: ", res.data)
                                setAppInfo(res.data);

                                if (res.data.app_sub_count < 1) {
                                    setScreenName('installDocs');
                                } else {
                                    setScreenName('sendNot');
                                }

                                setShowLoading(false);
                            })
                            .catch(err => console.log(err))
                    }
                } else if (data.paid_sub && !data.custom_pricing) {
                    axios
                        .put(`${host}/api/stripe/subscription/quantity`, {})
                        .then(responseToPost => {
                            // console.log(responseToPost);

                            axios
                                .get(`${host}/api/app/${getCookie('app_id')}`)
                                .then(res => {
                                    // console.log("res.data: ", res.data)
                                    // console.log("InAppNavigation 4 appInfo: ", appInfo)
                                    setAppInfo(res.data);

                                    if (res.data.app_sub_count < 1) {
                                        setScreenName('installDocs');
                                    } else {
                                        setScreenName('sendNot');
                                    }

                                    setShowLoading(false);
                                })
                                .catch(err => console.log(err))
                        })
                        .catch(errorOfPost => console.log(errorOfPost))
                } else if (data.paid_sub && data.custom_pricing) {
                    axios
                        .get(`${host}/api/app/${getCookie('app_id')}`)
                        .then(res => {
                            // console.log("res.data: ", res.data);
                            // console.log("InAppNavigation 3 appInfo res.data: ", res.data)
                            setAppInfo(res.data);

                            if (res.data.app_sub_count < 1) {
                                setScreenName('installDocs');
                            } else {
                                setScreenName('sendNot');
                            }

                            setShowLoading(false);
                        })
                        .catch(err => console.log(err))
                }
            })
            .catch(err => console.log(err))
    }, [appUpdated]);

    const sendNotification = () => {
        // if(!appInfo.expo_tokens && !appInfo.expo_ios_tokens && !appInfo.expo_android_tokens) {
        //     return alert("Sorry, your app does not have any push notification subscribers yet. Try opening your app in the Expo Go app, then refresh this page and try again.")
        // }

        if (title && body) {
            let sendConfirm = confirm("Are you ready to send your push notification? This will be sent to all of your notification subscribers immediately.");

            if (sendConfirm) {
                const date = new Date();
                let hour = date.getHours();
                let amPM = "";

                if (hour === 12) {
                    amPM = "PM"
                } else if (hour > 12) {
                    amPM = "PM"
                    hour = hour - 12;
                } else {
                    amPM = "AM"
                }

                axios
                    .post(`${host}/api/notification`, {
                        appId: getCookie('app_id'),
                        title,
                        body,
                        dateSent: (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear() + " " + hour + ":" + ('0' + date.getMinutes()).slice(-2) + amPM,
                        pushData,
                        bigPictureURL
                    })
                    .then(res => {
                        // console.log(res.data);
                        alert(`Your push notification was successfully sent!`);
                        setTitle('');
                        setBody('');
                        setPushData('');
                        setBigPictureURL('');

                        axios
                            .get(`${host}/api/app/${getCookie('app_id')}`)
                            .then(res => {
                                // console.log("res.data: ", res.data)
                                setAppInfo(res.data);
                            })
                            .catch(err => console.log(err))
                    })
                    .catch(err => {
                        console.log(err);
                        alert("There was an error. We're going to refresh the page for you. Please, try to send a push notification again after the page reload.");
                        location.reload();
                    })
            }
        } else {
            alert('Please, type a notification title and a notification message before sending a push notification.')
        }
    }

    if (showLoading) {
        return <ActivityIndicator size="large" color="rgb(54, 117, 212)" style={styles.page} />
    }

    else {
        return (
            <View style={styles.page}>
                <Header
                    navigation={navigation}
                    host={host}
                    appInfo={appInfo}
                    setAppInfo={setAppInfo}
                    setAppUpdated={setAppUpdated}
                />
                {/* <DocsMenu InAppNavigation={InAppNavigation} /> */}
                <View style={styles.body}>
                    <NotificationSidebar
                        host={host}
                        setAppInfo={setAppInfo}
                        InAppNavigation={InAppNavigation}
                    />
                    {screenName === "sendNot"
                        ? <SendNotification
                            host={host}
                            appInfo={appInfo}
                            title={title}
                            setTitle={setTitle}
                            body={body}
                            setBody={setBody}
                            pushData={pushData}
                            setPushData={setPushData}
                            bigPictureURL={bigPictureURL}
                            setBigPictureURL={setBigPictureURL}
                            sendNotification={sendNotification}
                        />
                        : null
                    }
                    {screenName === "scheduleNotifications"
                        ? <ScheduleNotifications
                            host={host}
                            appInfo={appInfo}
                        />
                        : null
                    }
                    {screenName === "notInbox"
                        ? <NotificationInbox
                            host={host}
                            appInfo={appInfo}
                            setAppInfo={setAppInfo}
                            InAppNavigation={InAppNavigation}
                        />
                        : null
                    }
                    {screenName === "topicGroups"
                        ? <TopicGroups
                            host={host}
                            appInfo={appInfo}
                            setAppInfo={setAppInfo}
                            InAppNavigation={InAppNavigation}
                        />
                        : null
                    }
                    {screenName === "analytics"
                        ? <Analytics
                            appInfo={appInfo}
                            InAppNavigation={InAppNavigation}
                        />
                        : null
                    }
                    {screenName === "settings"
                        ? <Settings
                            host={host}
                            appInfo={appInfo}
                            setAppInfo={setAppInfo}
                            InAppNavigation={InAppNavigation}
                            updateAppInfo={updateAppInfo}
                            navigation={navigation}
                            setAppUpdated={setAppUpdated}
                        />
                        : null
                    }
                    {screenName === "subs"
                        ? <Subs
                            host={host}
                            appInfo={appInfo}
                            InAppNavigation={InAppNavigation}
                            updateAppInfo={updateAppInfo}
                        />
                        : null
                    }

                    {/* documentation */}
                    {screenName === "installDocs"
                        ? <StartHereDocs
                            host={host}
                            appInfo={appInfo}
                            setAppInfo={setAppInfo}
                            InAppNavigation={InAppNavigation}
                        />
                        : null
                    }
                    {screenName === "androidDocs"
                        ? <AndroidDocs
                            host={host}
                            appInfo={appInfo}
                            updateAppInfo={updateAppInfo}
                            InAppNavigation={InAppNavigation}
                        />
                        : null
                    }
                    {screenName === "IOSDocs"
                        ? <IOSDocs
                            host={host}
                            appInfo={appInfo}
                            InAppNavigation={InAppNavigation}
                            updateAppInfo={updateAppInfo}
                        />
                        : null
                    }
                    {screenName === "inboxDocs"
                        ? <NotificationInboxDocs
                            host={host}
                            appInfo={appInfo}
                            InAppNavigation={InAppNavigation}
                        />
                        : null
                    }
                    {screenName === "indieInstallDocs"
                        ? <IndieInstallDocs
                            appInfo={appInfo}
                            InAppNavigation={InAppNavigation}
                        />
                        : null
                    }
                    {screenName === "indieInboxDocs"
                        ? <IndieNotificationInboxDocs
                            host={host}
                            appInfo={appInfo}
                            InAppNavigation={InAppNavigation}
                        />
                        : null
                    }
                    {screenName === "GroupPushDocs"
                        ? <GroupPushDocs
                            appInfo={appInfo}
                            InAppNavigation={InAppNavigation}
                        />
                        : null
                    }
                    {screenName === "followDocs"
                        ? <FollowPushDocs
                            appInfo={appInfo}
                            InAppNavigation={InAppNavigation}
                        />
                        : null
                    }
                    {screenName === "analyticsDocs"
                        ? <AnalyticsInstallDocs
                            appInfo={appInfo}
                            InAppNavigation={InAppNavigation}
                        />
                        : null
                    }
                </View>

            </View>
        )
    }


}

const styles = StyleSheet.create({
    page: {
        flex: 1,
        height: '100%',
        minHeight: '100vh',
        width: '100%',
        minWidth: '100vw',
        maxWidth: '100vw',
        backgroundColor: "rgb(250, 250, 250)",
    },
    body: {
        width: '100%',
        flexDirection: 'row'
    }
});