import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Platform } from 'react-native';

import DocsMenu from './Components/DocsMenu';

export default function IndieInstallDocs({ appInfo, InAppNavigation }) {
    const { setScreenName } = InAppNavigation;

    return (
        <View style={styles.body}>
            <DocsMenu InAppNavigation={InAppNavigation} />
            <Text style={styles.HeadingText}>Follow these steps to create a Following Network that allows users to follow other users and to send notifications to a group of followers:</Text>
            <Text style={styles.plainText}>These instructions show how to create a Following Network. Users can register to become a Follow Master. Follow Masters can be followed by other users. When a Follow Push Notification is sent, the notification is ONLY sent to all of the Follow Master's followers.</Text>

            <Text style={styles.boldText}>Make sure you follow the <TouchableOpacity onPress={() => setScreenName('installDocs')}>'Start Here'</TouchableOpacity> instructions and the <TouchableOpacity onPress={() => setScreenName('indieInstallDocs')}>'Indie Push Setup Guide'</TouchableOpacity> instructions BEFORE you set up Follow Push Notifications. Follow Push Notifications will not work without first following the <TouchableOpacity onPress={() => setScreenName('installDocs')}>'Start Here'</TouchableOpacity> instructions and the <TouchableOpacity onPress={() => setScreenName('indieInstallDocs')}>'Indie Push Setup Guide'</TouchableOpacity>.</Text>

            <Text style={styles.mb}></Text>
            <Text style={styles.mb}></Text>

            {Platform.OS === "web"
                ? <View style={styles.youTubeVideo}>
                    <Text style={styles.h2}>This video walks you through the setup guide below:</Text>
                    <iframe width="400" height="220" src="https://www.youtube.com/embed/jyVG-I26Oxs?rel=0" title="Native Notify Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen="allowfullscreen"></iframe>

                </View>
                : null
            }

            <View style={styles.dottedLine}></View>

            <Text style={styles.HeadingTextTop}>Follow Push Notifications preview:</Text>
            <Text style={styles.plainText}>Here's how our Follow Push Notifications work:</Text>
            <Text style={styles.mb}></Text>
            <Text style={styles.plainText}><Text style={styles.bT}>Follow Master Registration:</Text></Text>
            <Text style={styles.plainText}>Send us the unique Indie Push Notification subscriber ID you want to be a Follow Master with the ability to be followed by other Indie Push Notification subscriber IDs.</Text>
            <Text style={styles.mb}>(This requires first registering your users using the Indie Push Setup Guide. Use the same unique subscriber IDs you used during the Indie Push Setup Guide.)</Text>
            <Text style={styles.mb}></Text>

            <Text style={styles.plainText}><Text style={styles.bT}>Follower Registration:</Text></Text>
            <Text style={styles.plainText}>Send us the unique Indie Push Notification subscriber ID of the Follow Master and the Indie Push Notification subscriber ID of the user you want to follow the Follow Master. You can register as many followers as you want, but you must only register ONE follower at a time.</Text>
            <Text style={styles.mb}>(This requires first registering your users using the Indie Push Setup Guide. Use the same unique subscriber IDs you used during the Indie Push Setup Guide.)</Text>
            <Text style={styles.mb}></Text>

            <Text style={styles.plainText}><Text style={styles.bT}>Use:</Text> Use the Follow Master's unique Indie Push Notification subscriber ID whenever you want to send a Follow Push Notification to all of the Follow Master's followers. The Follow Push Notification will ONLY be sent to the Follow Master's followers.</Text>

            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>
            <Text style={styles.HeadingText}>Setup Guide:</Text>

            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.blackBacktext}>
                <Text style={styles.mbSmall}>npm install native-notify@latest axios</Text>
                <Text>npx expo install expo-device expo-notifications expo-constants</Text>
            </Text>

            <Text style={styles.boldText}>Step 2:</Text>
            <Text style={styles.plainText}>Make sure to import the functions you intend to use at the top of the screen. Here are the Follow Push Notification functions available:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>{"{ registerFollowMasterID, registerFollowerID, postFollowingID, unfollowMasterID, updateFollowersList, getFollowMaster, deleteFollowMaster }"} </Text><Text style={styles.purpleText}>from</Text> 'native-notify';</Text>
                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>axios</Text> <Text style={styles.purpleText}>from</Text> 'axios';</Text>
            </Text>

            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>
            <Text style={styles.HeadingText}>Follow Master Registration:</Text>

            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.plainText}>In your function, use this code to register a Follow Master:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.medBlueText}>const</Text> <Text style={styles.yellowText}>yourFunction</Text> = () <Text style={styles.lightBlueText}>=></Text> {"{"}</Text>
                <Text style={styles.mb}>    ...</Text>
                <Text style={styles.mb}>    // Native Notify Follow Master Registration Code</Text>
                <Text>    <Text style={styles.yellowText}>registerFollowMasterID</Text>(</Text>
                <Text>        'put the unique Indie Push user ID you want to be a Follow Master here as a string',</Text>
                <Text>        {appInfo.app_id},</Text>
                <Text>        '{appInfo.app_token}'</Text>
                <Text style={styles.mb}>    );</Text>
                <Text>    // End of Native Notify Code</Text>
                <Text style={styles.mb}>{"}"}</Text>
            </Text>
            <Text style={styles.plainText}>Do NOT change the 'appId' or the 'appToken' value in this code. We put your appId and appToken in the code for you. </Text>

            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>
            <Text style={styles.HeadingText}>Follower Registration:</Text>

            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.plainText}>In your function, use this code to register a Follower:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.medBlueText}>const</Text> <Text style={styles.yellowText}>yourFunction</Text> = () <Text style={styles.lightBlueText}>=></Text> {"{"}</Text>
                <Text style={styles.mb}>    ...</Text>
                <Text style={styles.mb}>    // Native Notify Follower Registration Code</Text>
                <Text>    <Text style={styles.yellowText}>registerFollowerID</Text>(</Text>
                <Text>        'put the unique Indie Push user ID of the Follow Master you registered above here as a string',</Text>
                <Text>        'put the unique Indie Push user ID you want to be a Follower of the Follow Master here as a string',</Text>
                <Text>        {appInfo.app_id},</Text>
                <Text>        '{appInfo.app_token}'</Text>
                <Text style={styles.mb}>    );</Text>
                <Text>    // End of Native Notify Code</Text>
                <Text style={styles.mb}>{"}"}</Text>
            </Text>
            <Text style={styles.plainText}>Do NOT change the 'appId' or the 'appToken' value in this code. We put your appId and appToken in the code for you. </Text>


            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>
            <Text style={styles.HeadingText}>How to send a Follow Push Notification:</Text>
            <Text style={styles.plainText}>At this point, you are ready to send Follow Push Notifications. Whenever you want to send a Follow Push Notification, simply send us your unique Follow Master user ID (masterSubID), your app ID, your app Token, a push notification title, and a push notification message. The push notification will ONLY be sent to the Follow Master's followers.</Text>
            <Text style={styles.mb}>Here is the API:</Text>
            <Text style={styles.blackBacktext}>
                <Text>axios.<Text style={styles.yellowText}>post</Text>(`https://app.nativenotify.com/api/follow/notification`, {`{`}</Text>
                <Text>      masterSubID: '<Text style={styles.lightBlueText}>put your unique Follow Master user ID here as a string</Text>',</Text>
                <Text>      appId: {appInfo.app_id},</Text>
                <Text>      appToken: '{appInfo.app_token}',</Text>
                <Text>      title: '<Text style={styles.lightBlueText}>put your push notification title here as a string</Text>',</Text>
                <Text>      message: '<Text style={styles.lightBlueText}>put your push notification message here as a string</Text>'</Text>
                <Text>{" });"}</Text>
            </Text>
            <Text style={styles.plainText}>
                Do NOT change the 'appId' or the 'appToken' value in this code. We put your appId and appToken in the code for you.
            </Text>
            <Text style={styles.mb}></Text>
            <Text style={styles.mb}>You can also send an optional pushData object with your post. Here's an example:</Text>
            <Text style={styles.blackBacktext}>
                <Text>axios.<Text style={styles.yellowText}>post</Text>(`https://app.nativenotify.com/api/follow/notification`, {`{`}</Text>
                <Text>      ...,</Text>
                <Text>      pushData: {'{ yourProperty: "yourPropertyValue" }'}</Text>
                <Text>{" });"}</Text>
            </Text>
            <Text style={styles.mb}></Text>
            <Text style={styles.mb}></Text>

            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>
            <Text style={styles.HeadingTextTop}>Other Functions Available:</Text>

            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>
            <Text style={styles.HeadingText}>Get Follow Master Information:</Text>

            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.plainText}>In your function, use this code to get a Follow Master's information as an object:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.medBlueText}>const</Text> <Text style={styles.yellowText}>yourFunction</Text> = () <Text style={styles.lightBlueText}>=></Text> {"{"}</Text>
                <Text style={styles.mb}>    ...</Text>
                <Text style={styles.mb}>    // Native Notify Get Follow Master Code</Text>
                <Text>    <Text style={styles.medBlueText}>let</Text> <Text style={styles.lightBlueText}>followMasterData</Text> = <Text style={styles.yellowText}>getFollowMaster</Text>(</Text>
                <Text>        'put the Follow Master Indie Push ID here as a string',</Text>
                <Text>        {appInfo.app_id},</Text>
                <Text>        '{appInfo.app_token}'</Text>
                <Text style={styles.mb}>    );</Text>
                <Text>    // End of Native Notify Code</Text>
                <Text style={styles.mb}>{"}"}</Text>
            </Text>
            <Text style={styles.plainText}>Do NOT change the 'appId' or the 'appToken' value in this code. We put your appId and appToken in the code for you. </Text>

            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>
            <Text style={styles.HeadingText}>Add to the Follow Master's Following List:</Text>
            <Text style={styles.plainText}>(A Following List is the list of users a Follow Master is following.):</Text>

            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.plainText}>In your function, use this code to add a Follow Master ID to the list of users a Follow Master is following:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.medBlueText}>const</Text> <Text style={styles.yellowText}>yourFunction</Text> = () <Text style={styles.lightBlueText}>=></Text> {"{"}</Text>
                <Text style={styles.mb}>    ...</Text>
                <Text style={styles.mb}>    // Native Notify add to Follow Master's Following List Code</Text>
                <Text>    <Text style={styles.yellowText}>postFollowingID</Text>(</Text>
                <Text>        'put the Follow Master Indie Push ID here as a string',</Text>
                <Text>        'put the Master Indie Push ID of the user your are adding to this Follow Master's Following List here as a string',</Text>
                <Text>        {appInfo.app_id},</Text>
                <Text>        '{appInfo.app_token}'</Text>
                <Text style={styles.mb}>    );</Text>
                <Text>    // End of Native Notify Code</Text>
                <Text style={styles.mb}>{"}"}</Text>
            </Text>
            <Text style={styles.plainText}>Do NOT change the 'appId' or the 'appToken' value in this code. We put your appId and appToken in the code for you. </Text>

            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>
            <Text style={styles.HeadingText}>How to Unfollow a Follow Master:</Text>

            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.plainText}>In your function, use this code to unfollow a Follow Master:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.medBlueText}>const</Text> <Text style={styles.yellowText}>yourFunction</Text> = () <Text style={styles.lightBlueText}>=></Text> {"{"}</Text>
                <Text style={styles.mb}>    ...</Text>
                <Text style={styles.mb}>    // Native Notify Unfollow Follow Master Code</Text>
                <Text>    <Text style={styles.yellowText}>unfollowMasterID</Text>(</Text>
                <Text>        'put the unique Indie Push user ID of the Follow Master you want a Follower to unfollow here as a string',</Text>
                <Text>        'put the unique Indie Push user ID of the Follower you want to unfollow the Follow Master here as a string',</Text>
                <Text>        {appInfo.app_id},</Text>
                <Text>        '{appInfo.app_token}'</Text>
                <Text style={styles.mb}>    );</Text>
                <Text>    // End of Native Notify Code</Text>
                <Text style={styles.mb}>{"}"}</Text>
            </Text>
            <Text style={styles.plainText}>Do NOT change the 'appId' or the 'appToken' value in this code. We put your appId and appToken in the code for you. </Text>

            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>
            <Text style={styles.HeadingText}>How to Remove a User from a Follow Master's Following List:</Text>

            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.plainText}>In your function, use this code to remove a user from the Follow Master's Following List:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.medBlueText}>const</Text> <Text style={styles.yellowText}>yourFunction</Text> = () <Text style={styles.lightBlueText}>=></Text> {"{"}</Text>
                <Text style={styles.mb}>    ...</Text>
                <Text style={styles.mb}>    // Native Notify Remove User from Follow Master's Following List Code</Text>
                <Text>    <Text style={styles.yellowText}>updateFollowersList</Text>(</Text>
                <Text>        'put the Follow Master's Indie Push ID here as a string',</Text>
                <Text>        'put the Follow Master Indie Push ID you want to be REMOVED from the Following List here as a string',</Text>
                <Text>        {appInfo.app_id},</Text>
                <Text>        '{appInfo.app_token}'</Text>
                <Text style={styles.mb}>    );</Text>
                <Text>    // End of Native Notify Code</Text>
                <Text style={styles.mb}>{"}"}</Text>
            </Text>
            <Text style={styles.plainText}>Do NOT change the 'appId' or the 'appToken' value in this code. We put your appId and appToken in the code for you. </Text>


            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>
            <Text style={styles.HeadingText}>How to Delete/Unregister a Follow Master:</Text>

            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.plainText}>In your function, use this code to delete/unregister a Follow Master:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.medBlueText}>const</Text> <Text style={styles.yellowText}>yourFunction</Text> = () <Text style={styles.lightBlueText}>=></Text> {"{"}</Text>
                <Text style={styles.mb}>    ...</Text>
                <Text style={styles.mb}>    // Native Notify Delete Follow Master Code</Text>
                <Text>    <Text style={styles.yellowText}>deleteFollowMaster</Text>(</Text>
                <Text>        {appInfo.app_id},</Text>
                <Text>        '{appInfo.app_token}'</Text>
                <Text>        'put the unique Indie Push user ID of the Follow Master you want to delete/unregister here as a string',</Text>
                <Text style={styles.mb}>    );</Text>
                <Text>    // End of Native Notify Code</Text>
                <Text style={styles.mb}>{"}"}</Text>
            </Text>
            <Text style={styles.plainText}>Do NOT change the 'appId' or the 'appToken' value in this code. We put your appId and appToken in the code for you. </Text>


        </View>
    )
}

const styles = StyleSheet.create({
    body: {
        width: '85%',
        paddingTop: 30,
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingBottom: 200
    },

    buttonText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 16,
        color: '#fff',
    },
    button: {
        width: 220,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
    },
    HeadingTextTop: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        marginTop: 20,
        marginBottom: 10
    },
    HeadingText: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        // marginTop: 70,
        marginBottom: 10
    },
    h2: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 20,
        marginBottom: 10
    },
    boldText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginTop: 20,
        marginBottom: 5
    },
    bT: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 16,
        marginBottom: 5
    },
    blackBacktext: {
        backgroundColor: "#1b1f23",
        color: "#fff",
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        padding: 20,
        borderRadius: 6,
        marginBottom: 5,
        display: "flex",
        flexDirection: "column"
    },
    blueText: {
        color: "rgb(54, 117, 212)"
    },
    medBlueText: {
        color: "rgb(81, 130, 204)"
    },
    dottedLine: {
        height: 1,
        width: "100%",
        borderTopWidth: 1,
        borderColor: "#000",
        borderStyle: "dotted",
        marginTop: 30,
        marginBottom: 10,
    },
    lightBlueText: {
        color: "rgb(144, 180, 218)"
    },
    plainText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 5
    },
    purpleText: {
        color: "rgb(187, 86, 187)"
    },
    mb: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 10
    },
    tab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50
    },
    yellowText: {
        color: "rgb(222, 222, 160)"
    }
});