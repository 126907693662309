import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { View, Text, StyleSheet } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';

const Dropzone = ({ onFileUpload, uploadedFileName, setUploadedFileName }) => {
  // const [uploadedFileName, setUploadedFileName] = useState(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length === 1) {
        const file = acceptedFiles[0];
        const reader = new FileReader();

        reader.readAsText(file);

        reader.onload = () => {
          const content = reader.result;
          try {
            const jsonContent = JSON.parse(content);
            onFileUpload(jsonContent);
            setUploadedFileName(file.name);
          } catch (error) {
            console.error('Failed to parse JSON file:', error);
          }
        };
      }
    },
    [onFileUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div style={cssStyles.dropzone} {...getRootProps()}>
        <FontAwesome name="upload" size={30} style={cssStyles.uploadIcon} /> {/* Upload icon */}
        <input {...getInputProps()} />
        <p style={cssStyles.text}>
            {uploadedFileName ? `File uploaded successfully: ${uploadedFileName}` : 'Click here to select your Google Cloud Access Token JSON file'}
        </p>
        {uploadedFileName && (
            <p style={cssStyles.instructions}>
            Click here to upload a different file
            </p>
        )}
    </div>
  );
};

const cssStyles = {
  dropzone: {
    height: '150px',
    maxWidth: '700px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center',
    border: "2px solid #ddd",
    borderRadius: "5px",
    padding: "20px",
    marginBottom: "20px",
    cursor: 'pointer',
    textAlign: 'center'
  },
  uploadIcon: {
    marginBottom: 10,
  },
  text: {
    fontFamily: 'Arial',
    fontWeight: '400',
    fontSize: "16px",
    marginBottom: "5px",
  },
  instructions: {
    fontFamily: 'Arial',
    fontWeight: '400',
    fontSize: 14,
    color: '#2296f3',
    textDecorationLine: 'underline',
    marginTop: 10,
    cursor: 'pointer',
    textAlign: 'center'
  },
}

const styles = StyleSheet.create({
  dropzone: {
    height: 150,
    maxWidth: 700,
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: '#ddd',
    borderRadius: 5,
    padding: 20,
    marginBottom: 20,
    cursor: 'pointer',
    textAlign: 'center'
  },
  uploadIcon: {
    marginBottom: 10,
  },
  text: {
    fontFamily: 'Arial',
    fontWeight: '400',
    fontSize: 16,
    marginBottom: 5,
  },
  instructions: {
    fontFamily: 'Arial',
    fontWeight: '400',
    fontSize: 14,
    color: '#2296f3',
    textDecorationLine: 'underline',
    marginTop: 10,
    cursor: 'pointer',
    textAlign: 'center'
  },
});

export default Dropzone;