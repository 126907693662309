import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, ScrollView } from 'react-native';

export default function DocsMenu({ InAppNavigation }) {
    const { screenName, setScreenName } = InAppNavigation;

    return (
        <ScrollView contentContainerStyle={styles.container} horizontal={true} showsHorizontalScrollIndicator={true} persistentScrollbar={true}>
            <TouchableOpacity onPress={() => setScreenName('installDocs')}>
                <Text style={screenName === 'installDocs' ? styles.chosenText : styles.text}>Start Here</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setScreenName('androidDocs')}>
                <Text style={screenName === 'androidDocs' ? styles.chosenText : styles.text}>Android Setup</Text>
            </TouchableOpacity>
            {/* <TouchableOpacity onPress={() => setScreenName('IOSDocs')}>
                <Text style={screenName === 'IOSDocs' ? styles.chosenText : styles.text}>iOS Setup</Text>
            </TouchableOpacity> */}
            <TouchableOpacity onPress={() => setScreenName('publishAppDocs')}>
                <Text style={screenName === 'publishAppDocs' ? styles.chosenText : styles.text}>Publish Your App</Text>
            </TouchableOpacity>
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
    },
    chosenText: {
        color: 'rgb(54, 117, 212)',
        fontFamily: 'Arial',
        fontWeight: 600,
        borderBottomWidth: 2,
        borderBottomColor: 'rgb(54, 117, 212)',
        marginBottom: 30,
        marginRight: 60,
        fontSize: 14,
        paddingBottom: 10
    },
    text: {
        color: '#000',
        fontFamily: 'Arial',
        fontWeight: 600,
        marginBottom: 30,
        marginRight: 60,
        fontSize: 14,
        paddingBottom: 10
    }
})