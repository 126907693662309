import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Platform } from 'react-native';

import DocsMenu from './Components/DocsMenu';

export default function IndieInstallDocs({ appInfo, showInstallDocs, showFirebaseDocs, showIndieInstallDocs, goToAll, goToFirebase, goToOne, showAndroidIconUpdateDocs, goToAndroidIconUpdateDocs, goToIOSDocs, showIOSDocs }) {
    return (
        <View style={styles.body}>
            <DocsMenu 
                showInstallDocs={showInstallDocs} 
                showFirebaseDocs={showFirebaseDocs}
                showIndieInstallDocs={showIndieInstallDocs} 
                showAndroidIconUpdateDocs={showAndroidIconUpdateDocs}
                goToAll={goToAll} 
                goToFirebase={goToFirebase}
                goToOne={goToOne} 
                goToAndroidIconUpdateDocs={goToAndroidIconUpdateDocs}
                goToIOSDocs={goToIOSDocs}
                showIOSDocs={showIOSDocs} />
            <Text style={styles.HeadingText}>Follow these steps to send customized push notifications to individual users:</Text>
            <Text style={styles.plainText}>These instructions show how to send customized push notifications to individual users. We call these notifications "Indie" push notifications. </Text>
            <Text style={styles.boldText}>Make sure you follow the <TouchableOpacity onPress={goToAll}>'Start Here'</TouchableOpacity> instructions BEFORE you set up Indie push notifications. Indie push notifications will not work without first following the <TouchableOpacity onPress={goToAll}>'Start Here'</TouchableOpacity> instructions.</Text>
            
            <Text style={styles.mb}></Text>
            <Text style={styles.mb}></Text>

            {Platform.OS === "web"
                ? <View style={styles.youTubeVideo}>
                    <Text style={styles.h2}>This video walks you through the setup guide below:</Text>
                    <iframe width="400" height="220" src="https://www.youtube.com/embed/4KsGaR4gu-8?rel=0" title="Native Notify Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen="allowfullscreen"></iframe>
                    
                    </View>
                : null 
            }

            <View style={styles.dottedLine}></View>

            <Text style={styles.boldText}>API preview:</Text>
            <Text style={styles.plainText}>Here's how our Indie push notification API works:</Text>
            <Text style={styles.mb}></Text>
            <Text style={styles.plainText}><Text style={styles.bT}>Registration:</Text> You have to send us a unique identifier for each app user you want to send an Indie push notification to.</Text>
            <Text style={styles.plainText}><Text style={styles.bT}>Use:</Text> You can then use this unique identifier whenever you want to send an Indie push notification.</Text>
            <Text style={styles.mb}></Text>
            <Text style={styles.plainText}>Usually, how people do this is by placing our Indie registration API in a login function. So, once one of your users logs into your app, you can send us that user's unique identifier in the login function. Usually a unique identifier is the user's email, phone number, or a unique user ID you have assigned to a user in your database.</Text><Text style={styles.bT}>It's very important that your user's ID is UNIQUE. Make sure no other users share the same user ID.</Text>

            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>
            <Text style={styles.HeadingText}>Registration setup:</Text>

            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.blackBacktext}>flutter pub upgrade native_notify</Text> 

            <Text style={styles.boldText}>Step 2:</Text>
            <Text style={styles.plainText}>In your dart file, make sure this import is included:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.orangeText}>'package:native_notify/native_notify.dart'</Text>;</Text>
            </Text>

            <Text style={styles.boldText}>Step 3:</Text>
            <Text style={styles.plainText}>In your login function, use this code to register your individual user:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.medBlueText}>void</Text> <Text style={styles.yellowText}>yourLoginFunction</Text>() {"{"}</Text>
                <Text style={styles.mb}>    ...</Text>
                <Text style={styles.mb}>    <Text style={styles.greenText}>NativeNotify</Text>.<Text style={styles.yellowText}>registerIndieID</Text>('your_sub_id');</Text>
                <Text style={styles.mb}>{"}"}</Text>
            </Text>

            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>
            <Text style={styles.HeadingText}>How to send an indie push notification in app:</Text>
            <Text style={styles.plainText}>At this point, you are ready to send indie push notifications. Whenever you want to send an indie push notification, simply send us your unique user ID (subID), your app ID, your app Token, a push notification title, and a push notification message. You can also send a big picture URL and/or a data object with your indie push notifications if you choose, but they are not required.</Text>
            <Text style={styles.mb}>Here are the steps to follow to send an indie push notification:</Text>
            
            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.blackBacktext}>flutter pub upgrade native_notify</Text>

            <Text style={styles.boldText}>Step 2:</Text>
            <Text style={styles.plainText}>In your dart file, make sure this import is included:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.orangeText}>'package:native_notify/native_notify.dart'</Text>;</Text>
            </Text>

            <Text style={styles.boldText}>Step 3:</Text>
            <Text style={styles.plainText}>In your sending function, use this code to send an indie push notification:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.medBlueText}>void</Text> <Text style={styles.yellowText}>yourIndiePushSendingFunction</Text>() {"{"}</Text>
                <Text style={styles.mb}>    ...</Text>
                <Text style={styles.mb}>    <Text style={styles.greenText}>NativeNotify</Text>.<Text style={styles.yellowText}>sendIndieNotification</Text>({appInfo.flutter_app_id}, '{appInfo.flutter_app_token}', 'your_sub_id', 'your_title', 'your_body', 'your_big_picture_url', 'your_push_data_object');</Text>
                <Text>    // yourAppID, yourAppToken, 'your_sub_id', 'your_title', 'your_body' is required</Text>
                <Text>    // put null in any other parameter you do NOT want to use</Text>
                <Text style={styles.mb}></Text>
                <Text style={styles.mb}>{"}"}</Text>
            </Text>
            <Text style={styles.plainText}>Do NOT change the 'appId' or the 'appToken' value in this code. We put your appId and appToken in the code for you.</Text>

            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>
            <Text style={styles.HeadingText}>How to send an indie push notification using our API:</Text>
            <Text style={styles.mb}>You can also send indie push notifications using our API.</Text>
            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.plainText}>In your dart file, make sure this import is included:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.orangeText}>'package:http/http.dart'</Text> as <Text style={styles.lightBlueText}>http</Text>;</Text>
                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.orangeText}>'dart:convert'</Text>;</Text>
            </Text>
            <Text style={styles.boldText}>Step 2:</Text>
            <Text style={styles.mb}>Here is the API:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.lightBlueText}>http</Text>.<Text style={styles.yellowText}>post</Text>(`https://app.nativenotify.com/api/flutter/notification`),</Text>
                <Text>    <Text style={styles.lightBlueText}>headers</Text>: {`{`}</Text>
                <Text>        <Text style={styles.greenText}>HttpHeaders</Text>.contentTypeHeader: <Text style={styles.orangeText}>'application/json'</Text></Text>
                <Text>    {`}`},</Text>
                <Text>    <Text style={styles.lightBlueText}>body</Text>: <Text style={styles.yellowText}>jsonEncode</Text>({`{`}</Text>
                <Text>        <Text style={styles.greenText}>HttpHeaders</Text>.contentTypeHeader: <Text style={styles.orangeText}>'application/json'</Text></Text>
                <Text>            'indieSubID': 'put your unique registered app user ID here as a string',</Text>
                <Text>            'flutterAppId': '{appInfo.flutter_app_id}',</Text>
                <Text>            'flutterAppToken': '{appInfo.flutter_app_token}',</Text>
                <Text>            'title': 'put your push notification title here as a string',</Text>
                <Text>            'body': 'put your push notification message here as a string',</Text>
                <Text>            'bigPictureURL': <Text style={styles.blueText}>null</Text>,</Text>
                <Text>            'data': '{'{ "yourProperty":"yourPropertyValue" }'}',</Text>
                <Text>{" }));"}</Text>
            </Text>

            <Text style={styles.mb}></Text>
            <Text style={styles.mb}></Text>
            <Text style={styles.plainText}>'bigPictureURL' and 'data' API values are optional. If you do NOT want to use these post values, put null as it's value or remove the values from the post request.</Text>
            
        </View>
    )
}

const styles = StyleSheet.create({
    body: {
        width: '85%',
        paddingTop: 30,
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingBottom: 200
    },

    buttonText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 16,
        color: '#fff',
    },
    button: {
        width: 220,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
    },
    HeadingText: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        // marginTop: 70,
        marginBottom: 10
    },
    h2: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 20,
        marginBottom: 10
    },
    boldText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginTop: 20,
        marginBottom: 5
    },
    bT: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 16,
        marginBottom: 5
    },
    blackBacktext: {
        backgroundColor: "#1b1f23",
        color: "#fff",
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        padding: 20,
        borderRadius: 6,
        marginBottom: 5,
        display: "flex",
        flexDirection: "column"
    },
    blueText: {
        color: "rgb(54, 117, 212)"
    },
    medBlueText: {
        color: "rgb(81, 130, 204)"
    },
    dottedLine: {
        height: 1,
        width: "100%",
        borderTopWidth: 1,
        borderColor: "#000",
        borderStyle: "dotted",
        marginTop: 30,
        marginBottom: 10,
    },
    lightBlueText: {
        color: "rgb(144, 180, 218)"
    },
    plainText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 5
    },
    purpleText: {
        color: "rgb(187, 86, 187)"
    },
    mb: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 10
    },
    tab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50
    },
    yellowText: {
        color: "rgb(222, 222, 160)"
    },
    greenText: {
        color: '#76d7aa'
    },
    orangeText: {
        color: "#c78c74"
    }
});