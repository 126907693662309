import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Platform, TextInput } from 'react-native';
import axios from '../../../../axios';

import Icon from 'react-native-vector-icons/MaterialIcons';

import DocsMenu from './Components/DocsMenu';

export default function FirebaseDocs({ host, appInfo, showInstallDocs, showFirebaseDocs, showIndieInstallDocs, goToIOSDocs, showIOSDocs, goToAll, goToFirebase, goToOne, showAndroidIconUpdateDocs, goToAndroidIconUpdateDocs }) {
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');

    function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

    const sendNotification = () => {
        if(title && body) {
            axios
            .get(`${host}/api/flutter/app/${getCookie('flutter_app_id')}`)
            .then(res => {
                // console.log("res.data: ", res.data);

                if (!res.data.app_sub_count || res.data.app_sub_count < 1) {
                    return alert("Sorry, your app does not have any push notification subscribers yet. Try opening your app, then refresh this page and try again.")
                } else {
                    const date = new Date();
                    let hour = date.getHours();
                    let amPM = "";

                    if (hour === 12) {
                        amPM = "PM"
                    } else if (hour > 12) {
                        amPM = "PM"
                        hour = hour - 12;
                    } else {
                        amPM = "AM"
                    }

                    axios
                        .post(`${host}/api/flutter/notification`, { flutterAppId: getCookie('flutter_app_id'), flutterAppToken: appInfo.flutter_app_token, title, body })
                        .then(res => {
                            console.log(res.data);
                            alert(`Your Android push notification was successfully sent! \n\n(Sometimes it takes up to 30 minutes for a push notification to appear on a device.)`);
                            setTitle('');
                            setBody('');
                        })
                        .catch(err => {
                            console.log(err);
                            alert("There was an error. We're going to refresh the page for you. Please, try to send a push notification again after the page reload.");
                            location.reload();
                        })
                }
            })
            .catch(err => console.log(err))
        } else {
            alert('Please, type a notification title and a notification message before sending a push notification.')
        }
    }
    
    return (
        <View style={styles.body}>
            <DocsMenu 
                showInstallDocs={showInstallDocs} 
                showFirebaseDocs={showFirebaseDocs}
                showIndieInstallDocs={showIndieInstallDocs} 
                showAndroidIconUpdateDocs={showAndroidIconUpdateDocs}
                goToAll={goToAll} 
                goToFirebase={goToFirebase}
                goToOne={goToOne} 
                goToAndroidIconUpdateDocs={goToAndroidIconUpdateDocs}
                goToIOSDocs={goToIOSDocs}
                showIOSDocs={showIOSDocs} />
            {/* <TouchableOpacity style={styles.button} onPress={() => { setShowInstallDocs(false); setShowSendNot(true); setShowInstantInstallDocs(false); }}>
                <Text style={styles.buttonText}>Send a Push Notification</Text>
            </TouchableOpacity> */}

            <Text style={styles.HeadingText}>Follow these steps to get Native Notify push notifications working in Android Production Mode:</Text>
            <Text style={styles.plainText}>If you have followed the <TouchableOpacity onPress={goToAll}>'Start Here'</TouchableOpacity> instructions, Native Notify works in Android development. For Native Notify push notifications to work in Android production mode, you have to integrate Firebase. The instructions below show you how to integrate Firebase into your app.</Text>
            {/* <Text style={styles.boldText}>Make sure you follow the <TouchableOpacity onPress={goToAll}>'Start Here'</TouchableOpacity> instructions BEFORE you integrating Firebase or Native Notify push notifications won't work.</Text> */}

            <Text style={styles.mb}></Text>

            {Platform.OS === "web"
                ? <View style={styles.youTubeVideo}>
                    <Text style={styles.h2}>This video walks you through the Android Firebase setup guide below:</Text>
                    <iframe width="400" height="220" style={{ maxWidth: '100%' }} src="https://www.youtube.com/embed/jhQ3wHUstH4?rel=0" title="Native Notify Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen="allowfullscreen"></iframe>
                    
                    </View>
                : null 
            }   

            <View style={styles.dottedLine}></View>

            <Text style={styles.HeadingText}>Android Firebase setup guide:</Text>

            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.plainText}>
                Go to this link: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://console.firebase.google.com')}>https://console.firebase.google.com</TouchableOpacity>
            </Text>

            <Text style={styles.boldText}>Step 2:</Text>
            <Text style={styles.plainText}>Click "Add project" and follow the instructions. This will take you to the "Project Overview" page.</Text>

            <Text style={styles.boldText}>Step 3:</Text>
            <Text style={styles.plainText}>Click the Android icon in the middle of the screen to create an Android app and follow the instructions.</Text>

            <Text style={styles.boldText}>Step 4:</Text>
            <Text style={styles.plainText}>Click the gear icon to the right of the "Project Overview" text in the top left of the screen.</Text>

            <Text style={styles.boldText}>Step 5:</Text>
            <Text style={styles.plainText}>Click "Cloud Messaging" in the upper left part of the screen.</Text>

            <Text style={styles.boldText}>Step 6:</Text>
            <Text style={styles.plainText}>Click the three little dots in the top right corner of the "Cloud Messaging API (Legacy)" container to enable the legacy Server key.</Text>

            <Text style={styles.boldText}>Keep this "Cloud Messaging" page open. You will need the "Server key" for the next step below:</Text>

            <View style={styles.dottedLine}></View>

            <View style={styles.flexRow}>
                <View style={styles.leftCont}>
                    <Text style={styles.HeadingTextTop}>lib</Text>
                        <Text style={styles.HeadingText}> <Icon name='subdirectory-arrow-right' size={20} />main.dart file setup:</Text>
                    <Text style={styles.boldText}>Step 1:</Text>
                    <Text style={styles.blackBacktext}>
                        <Text>flutter pub add native_notify</Text>
                    </Text>

                    <Text style={styles.boldText}>Step 2:</Text>
                    <Text style={styles.plainText}>In your "lib > main.dart" file, make sure these imports are included at the top of the screen:</Text>
                    <Text style={styles.blackBacktext}>
                        <Text><Text style={styles.medBlueText}>import</Text> 'package:flutter/material.dart';</Text>
                        <Text><Text style={styles.medBlueText}>import</Text> 'package:native_notify/native_notify.dart';</Text>
                    </Text>

                    <Text style={styles.boldText}>Step 3:</Text>
                    <Text style={styles.plainText}>In your "lib > main.dart" file, in the "void main()" function, paste this code BEFORE runApp():</Text>
                    <Text style={styles.blackBacktext}>
                        <Text><Text style={styles.greenText}>WidgetsFlutterBinding</Text>.<Text style={styles.yellowText}>ensureInitialized</Text>();</Text>
                        <Text><Text style={styles.greenText}>NativeNotify</Text>.<Text style={styles.yellowText}>initialize</Text>({appInfo.flutter_app_id}, '{appInfo.flutter_app_token}', 'your-firebase-server-key', null);</Text>
                    </Text>
                    <Text style={styles.plainText}>Do NOT change the 'appId' or the 'appToken' value in this code. We put your appId and appToken in the code for you. </Text>
                    <Text style={styles.plainText}>Do NOT delete the 'null' parameter, or Native Notify push notifications will not initialize properly.</Text>
                </View>

                <View style={styles.rightCont}>
                    {screen.width < 900 ? <View style={styles.dottedLine}></View> : null}

                    <View style={styles.stickyRight}>
                        <Text style={styles.boldTextTop}>Here is an example of a "lib > main.dart" file with Native Notify code included:</Text>
                        <Text style={styles.blackBacktext}>
                            <Text><Text style={styles.medBlueText}>import</Text> 'package:flutter/material.dart';</Text>
                            <Text style={styles.mb}><Text style={styles.medBlueText}>import</Text> 'package:native_notify/native_notify.dart';</Text>

                            <Text><Text style={styles.medBlueText}>void</Text> <Text style={styles.yellowText}>main</Text>() {'{'}</Text>
                                <Text>    <Text style={styles.greenText}>WidgetsFlutterBinding</Text>.<Text style={styles.yellowText}>ensureInitialized</Text>();</Text>
                                <Text>    <Text style={styles.greenText}>NativeNotify</Text>.<Text style={styles.yellowText}>initialize</Text>({appInfo.flutter_app_id}, '{appInfo.flutter_app_token}', 'your-firebase-server-key', null);</Text>
                                <Text>    <Text style={styles.yellowText}>runApp</Text>(<Text style={styles.greenText}>myApp</Text>());</Text>
                                {/* this is background_fetch instructions. background_fetch does not currently work. */}
                                {/* <Text>    <Text style={styles.greenText}>NativeNotify</Text>.<Text style={styles.yellowText}>listener</Text>();</Text> */}
                            <Text style={styles.mb}>{"}"}</Text>

                            <Text>...</Text>
                        </Text>
                    </View>
                </View>
            </View>

            <View style={styles.dottedLine}></View>
            
            <View style={styles.flexRow}>
                <View style={styles.leftCont}>
                    <Text style={styles.HeadingTextTop}>android</Text>
                        <Text style={styles.HeadingTextTop}>        <Icon name='subdirectory-arrow-right' size={20} />app</Text>
                        <Text style={styles.HeadingTextBottom}>            <Icon name='subdirectory-arrow-right' size={20} />build.gradle file setup:</Text>

                    <Text style={styles.boldTextTop}>Step 1:</Text>
                    <Text style={styles.plainText}>In the "android" object, change "compileSdkVersion flutter.compileSdkVersion" to:</Text>
                    <Text style={styles.blackBacktext}>
                        <Text>compileSdkVersion <Text style={styles.yellowText}>31</Text></Text>
                    </Text>

                    <Text style={styles.boldTextTop}>Step 2:</Text>
                    <Text style={styles.plainText}>In the "android > defaultConfig" object, change "minSdkVersion flutter.minSdkVersion" to:</Text>
                    <Text style={styles.blackBacktext}>
                        <Text>minSdkVersion <Text style={styles.yellowText}>21</Text></Text>
                    </Text>
                </View>

                <View style={styles.rightCont}>
                    {screen.width < 900 ? <View style={styles.dottedLine}></View> : null}

                    <View style={styles.stickyRight}>
                        <Text style={styles.boldTextTop}>Here is an example of an "android > app > build.gradle" file "android" object with Native Notify code included:</Text>
                        <Text style={styles.blackBacktext}>
                            <Text>android {'{'}</Text>
                                <Text style={styles.mb}>    compileSdkVersion <Text style={styles.yellowText}>31</Text></Text>
                                <Text style={styles.mb}>    ...</Text>
                                <Text>    defaultConfig {'{'}</Text>
                                    <Text>        applicationId <Text style={styles.orangeText}>"com.example.my_app"</Text></Text>
                                    <Text>        minSdkVersion <Text style={styles.yellowText}>21</Text></Text>
                                    <Text>        targetSdkVersion flutter.targetSdkVersion</Text>
                                    <Text>        versionCode flutterVersionCode.toInteger()</Text>
                                    <Text>        versionName flutterVersionName</Text>
                                <Text>    {'}'}</Text>
                            <Text>{'}'}</Text>
                        </Text>
                    </View>
                </View>
            </View> 

            <View style={styles.dottedLine}></View>

            <View style={styles.flexRow}>
                <View style={styles.leftCont}>
                    <Text style={styles.HeadingTextTop}>ios</Text>
                        <Text style={styles.HeadingTextBottom}>  <Icon name='subdirectory-arrow-right' size={20} />Podfile file setup:</Text>
                    <Text style={styles.boldTextTop}>Step 1:</Text>
                    <Text style={styles.plainText}>The "platform :ios" at the top of the sceen needs to be '10.0' or higher. Here is an example:</Text>
                    <Text style={styles.blackBacktext}>
                        <Text>platform :ios, <Text style={styles.orangeText}>'10.0'</Text></Text>
                    </Text>
                </View>

                <View style={styles.rightCont}>
                    {screen.width < 900 ? <View style={styles.dottedLine}></View> : null}

                    <View style={styles.stickyRight}>
                        <Text style={styles.boldTextTop}>Here is an example of an "ios > Podfile" with Native Notify code included:</Text>
                        <Text style={styles.blackBacktext}>
                            <Text style={styles.greenText}># Uncomment this line to define a global platform for your project</Text>
                            <Text>platform :ios, <Text style={styles.orangeText}>'10.0'</Text></Text>
                        </Text>
                    </View>
                </View>
            </View>   

            <View style={styles.dottedLine}></View>

            <Text style={styles.HeadingText}>Firebase Android integration is finished! Try sending yourself an Android push notification: </Text>

            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.plainText}>Open your app on an Android emulator or an Android APK file on your smartphone.</Text>

            <Text style={styles.boldText}>Step 2: </Text>
            <Text style={styles.HeadingText}>Send a push notification to yourself:</Text>

            <View style={styles.sendCont}>
                <View style={styles.inputCont}>
                    <Text style={styles.text}>Notification Title:</Text>
                    <TextInput
                        style={styles.inputLarge}
                        onChangeText={(text) => setTitle(text)}
                        placeholder={"Type Notification Title here..."}
                        placeholderTextColor={"#6e6d6c"}
                        value={title}
                    />
                </View>
                <View style={styles.inputCont}>
                    <Text style={styles.text}>Notification Message:</Text>
                    <TextInput
                        style={styles.inputLarge}
                        onChangeText={(text) => setBody(text)}
                        placeholder={"Type Notification Message here..."}
                        placeholderTextColor={"#6e6d6c"}
                        value={body}
                        multiline={true}
                    />
                </View>

                <TouchableOpacity style={styles.sendButton} onPress={() => sendNotification()}>
                    <Text style={styles.buttonText}>Send Push Notification</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    body: {
        width: 'calc(100% - 60px)',
        paddingTop: 30,
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingBottom: 200,
    },
    flexRow: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    leftCont: {
        width: screen.width > 900 ? '48%' : '100%',
    },
    rightCont: {
        width: screen.width > 900 ? '48%' : '100%',
        backgroundColor: screen.width > 900 ? '#3675d420' : '#fff',
        padding: screen.width > 900 ? 15 : 0,
        borderRadius: screen.width > 900 ? 6 : 0
    },
    stickyRight: {
        position: screen.width > 900 ? 'sticky' : 'relative',
        top: screen.width > 900 ? 10 : null
    },

    buttonText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 16,
        color: '#fff',
    },
    button: {
        width: 220,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
    },
    HeadingTextTop: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 20,
        justifyContent: 'center',
        marginBottom: -5
    },
    HeadingTextBottom: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        marginBottom: 30,
        justifyContent: 'center'
    },
    HeadingText: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        // marginTop: 70,
        marginBottom: 10
    },
    HeadingText2: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        marginTop: 70,
        marginBottom: 10
    },
    h2: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 20,
        marginBottom: 10
    },
    boldText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginTop: 20,
        marginBottom: 5
    },
    boldTextTop: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        // marginTop: 20,
        marginBottom: 5
    },
    blackBacktext: {
        backgroundColor: "#1b1f23",
        color: "#fff",
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        padding: 20,
        borderRadius: 6,
        marginBottom: 5,
        display: "flex",
        flexDirection: "column"
    },
    blueText: {
        color: "rgb(54, 117, 212)"
    },
    medBlueText: {
        color: "rgb(81, 130, 204)"
    },
    dottedLine: {
        height: 1,
        width: "100%",
        borderTopWidth: 1,
        borderColor: "#000",
        borderStyle: "dotted",
        marginTop: 30,
        marginBottom: 30,
    },
    lightBlueText: {
        color: "rgb(144, 180, 218)"
    },
    plainText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 5
    },
    purpleText: {
        color: "rgb(187, 86, 187)"
    },
    mb: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 20
    },
    mbSmall: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 5
    },
    tab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50
    },
    greentab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50,
        color: 'rgb(81, 167, 81)'
    },
    yellowText: {
        color: "rgb(222, 222, 160)"
    },
    greenText: {
        color: 'rgb(81, 167, 81)'
    },
    sendCont: {
        padding: 20,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 15
    },
    sendButton: {
        width: 220,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
        marginTop: 5,
        // marginBottom: 70,
    },
    inputCont: {
        marginBottom: 20,
        width: '100%',
    },
    inputLarge: {
        width: '100%',
        padding: 12.5,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 15
    },
    orangeText: {
        color: "#c78c74"
    }
});