import axios from "axios";

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.message.includes('status code 401')) {
        console.log('API returned 401, redirecting..')
        var d = new Date();
        d.setDate(d.getDate() - 1);
        document.cookie = `AUTH = ""; expires=${d};`;
        document.cookie = `AUTH = ""; domain=nativenotify.com; expires=${d};`;
        document.cookie = `AUTH = ""; domain=app.nativenotify.com; expires=${d};`;
        window.location.href = '/login'
    }
    return Promise.reject(error);
  }
);

export default axios;
