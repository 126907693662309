import React, { useState, useEffect, useRef } from 'react';

import AppState from './src/AppState/AppState';

// index.js or App.js
import './styles.css';

export default function App() {  
  // at home mac host for apps needed for app development

  // dev host for website at home
  // const host = 'http://localhost:7070';

  // host for live site
  const host = 'https://app.nativenotify.com';
  const [userId, setUserId] = useState(0);

  return <AppState 
            host={host} 
            userId={userId}
            setUserId={setUserId}
         />
}